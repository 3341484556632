import styled from 'styled-components';

export const AddTherapyAreaButton = styled.div`
  border-top: ${({ theme }) => ` 1px solid ${theme.palette.grayLight}`};
  padding-top: 18px;
`;
export const DeleteTherapyAreaButton = styled.div`
  border-bottom: ${({ theme }) => ` 1px solid ${theme.palette.grayLight}`};
  padding-bottom: 18px;
  margin-bottom: 18px;
`;
