import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

import { IconUserProfileSVG } from '../../assets/svg';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../constants/auth';
import { AppRoutes } from '../../constants/navigation';
import { Component } from '../../types-common';
import { ActionsMenu, ActionsMenuProps } from '../ActionsMenu';

export const useUsersListItem = (): Pick<ActionsMenuProps, 'items'> => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const onViewProfile = (): void => {
    navigate(AppRoutes.MY_PROFILE);
  };
  const onLogout = (): void => {
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, '');
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
  };

  return {
    items: [
      { name: 'View Profile', actionHandler: onViewProfile },
      { name: 'Logout', actionHandler: onLogout },
    ],
  };
};

export const MyProfileButton: Component = () => <ActionsMenu icon={<IconUserProfileSVG />} {...useUsersListItem()} />;
