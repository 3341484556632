import { Component } from '../../../types-common';
import CreateNewProjectButton from './CreateNewProjectButton';
import { EmptyProjectsListRoot, EmptyProjectsListText } from './styled/EmptyProjectsList.styled';

export const EmptyProjectsList: Component = () => (
  <EmptyProjectsListRoot>
    <EmptyProjectsListText>There are no projects yet</EmptyProjectsListText>
    <CreateNewProjectButton />
  </EmptyProjectsListRoot>
);
