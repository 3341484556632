import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from 'components/Button/Button';
import { DialogActions, DialogDescription, DialogIconClose, DialogTitle } from 'components/Dialog';
import { Component } from 'types-common';

import { IconCloseSVG, IconInfoSVG } from '../../../assets/svg';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { CheckboxToggle } from '../../../components/Checkbox/CheckboxToggle';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { theme } from '../../../config/theme';
import { OnCloseDialogHandler } from '../types';
import { useProjectServicesForm } from './hooks/useProjectServicesForm';
import { ProjectEditWizardServicesStep } from './project-edit-wizard-reducer';
import {
  FormError,
  ProjectServiceCheckbox,
  ProjectServices,
  ProjectServicesGroup,
  ProjectServicesGroupTitle,
  RepositoryOnly,
} from './styled/ProjectServiceForm.styled';

const schema = yup
  .object()
  .shape({
    isRepositoryOnly: yup.boolean(),
    clinicalTrialTracker: yup.boolean(),
    newsTracker: yup.boolean(),
    productProfiles: yup.boolean(),
    competitorProfiles: yup.boolean(),
    conferenceMaterials: yup.boolean(),
    publications: yup.boolean(),
    newsletter: yup.boolean(),
    companyMaterials: yup.boolean(),
    powerBI: yup.boolean(),
    reports: yup.boolean(),
    keyEvents: yup.boolean(),
  })
  .test('at-least-one-service', 'You have to select at least one service for the project', value => {
    return !!(
      value.clinicalTrialTracker ||
      value.newsTracker ||
      value.productProfiles ||
      value.competitorProfiles ||
      value.conferenceMaterials ||
      value.publications ||
      value.newsletter ||
      value.companyMaterials ||
      value.powerBI ||
      value.reports ||
      value.keyEvents
    );
  });
export type ProjectServicesFormData = yup.InferType<typeof schema>;

export interface ProjectServicesFormUIProps {
  defaultValues: ProjectEditWizardServicesStep;
  loading: boolean;
  onBack: () => void;
  onSubmit: (formData: ProjectServicesFormData) => void;
  onChange: (formData: ProjectServicesFormData) => void;
}
const ProjectServicesFormUI: Component<ProjectServicesFormUIProps & OnCloseDialogHandler> = ({
  defaultValues,
  loading,
  onBack,
  onSubmit,
  onChange,
  onClose,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver<ProjectServicesFormData>(schema),
  });
  const formState = useWatch({ control });

  useEffect(() => {
    if (formState) {
      onChange(formState);
    }
  }, [formState]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <DialogTitle>
        <div>Select Service</div>
        <DialogIconClose role="button" onClick={onClose}>
          <IconCloseSVG />
        </DialogIconClose>
      </DialogTitle>
      <DialogDescription>
        Once the project created
        <br />
        service set cannot be changed
      </DialogDescription>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RepositoryOnly>
          <CheckboxToggle
            control={control}
            name={'isRepositoryOnly'}
            label="Repository Only"
            defaultChecked={Boolean(defaultValues.isRepositoryOnly)}
            disabled={loading}
          />
          <Tooltip
            background={theme.palette.primary}
            title="Enable this option only if you offer the client limited services with Conference Materials and Reports libraries as a courtesy repository for this project">
            <IconInfoSVG />
          </Tooltip>
        </RepositoryOnly>

        <ProjectServices>
          <ProjectServicesGroup>
            <ProjectServicesGroupTitle>Tracker</ProjectServicesGroupTitle>
            <ProjectServiceCheckbox>
              <Checkbox
                name="clinicalTrialTracker"
                control={control}
                label="Clinical Trials Tracker"
                defaultChecked={false}
                disabled
              />
              <Checkbox
                control={control}
                name={'newsTracker'}
                label="News Tracker"
                defaultChecked={Boolean(defaultValues.newsTracker)}
                disabled={loading}
              />
            </ProjectServiceCheckbox>
          </ProjectServicesGroup>
          <ProjectServicesGroup>
            <ProjectServicesGroupTitle>Profiles</ProjectServicesGroupTitle>
            <ProjectServiceCheckbox>
              <Checkbox
                control={control}
                name={'productProfiles'}
                label="Product Profiles"
                defaultChecked={false}
                disabled
              />
              <Checkbox
                control={control}
                name={'competitorProfiles'}
                label="Competitor Profiles"
                defaultChecked={false}
                disabled
              />
            </ProjectServiceCheckbox>
          </ProjectServicesGroup>
        </ProjectServices>

        <ProjectServices>
          <ProjectServicesGroup key={`project-services-form-group-materials`}>
            <ProjectServicesGroupTitle>Materials</ProjectServicesGroupTitle>
            <ProjectServiceCheckbox>
              <Checkbox
                control={control}
                name={'conferenceMaterials'}
                label="Conference Materials"
                defaultChecked={false}
                disabled
              />
              <Checkbox control={control} name={'publications'} label="Publications" defaultChecked={false} disabled />
              <Checkbox control={control} name={'newsletter'} label="Newsletter" defaultChecked={false} disabled />
              <Checkbox
                control={control}
                name={'companyMaterials'}
                label="Company Materials"
                defaultChecked={false}
                disabled
              />
            </ProjectServiceCheckbox>
          </ProjectServicesGroup>
          <ProjectServicesGroup key={`project-services-form-group-reports`}>
            <ProjectServicesGroupTitle>Reports</ProjectServicesGroupTitle>
            <ProjectServiceCheckbox>
              <Checkbox control={control} name={'powerBI'} label="Power BI" defaultChecked={false} disabled />
              <Checkbox control={control} name={'reports'} label="Reports" defaultChecked={false} disabled />
            </ProjectServiceCheckbox>
          </ProjectServicesGroup>
        </ProjectServices>
        <ProjectServices>
          <ProjectServicesGroup key={`project-services-form-group-other`}>
            <ProjectServicesGroupTitle>Other</ProjectServicesGroupTitle>
            <ProjectServiceCheckbox>
              <Checkbox control={control} name={'keyEvents'} label="Key Events" defaultChecked={false} disabled />
            </ProjectServiceCheckbox>
          </ProjectServicesGroup>
        </ProjectServices>

        <FormError>
          {Object.keys(errors).map((key: string) => (
            <>{(errors as Record<string, Record<string, string>>)[key]?.message}</>
          ))}
        </FormError>

        <DialogActions>
          <Button variant="outlined" type="button" disabled={loading} onClick={onBack}>
            Back
          </Button>
          <Button type="submit" loading={loading} disabled={loading}>
            Add Project
          </Button>
        </DialogActions>
      </form>
    </div>
  );
};

export interface ProjectServicesFormProps {
  onBack: () => void;
  onClose: (reason?: string) => void;
}
export const ProjectServicesForm: Component<ProjectServicesFormProps> = ({ onBack, onClose }) => (
  <ProjectServicesFormUI onClose={onClose} {...useProjectServicesForm(onBack, onClose)} />
);
