import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import diseasesReducer from '../modules/healthcare-category-list/diseases-reducer';
import therapyAreasReducer from '../modules/healthcare-category-list/therapy-areas-reducer';
import inviteVerificationReducer from '../modules/identity-management/invite-verification/reducer';
import profileReducer from '../modules/identity-management/my-profile/reducer';
import authReducer from '../modules/identity-management/sign-in/reducer';
import usersInviteReducer from '../modules/identity-management/user-management/users-invite-reducer';
import usersReducer from '../modules/identity-management/user-management/users-reducer';
import newsAlertsReducer from '../modules/news-tracker/news-list/news-alerts-reducer';
import projectEditWizardReducer from '../modules/project-management/project-edit-wizard/project-edit-wizard-reducer';
import businessUnitsReducer from '../modules/project-management/projects-list/business-units-reducer';
import projectServicesReducer from '../modules/project-management/projects-list/project-services-reducer';
import projectsReducer from '../modules/project-management/projects-list/projects-reducer';
import sitesReducer from '../modules/site-management/sites-list/reducer';
import { rootSaga } from './saga';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    sites: sitesReducer,
    profile: profileReducer,
    users: usersReducer,
    usersInvite: usersInviteReducer,
    inviteVerification: inviteVerificationReducer,
    projects: projectsReducer,
    projectEditWizard: projectEditWizardReducer,
    projectServices: projectServicesReducer,
    businessUnits: businessUnitsReducer,
    therapyAreas: therapyAreasReducer,
    diseases: diseasesReducer,
    newsAlerts: newsAlertsReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
