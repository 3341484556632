import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccessToken, ErrorMessage, Maybe } from 'types-common';

import { FailureResponseData } from '../../../error-handling';
import { PostAuthDummyLoginPayload } from './SignInForm';

// TODO: move to separate file
export interface AADB2CLoginDTO {
  idToken: string;
}

export interface AuthState {
  accessToken: Maybe<AccessToken>;
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: AuthState = {
  accessToken: '',
  loading: false,
  error: null,
};

// TODO: separate slice for AADB2C auth?
const reducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticateLocalDevelopment(state, _action: PayloadAction<PostAuthDummyLoginPayload>) {
      state.loading = true;
      state.error = null;
    },
    // TODO: keep AADB2C specific code separately?
    authenticateAADB2C(state, _action: PayloadAction<AADB2CLoginDTO>) {
      state.loading = true;
      state.error = null;
    },
    authSuccess(state, action: PayloadAction<{ accessToken: AccessToken }>) {
      state.loading = false;
      state.accessToken = action.payload.accessToken;
    },
    authFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { authenticateLocalDevelopment, authenticateAADB2C, authSuccess, authFailure } = reducer.actions;
export default reducer.reducer;
