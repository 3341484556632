import * as React from 'react';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import { Component } from '../../types-common';
import { SelectOption } from '../Select';
import { AutocompleteSelectOptions } from './AutocompleteSelectOptions';

interface AutocompleteSelectOptionsInputProps {
  control: any;
  id: string;
  name: string;
  label?: string;
  placeholder: string;
  options: SelectOption[];
  defaultOptions?: SelectOption[];
  getOptionLabel?: (option: SelectOption) => string | JSX.Element;
  deleteIcon?: JSX.Element;
  rules?: {
    required?: any;
  };
}
export const AutocompleteSelectOptionsInput: Component<AutocompleteSelectOptionsInputProps> = ({
  control,
  id,
  label,
  name,
  placeholder,
  options,
  defaultOptions = [],
  getOptionLabel,
  deleteIcon,
  rules,
}) => {
  const [autocompleteOptions, setAutocompleteOptions] = useState<SelectOption[]>(options);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange }, fieldState: { invalid } }) => {
        const handleChange = (event: React.SyntheticEvent, selectedOptions: SelectOption[]): void => {
          const optionsList = [
            ...autocompleteOptions,
            ...selectedOptions.filter(option => !autocompleteOptions.includes(option)),
          ];

          onChange(selectedOptions);
          setAutocompleteOptions(optionsList);
        };

        return (
          <AutocompleteSelectOptions
            id={id}
            label={label}
            placeholder={placeholder}
            options={autocompleteOptions}
            defaultValue={defaultOptions}
            getOptionLabel={getOptionLabel}
            deleteIcon={deleteIcon}
            onChange={handleChange}
            isInvalid={invalid}
          />
        );
      }}
    />
  );
};
