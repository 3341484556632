import styled from 'styled-components';

import { Table } from '../../../../components/Table';
import { TableHeadRowAlignTop } from '../../../../components/TableHeadRow';
import { palette } from '../../../../config/theme';

export const NewsListRoot = styled(Table)`
  position: relative;
  width: 100%;
`;
export const Scroll = styled.div`
  overflow-x: scroll;
  margin: 0 -24px;
  width: calc(100% + 48px);
`;

export const ScrollContent = styled.div`
  width: fit-content;
  min-width: 100%;
  padding-right: 38px;
`;

export const NoRecords = styled.div`
  padding-top: 24px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

export const NewsListTable = styled(Table)`
  display: grid;
  padding-left: 24px;
`;
export const NewsListTableHead = styled(TableHeadRowAlignTop)`
  align-items: stretch;
  border-top: 1px solid ${palette('grayLight')};
  border-left: 1px solid ${palette('grayLight')};
  border-bottom: 0;
  padding: 0;
`;

export const AddNewsAlert = styled.div`
  position: absolute;
  left: 50%;
  top: 129px;
  z-index: 2;
`;
