import styled from 'styled-components';

export const EmptyProjectsListRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;

  button {
    min-width: 244px;
  }
`;
export const EmptyProjectsListText = styled.div`
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
`;
