import { User } from '../../modules/identity-management/types';
import { Component } from '../../types-common';
import { Label } from '../Label/Label.styled';
import { Tooltip } from '../Tooltip/Tooltip';

interface UserInitialsLabelProps {
  user: User;
}
export const UserInitialsLabel: Component<UserInitialsLabelProps> = ({ user }) => {
  const { firstName, lastName } = user;
  const fullName = `${firstName} ${lastName}`;
  const initials = String(firstName[0] + lastName[0]).toUpperCase();

  return (
    <Tooltip title={fullName}>
      <Label>{initials}</Label>
    </Tooltip>
  );
};
