import { yupResolver } from '@hookform/resolvers/yup';
import { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Component } from 'types-common';

import { IconCloseCircleSVG } from '../../../assets/svg';
import { AutocompleteSelectOptionsInput } from '../../../components/AutocompleteSelectOptions/AutocompleteSelectOptionsInput';
import { DatePickerInput } from '../../../components/DatePickerInput';
import { Label } from '../../../components/Label';
import { LabelsItem } from '../../../components/Labels/Labels';
import { SelectInput, SelectOption } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { TherapyAreaIcon } from '../../../components/TherapyAreaIcon/TherapyAreaIcon';
import {
  MAX_INPUT_LENGTH_LONG,
  MAX_INPUT_LENGTH_SHORT,
  MAX_TEXTAREA_LENGTH_LONG,
  MAX_TEXTAREA_LENGTH_SHORT,
  NO_VALIDATION_MESSAGE,
} from '../../../constants/form';
import {
  NewsAlert,
  NewsAlertCategory,
  NewsAlertGeography,
  NewsAlertPriorityLevel,
  NewsTrackerColumnName,
  NewsTrackerColumnsVisibility,
} from '../types';
import { useNewsListItem } from './hooks/useNewsListItem';
import {
  Cell,
  ColumnAgeGroup,
  ColumnAsset,
  ColumnBusinessStream,
  ColumnCategory,
  ColumnCompany,
  ColumnDate,
  ColumnDisease,
  ColumnGeography,
  ColumnImplications,
  ColumnIndications,
  ColumnLink,
  ColumnModality,
  ColumnOther,
  ColumnPriorityLevel,
  ColumnSummary,
  ColumnTherapyArea,
  ColumnTitle,
} from './styled/Columns.styled';
import {
  NewsListItemDiseases,
  NewsListItemLinkEditWrap,
  NewsListItemRoot,
  NewsListItemTherapyAreas,
} from './styled/NewsListItem.styled';

// TODO: Update Schema type
const schema = yup.object().shape(
  {
    diseaseIds: yup.array().min(1, NO_VALIDATION_MESSAGE),
    therapyAreaIds: yup.array().min(1, NO_VALIDATION_MESSAGE),
    title: yup
      .string()
      .trim()
      .required(NO_VALIDATION_MESSAGE)
      .max(MAX_INPUT_LENGTH_LONG, `Title must not exceed ${MAX_INPUT_LENGTH_LONG} characters`),
    date: yup.string().nullable().default('').required(NO_VALIDATION_MESSAGE),
    businessStream: yup
      .string()
      .max(MAX_INPUT_LENGTH_SHORT, `Business Stream must not exceed ${MAX_INPUT_LENGTH_SHORT} characters`)
      .notRequired()
      .when('businessStream', {
        is: (value: string) => value?.length,
        then: rule => rule.min(3),
      }),
    modality: yup
      .string()
      .max(MAX_INPUT_LENGTH_SHORT, `Modality must not exceed ${MAX_INPUT_LENGTH_SHORT} characters`)
      .notRequired()
      .when('modality', {
        is: (value: string) => value?.length,
        then: rule => rule.min(3),
      }),
    linkUrl: yup
      .string()
      .trim()
      .required(NO_VALIDATION_MESSAGE)
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Source URL is invalid',
      ),
    linkText: yup
      .string()
      .trim()
      .required(NO_VALIDATION_MESSAGE)
      .max(MAX_INPUT_LENGTH_SHORT, `Link Text must not exceed ${MAX_INPUT_LENGTH_SHORT} characters`),
    asset: yup
      .string()
      .trim()
      .notRequired()
      .max(MAX_INPUT_LENGTH_SHORT, `Asset must not exceed ${MAX_INPUT_LENGTH_SHORT} characters`),
    company: yup
      .string()
      .trim()
      .notRequired()
      .max(MAX_INPUT_LENGTH_LONG, `Company must not exceed ${MAX_INPUT_LENGTH_LONG} characters`),
    summary: yup
      .string()
      .trim()
      .required(NO_VALIDATION_MESSAGE)
      .max(MAX_TEXTAREA_LENGTH_SHORT, `Summary must not exceed ${MAX_TEXTAREA_LENGTH_SHORT} characters`),
    ageGroup: yup
      .string()
      .trim()
      .notRequired()
      .max(MAX_INPUT_LENGTH_SHORT, `Age Group must not exceed ${MAX_INPUT_LENGTH_SHORT} characters`),
    indication: yup
      .string()
      .trim()
      .notRequired()
      .max(MAX_INPUT_LENGTH_LONG, `Indication must not exceed ${MAX_INPUT_LENGTH_LONG} characters`),
    implications: yup
      .string()
      .trim()
      .notRequired()
      .max(MAX_TEXTAREA_LENGTH_LONG, `Implications must not exceed ${MAX_TEXTAREA_LENGTH_LONG} characters`),
    other: yup
      .string()
      .trim()
      .notRequired()
      .max(MAX_TEXTAREA_LENGTH_LONG, `Other must not exceed ${MAX_TEXTAREA_LENGTH_LONG} characters`),
    category: yup.string().trim().notRequired().nullable(),
    priorityLevel: yup.string().trim().notRequired().nullable(),
    geography: yup.string().trim().notRequired().nullable(),
  },
  [
    ['businessStream', 'businessStream'],
    ['modality', 'modality'],
  ],
);

const categoryOptions: SelectOption[] = [
  { name: 'None', value: '' },
  { name: NewsAlertCategory.Commercial, value: NewsAlertCategory.Commercial },
  { name: NewsAlertCategory.EarningsCall, value: NewsAlertCategory.EarningsCall },
  { name: NewsAlertCategory.InvestorEvent, value: NewsAlertCategory.InvestorEvent },
  { name: NewsAlertCategory.Regulatory, value: NewsAlertCategory.Regulatory },
  { name: NewsAlertCategory.Other, value: NewsAlertCategory.Other },
  { name: NewsAlertCategory.Clinical, value: NewsAlertCategory.Clinical },
];
const priorityLevelOptions: SelectOption[] = [
  { name: 'None', value: '' },
  { name: NewsAlertPriorityLevel.High, value: NewsAlertPriorityLevel.High },
  { name: NewsAlertPriorityLevel.Medium, value: NewsAlertPriorityLevel.Medium },
  { name: NewsAlertPriorityLevel.Low, value: NewsAlertPriorityLevel.Low },
];
const geographyOptions: SelectOption[] = [
  { name: 'None', value: '' },
  { name: NewsAlertGeography.Global, value: NewsAlertGeography.Global },
  { name: NewsAlertGeography.LATAM, value: NewsAlertGeography.LATAM },
  { name: NewsAlertGeography.Europe, value: NewsAlertGeography.Europe },
  { name: NewsAlertGeography.APAC, value: NewsAlertGeography.APAC },
  { name: NewsAlertGeography.MENA, value: NewsAlertGeography.MENA },
  { name: NewsAlertGeography.NorthAmerica, value: NewsAlertGeography.NorthAmerica },
];

export type NewsListItemFormData = yup.InferType<typeof schema>;

export type NewsListItemUIProps = {
  loading: boolean;
  therapyAreaOptions: SelectOption[];
  therapyAreasSelected: SelectOption[];
  diseaseOptions: SelectOption[];
  diseasesSelected: SelectOption[];
  diseasesSelectedLabels: LabelsItem[];
  onSubmit: (formData: NewsListItemFormData) => void;
};

export const NewsListItemUI: Component<
  NewsListItemUIProps & Pick<NewsListItemProps, 'columnsOrder' | 'columnsVisibility' | 'newsAlert'>
> = ({
  newsAlert,
  therapyAreaOptions,
  therapyAreasSelected,
  diseaseOptions,
  diseasesSelected,
  diseasesSelectedLabels,
  columnsOrder,
  columnsVisibility,
  onSubmit,
}) => {
  const {
    tempId,
    id,
    title,
    date,
    company,
    category,
    summary,
    asset,
    priorityLevel,
    link,
    businessStream,
    geography,
    modality,
    ageGroup,
    implications,
    other,
    indication,
  } = newsAlert;

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      title,
      date,
      summary,
      diseaseIds: diseasesSelected,
      therapyAreaIds: therapyAreasSelected,
      linkUrl: link.url,
      linkText: link.text,
      category: category || '',
      priorityLevel: priorityLevel || '',
      company: company || '',
      asset: asset || '',
      businessStream: businessStream || '',
      modality: modality || '',
      ageGroup: ageGroup || '',
      implications: implications || '',
      other: other || '',
      indication: indication || '',
      geography: geography || '',
    } as NewsListItemFormData,
    mode: 'onSubmit',
    resolver: yupResolver<NewsListItemFormData>(schema),
  });

  useEffect(() => {
    const subscription: any = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: Partial<Record<NewsTrackerColumnName, JSX.Element>> = {
    title: (
      <ColumnTitle>
        <Cell>
          <TextField multiline name="title" type="input" placeholder="Add Title" control={control} />
        </Cell>
      </ColumnTitle>
    ),
    therapyAreaIds: (
      <ColumnTherapyArea>
        <Cell>
          <NewsListItemTherapyAreas>
            <AutocompleteSelectOptionsInput
              control={control}
              name="therapyAreaIds"
              id={`therapy-areas-autocomplete-${id || tempId}`}
              placeholder={'TA'}
              options={therapyAreaOptions}
              defaultOptions={therapyAreasSelected}
              getOptionLabel={({ name }) => <TherapyAreaIcon name={name} />}
              deleteIcon={<IconCloseCircleSVG />}
            />
          </NewsListItemTherapyAreas>
        </Cell>
      </ColumnTherapyArea>
    ),
    date: (
      <ColumnDate>
        <Cell>
          <DatePickerInput name="date" control={control} minDate={date} />
        </Cell>
      </ColumnDate>
    ),
    company: (
      <ColumnCompany>
        <Cell>
          <TextField multiline name="company" type="input" placeholder="Add Company" control={control} />
        </Cell>
      </ColumnCompany>
    ),
    category: (
      <ColumnCategory>
        <Cell>
          <SelectInput<string> name="category" placeholder="Add Category" options={categoryOptions} control={control} />
        </Cell>
      </ColumnCategory>
    ),
    diseaseIds: (
      <ColumnDisease>
        <Cell>
          <NewsListItemDiseases>
            <AutocompleteSelectOptionsInput
              control={control}
              name="diseaseIds"
              id={`disease-autocomplete-${id || tempId}`}
              placeholder={'Add Diseases'}
              options={diseaseOptions}
              defaultOptions={diseasesSelected}
              getOptionLabel={({ name }) => (
                <Label color={diseasesSelectedLabels.find(({ name: diseaseName }) => diseaseName === name)?.color}>
                  {name}
                </Label>
              )}
            />
          </NewsListItemDiseases>
        </Cell>
      </ColumnDisease>
    ),
    summary: (
      <ColumnSummary>
        <Cell>
          <TextField multiline name="summary" type="input" placeholder="Add Summary" control={control} />
        </Cell>
      </ColumnSummary>
    ),
    asset: (
      <ColumnAsset>
        <Cell>
          <TextField multiline name="asset" type="input" placeholder="Add Asset" control={control} />
        </Cell>
      </ColumnAsset>
    ),
    priorityLevel: (
      <ColumnPriorityLevel>
        <Cell>
          <SelectInput<string>
            name="priorityLevel"
            placeholder="Add Priority"
            options={priorityLevelOptions}
            control={control}
          />
        </Cell>
      </ColumnPriorityLevel>
    ),
    link: (
      <ColumnLink>
        <Cell>
          <NewsListItemLinkEditWrap>
            <TextField
              name="linkUrl"
              type="input"
              placeholder="Add Source URL"
              control={control}
              className="url-input"
            />
            <TextField name="linkText" type="input" placeholder="Add Source name" control={control} />
          </NewsListItemLinkEditWrap>
        </Cell>
      </ColumnLink>
    ),
    businessStream: (
      <ColumnBusinessStream>
        <Cell>
          <TextField multiline name="businessStream" type="input" placeholder="Add Business Stream" control={control} />
        </Cell>
      </ColumnBusinessStream>
    ),
    geography: (
      <ColumnGeography>
        <Cell>
          <SelectInput<string>
            name="geography"
            placeholder="Add Geography"
            options={geographyOptions}
            control={control}
          />
        </Cell>
      </ColumnGeography>
    ),
    modality: (
      <ColumnModality>
        <Cell>
          <TextField multiline name="modality" type="input" placeholder="Add Modality" control={control} />
        </Cell>
      </ColumnModality>
    ),
    ageGroup: (
      <ColumnAgeGroup>
        <Cell>
          <TextField multiline name="ageGroup" type="input" placeholder="Add Age Group" control={control} />
        </Cell>
      </ColumnAgeGroup>
    ),
    implications: (
      <ColumnImplications>
        <Cell>
          <TextField multiline name="implications" type="input" placeholder="Add Implications" control={control} />
        </Cell>
      </ColumnImplications>
    ),
    indication: (
      <ColumnIndications>
        <Cell>
          <TextField multiline name="indication" type="input" placeholder="Add Indication" control={control} />
        </Cell>
      </ColumnIndications>
    ),
    other: (
      <ColumnOther>
        <Cell>
          <TextField multiline name="other" type="input" placeholder="Add Other" control={control} />
        </Cell>
      </ColumnOther>
    ),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <NewsListItemRoot>
        {columnsOrder?.map(key => (columnsVisibility[key] ? <Fragment key={key}>{columns[key]}</Fragment> : null))}
      </NewsListItemRoot>
    </form>
  );
};

export interface NewsListItemProps {
  newsAlert: NewsAlert;
  columnsOrder: NewsTrackerColumnName[];
  columnsVisibility: NewsTrackerColumnsVisibility;
}
const NewsListItem: Component<NewsListItemProps> = ({ newsAlert, columnsOrder, columnsVisibility }) => (
  <NewsListItemUI
    columnsOrder={columnsOrder}
    columnsVisibility={columnsVisibility}
    newsAlert={newsAlert}
    {...useNewsListItem(newsAlert)}
  />
);
export default NewsListItem;
