import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbar } from '../../../../components/SnackbarProvider/useSnackbar';
import { RootState } from '../../../../config/store';
import { FORM_DIALOG_ASK_BEFORE_CLOSE } from '../../../../constants/form';
import { Maybe } from '../../../../types-common';
import { Disease } from '../../../healthcare-category-list/types';
import { PostCreateProjectPayload, Project, ProjectServiceId } from '../../types';
import {
  ProjectEditWizardGeneralInfoStep,
  ProjectEditWizardServicesStep,
  ProjectEditWizardTherapyAreaStep,
  resetProjectEditWizard,
  setProjectEditId,
  setProjectEditWizardGeneralInfo,
  setProjectEditWizardServices,
  setProjectEditWizardTherapyArea,
} from '../project-edit-wizard-reducer';
import { ProjectEditWizardUIProps } from '../ProjectEditWizard';
import { prepareProjectCreatePayload } from './useProjectSave';

const prepareGeneralInfoStepData = (project: Project): ProjectEditWizardGeneralInfoStep => {
  const { projectName, projectCode, clientName, description, businessUnitId, projectStartsAt, projectFinishesAt } =
    project;

  return {
    projectName,
    projectCode,
    clientName,
    description,
    businessUnitId,
    projectStartsAt,
    projectFinishesAt,
  };
};

const prepareTherapyAreaStepData = (project: Project, diseases: Disease[]): ProjectEditWizardTherapyAreaStep => {
  const { therapyAreaIds, diseaseIds } = project;
  const therapyAreaDiseases = diseases.filter(({ id }) => diseaseIds.includes(id));
  const therapyAreas = therapyAreaIds.map(id => ({
    therapyAreaId: id,
    diseaseNames: therapyAreaDiseases.filter(({ therapyAreaId }) => therapyAreaId === id).map(({ name }) => name),
  }));

  return therapyAreas;
};

const prepareServicesStepData = (project: Project): ProjectEditWizardServicesStep => {
  const servicesEnableMap: Record<ProjectServiceId, boolean> = {} as Record<ProjectServiceId, boolean>;

  Object.values(ProjectServiceId).forEach(serviceId => {
    servicesEnableMap[serviceId] = Boolean(project[`${serviceId}Config`]?.enabled);
  });

  return {
    isRepositoryOnly: project.isRepositoryOnly,
    ...servicesEnableMap,
  };
};

export const useProjectEditWizard = (
  project: Maybe<Project>,
  onClose: (_?: any, reason?: string) => void,
): ProjectEditWizardUIProps => {
  const [stepIndex, setStepIndex] = useState(0);
  const dispatch = useDispatch();
  const { snackbar } = useSnackbar();
  const { diseases } = useSelector((state: RootState) => state.diseases);
  const {
    steps: { generalInfo: generalInfoStepData, therapyArea: therapyAreaStepData, services: servicesStepData },
  } = useSelector((state: RootState) => state.projectEditWizard);

  const handleClose = (_: any, reason: string): void => {
    if (reason == 'backdropClick') {
      return;
    }

    const projectCreatePayload =
      generalInfoStepData && therapyAreaStepData && servicesStepData
        ? prepareProjectCreatePayload(generalInfoStepData, therapyAreaStepData, servicesStepData, diseases)
        : ({} as PostCreateProjectPayload);
    const hasNoEditChanges =
      project &&
      isEqual(
        {
          projectName: project?.projectName,
          projectCode: project?.projectCode,
          clientName: project?.clientName,
          businessUnitId: project?.businessUnitId,
          projectStartsAt: project?.projectStartsAt,
          projectFinishesAt: project?.projectFinishesAt,
          description: project?.description,
          therapyAreaIds: project?.therapyAreaIds,
          diseaseIds: project?.diseaseIds,
        },
        {
          projectName: projectCreatePayload.projectName,
          projectCode: projectCreatePayload.projectCode,
          clientName: projectCreatePayload.clientName,
          businessUnitId: projectCreatePayload.businessUnitId,
          projectStartsAt: projectCreatePayload.projectStartsAt,
          projectFinishesAt: projectCreatePayload.projectFinishesAt,
          description: projectCreatePayload.description,
          therapyAreaIds: projectCreatePayload.therapyAreaIds,
          diseaseIds: projectCreatePayload.diseaseIds,
        },
      );

    if (reason === FORM_DIALOG_ASK_BEFORE_CLOSE && !hasNoEditChanges) {
      snackbar({
        variant: 'warning',
        headline: 'Are you sure you want to cancel the current process?',
        message: 'Any information entered will be lost',
        action: {
          title: 'Confirm Cancelling',
          handler: () => {
            dispatch(resetProjectEditWizard());
            onClose();
          },
        },
      });
    } else {
      dispatch(resetProjectEditWizard());
      onClose();
    }
  };

  const navigateNext =
    (stepsCount: number): (() => void) =>
    (): void => {
      const index: number = stepIndex + 1;
      const normalizedIndex = index < stepsCount ? index : stepsCount - 1;

      setStepIndex(normalizedIndex);
    };

  const navigateBack = (): void => {
    const index: number = stepIndex - 1;
    const normalizedIndex = index < 0 ? 0 : index;

    setStepIndex(normalizedIndex);
  };

  useEffect(() => {
    if (project) {
      dispatch(setProjectEditId(project.id));
      dispatch(setProjectEditWizardGeneralInfo(prepareGeneralInfoStepData(project)));
      dispatch(setProjectEditWizardTherapyArea(prepareTherapyAreaStepData(project, diseases)));
      dispatch(setProjectEditWizardServices(prepareServicesStepData(project)));
    }
  }, [project]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    stepIndex,
    onClose: handleClose,
    navigateBack,
    navigateNext,
  };
};
