import { IconActionsSVG } from 'assets/svg';
import { useState } from 'react';

import { Component } from 'types-common';

import { Menu, MenuItem } from '../Menu';
import { ActionsMenuButton, ActionsMenuStyled } from './ActionsMenu.styled';

export interface ActionsMenuItem {
  name: string;
  actionHandler: () => void;
  disabled?: boolean;
}
export interface ActionsMenuProps {
  icon?: JSX.Element;
  items: ActionsMenuItem[];
  disabled?: boolean;
}

export const ActionsMenu: Component<ActionsMenuProps> = ({ icon, items, disabled }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <ActionsMenuStyled>
      <ActionsMenuButton isOpen={open} onClick={handleClick} disabled={disabled}>
        {icon || <IconActionsSVG />}
      </ActionsMenuButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {items.map(({ name, actionHandler, disabled }, index) => (
          <MenuItem
            key={`actions-menu-item-${index}`}
            onClick={() => {
              actionHandler();
              handleClose();
            }}
            disabled={disabled}>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </ActionsMenuStyled>
  );
};
