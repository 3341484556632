import { SelectChangeEvent } from '@mui/material';

import { Button } from 'components/Button/Button';
import { DialogActions, DialogDescription, DialogIconClose, DialogTitle } from 'components/Dialog';
import { Select, SelectOption } from 'components/Select/Select';
import { Component, ID } from 'types-common';

import { IconCloseSVG } from '../../../assets/svg';
import { Autocomplete, AutocompleteProps } from '../../../components/Autocomplete/Autocomplete';
import { InputWrap } from '../../../components/Select/Select.styled';
import { TextFieldError } from '../../../components/TextField/TextField.styled';
import { FORM_DIALOG_ASK_BEFORE_CLOSE } from '../../../constants/form';
import { Disease } from '../../healthcare-category-list/types';
import {
  ProjectEditWizardTherapyAreaValidationMap,
  useProjectTherapyAreaForm,
} from './hooks/useProjectTherapyAreaForm';
import { ProjectEditWizardTherapyArea } from './project-edit-wizard-reducer';
import { AddTherapyAreaButton, DeleteTherapyAreaButton } from './styled/ProjectTherapyAreaForm.styled';

export interface ProjectTherapyAreaFormUIProps {
  isEditMode: boolean;
  loading: boolean;
  therapyAreasList: ProjectEditWizardTherapyArea[];
  therapyAreaOptions: SelectOption[];
  selectedTherapyAreaIds: ID[];
  diseasesByTherapyAreaId: Record<ID, Disease[]>;
  groupsValidationMap: ProjectEditWizardTherapyAreaValidationMap;
  onTherapyAreaChange: (index: number) => (event: SelectChangeEvent<unknown>) => void;
  onDiseaseChange: (index: number) => AutocompleteProps['onChange'];
  onTherapyAreaRemove: (index: number) => () => void;
  onAddNewTherapyArea: () => void;
  onBack: () => void;
  onSubmit: () => void;
  onClose: (reason?: string) => void;
}
const ProjectTherapyAreaFormUI: Component<ProjectTherapyAreaFormUIProps> = ({
  isEditMode,
  loading,
  therapyAreasList,
  therapyAreaOptions,
  selectedTherapyAreaIds,
  diseasesByTherapyAreaId,
  groupsValidationMap,
  onTherapyAreaChange,
  onDiseaseChange,
  onTherapyAreaRemove,
  onAddNewTherapyArea,
  onBack,
  onSubmit,
  onClose,
}) => {
  const handleClose = (): void => {
    onClose(FORM_DIALOG_ASK_BEFORE_CLOSE);
  };

  return (
    <>
      <DialogTitle>
        <div>
          {isEditMode ? (
            <div>
              Edit Therapy Area
              <br /> and Disease
            </div>
          ) : (
            'Define Therapy Area'
          )}
        </div>
        <DialogIconClose role="button" onClick={handleClose}>
          <IconCloseSVG />
        </DialogIconClose>
      </DialogTitle>
      {isEditMode ? null : (
        <DialogDescription>
          Specify the therapy area and disease
          <br /> that your project focused on
        </DialogDescription>
      )}
      {therapyAreasList.map(({ therapyAreaId, diseaseNames }, index) => (
        <div key={`therapy-areas-list-item-${index}`}>
          <InputWrap>
            <Select
              label="Therapy Area"
              placeholder="Select Therapy Area here"
              value={therapyAreaId}
              options={therapyAreaOptions}
              disabledOptionValues={selectedTherapyAreaIds}
              onChange={onTherapyAreaChange(index)}
              isValid={Boolean(therapyAreaId)}
              isInvalid={groupsValidationMap[index]?.isTherapyAreaValid === false}
            />
          </InputWrap>
          <InputWrap>
            <Autocomplete
              key={therapyAreaId}
              id={`disease-autocomplete-${index}`}
              label={'Disease'}
              placeholder={'Select Diseases here'}
              options={diseasesByTherapyAreaId[therapyAreaId]?.map(({ name }) => name)}
              defaultValue={diseaseNames as string[]}
              onChange={onDiseaseChange(index)}
              disabled={!therapyAreaId}
              isValid={Boolean(diseaseNames.length)}
              isInvalid={groupsValidationMap[index]?.isDiseaseValid === false}
            />
          </InputWrap>

          {groupsValidationMap[index]?.isValid === false ? (
            <TextFieldError>You must select at least 1 therapy area & disease</TextFieldError>
          ) : null}

          {index < therapyAreasList.length - 1 ? (
            <DeleteTherapyAreaButton>
              <Button variant="text" type="button" fullWidth onClick={onTherapyAreaRemove(index)}>
                Delete
              </Button>
            </DeleteTherapyAreaButton>
          ) : null}
        </div>
      ))}
      <AddTherapyAreaButton>
        <Button variant="text" type="button" fullWidth onClick={onAddNewTherapyArea}>
          + Add Therapy Area
        </Button>
      </AddTherapyAreaButton>
      <DialogActions>
        <Button variant="outlined" type="button" disabled={loading} onClick={onBack}>
          Back
        </Button>
        <Button type="button" loading={loading} onClick={onSubmit}>
          {isEditMode ? 'Save' : 'Next'}
        </Button>
      </DialogActions>
    </>
  );
};

export type ProjectTherapyAreaFormProp = {
  onBack: () => void;
  onNext: () => void;
  onClose: (reason?: string) => void;
};
export const ProjectTherapyAreaForm: Component<ProjectTherapyAreaFormProp> = props => (
  <ProjectTherapyAreaFormUI {...useProjectTherapyAreaForm(props)} />
);
