import MuiFormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';

export const FormControlLabel = styled(MuiFormControlLabel)`
  && {
    margin-left: -6px;

    .MuiCheckbox-root {
      padding: 6px;
      margin-right: 6px;
    }

    .MuiFormControlLabel-label {
      font-size: 12px;
    }

    .Mui-disabled svg {
      opacity: 0.5;
    }
  }
`;
