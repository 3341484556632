import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbar } from '../../../../components/SnackbarProvider/useSnackbar';
import { RootState } from '../../../../config/store';
import { ID } from '../../../../types-common';
import { Disease } from '../../../healthcare-category-list/types';
import { fetchProjects } from '../../projects-list/projects-reducer';
import { CreateDiseasePayload, PostCreateProjectPayload, ProjectServicesConfig } from '../../types';
import {
  createDiseases,
  createProject,
  ProjectEditWizardGeneralInfoStep,
  ProjectEditWizardServicesStep,
  ProjectEditWizardTherapyArea,
  ProjectEditWizardTherapyAreaStep,
  resetProjectEditWizard,
  updateProject,
} from '../project-edit-wizard-reducer';

export const prepareProjectCreatePayload = (
  generalInfoStepData: ProjectEditWizardGeneralInfoStep,
  therapyAreaStepData: ProjectEditWizardTherapyAreaStep,
  servicesStepData: ProjectEditWizardServicesStep,
  diseases: Disease[],
): PostCreateProjectPayload => {
  const { projectName, projectCode, clientName, description, businessUnitId, projectFinishesAt, projectStartsAt } =
    generalInfoStepData;
  const therapyAreaIds = therapyAreaStepData.map(({ therapyAreaId }) => therapyAreaId);
  const diseaseIdsByNameMap: Record<string, ID> = {};

  diseases.forEach(({ name, id }) => {
    diseaseIdsByNameMap[name] = id;
  });

  const diseaseIds: ID[] = therapyAreaStepData.reduce((acc: ID[], { diseaseNames }: ProjectEditWizardTherapyArea) => {
    const ids = diseaseNames.map(name => diseaseIdsByNameMap[name]);
    return [...acc, ...ids];
  }, [] as ID[]);

  return {
    projectName,
    projectCode,
    clientName,
    description,
    businessUnitId,
    projectStartsAt,
    projectFinishesAt,
    therapyAreaIds,
    diseaseIds,
    isRepositoryOnly: Boolean(servicesStepData.isRepositoryOnly),
    enableNewsTracker: Boolean(servicesStepData.newsTracker),
  };
};

type UseProjectSave = () => { handleProjectSave: () => void; loading: boolean; success: boolean };
export const useProjectSave: UseProjectSave = () => {
  const dispatch = useDispatch();
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [diseasesUpdated, setDiseasesUpdated] = useState(false);
  const [success, setSuccess] = useState(false);
  const { snackbar } = useSnackbar();
  const { projects } = useSelector((state: RootState) => state.projects);
  const { diseases } = useSelector((state: RootState) => state.diseases);
  const {
    steps: { generalInfo: generalInfoStepData, therapyArea: therapyAreaStepData, services: servicesStepData },
    createLoading,
    updateLoading,
    error,
    projectEditId,
  } = useSelector((state: RootState) => state.projectEditWizard);
  const existingDiseaseNames = diseases.map(({ name }) => name);

  // On new diseases create success
  useEffect(() => {
    if (submitInProgress && diseases) {
      setDiseasesUpdated(true);
    }
  }, [diseases]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (diseasesUpdated && generalInfoStepData && therapyAreaStepData && servicesStepData) {
      const projectCreatePayload = prepareProjectCreatePayload(
        generalInfoStepData,
        therapyAreaStepData,
        servicesStepData,
        diseases,
      );

      if (projectEditId) {
        const { enableNewsTracker, ...projectBaseUpdateData } = projectCreatePayload;
        const project = projects.find(({ id }) => id === projectEditId);

        if (!project) {
          console.warn(`Can't update project with ID '${projectEditId}'. Missing project in store`);
          return;
        }

        const projectUpdatePayloadMeta = {
          ...projectBaseUpdateData,
          ...({
            newsTrackerConfig: {
              ...project.newsTrackerConfig,
              enabled: enableNewsTracker,
            },
          } as ProjectServicesConfig),
        };

        dispatch(
          updateProject({
            projectId: projectEditId,
            meta: projectUpdatePayloadMeta,
          }),
        );
      } else {
        dispatch(createProject(projectCreatePayload));
      }
    }
  }, [diseasesUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  // On new project create error
  useEffect(() => {
    if (submitInProgress && error) {
      setDiseasesUpdated(false);
      setSubmitInProgress(false);

      snackbar({
        variant: 'error',
        headline: 'Something went wrong, and the project hasn’t been created.',
        message: 'Please try again later.',
        withEmailNotice: true,
      });
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  // On new project create success
  useEffect(() => {
    if (submitInProgress && !createLoading && !error) {
      setSubmitInProgress(false);
      setSuccess(true);

      dispatch(resetProjectEditWizard());
      dispatch(fetchProjects());

      snackbar({
        variant: 'success',
        headline: 'The project has been created',
      });
    }
  }, [createLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  // On new project update success
  useEffect(() => {
    if (submitInProgress && !updateLoading && !error) {
      setSubmitInProgress(false);
      setSuccess(true);

      dispatch(resetProjectEditWizard());
      dispatch(fetchProjects());

      snackbar({
        variant: 'success',
        headline: 'The project information has been updated',
      });
    }
  }, [updateLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const createNewDiseases = (): void => {
    if (therapyAreaStepData) {
      const diseasesPayload: CreateDiseasePayload[] = therapyAreaStepData.reduce(
        (acc: CreateDiseasePayload[], { therapyAreaId, diseaseNames }: ProjectEditWizardTherapyArea) => {
          const diseases = diseaseNames
            .map(name => ({ name, therapyAreaId }))
            .filter(({ name }) => !existingDiseaseNames.includes(name));

          return [...acc, ...diseases];
        },
        [] as CreateDiseasePayload[],
      );

      if (diseasesPayload.length) {
        dispatch(createDiseases(diseasesPayload));
      } else {
        setDiseasesUpdated(true);
      }
    } else {
      console.warn(`Oops, can't create new Diseases. Wizard data is empty`);
    }
  };

  const handleProjectSave = (): void => {
    setSubmitInProgress(true);
    createNewDiseases();
  };

  return {
    handleProjectSave,
    loading: submitInProgress,
    success,
  };
};
