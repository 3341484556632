import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Controller } from 'react-hook-form';

import { NO_VALIDATION_MESSAGE } from '../../constants/form';
import { Component } from '../../types-common';
import { TextFieldError, TextFieldLabel, TextFieldRoot, TextFieldStyled } from './TextField.styled';

export type InputTextProps = TextFieldProps & {
  name: string;
  label?: string;
  control: any;
  rules?: {
    required?: any;
  };
  multiline?: boolean;
  onChangeCallback?: (option: string | number | boolean) => void;
  helperText?: string;
};

export const TextField: Component<InputTextProps> = ({
  name,
  control,
  label,
  rules,
  multiline,
  helperText,
  className,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value }, fieldState: { error, invalid } }) => {
        const isValid = value && !invalid;
        const isInvalid = error?.message;
        const showValidationError = error?.message && error.message !== NO_VALIDATION_MESSAGE;

        return (
          <TextFieldRoot>
            <TextFieldLabel>{label}</TextFieldLabel>
            <TextFieldStyled
              variant="standard"
              className={`${className} ${isValid ? 'valid' : ''} ${isInvalid ? 'invalid' : ''}`}
              name={name}
              value={value}
              multiline={multiline}
              onChange={onChange}
              onBlur={onBlur}
              helperText={helperText}
              {...props}
            />
            {showValidationError && <TextFieldError>{error.message}</TextFieldError>}
          </TextFieldRoot>
        );
      }}
    />
  );
};
