import orderBy from 'lodash/orderBy';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { mapNamedInstanceToSelectOption, SelectOption } from '../../../../components/Select';
import { RootState } from '../../../../config/store';
import { ProjectEditWizardTherapyArea, setProjectEditWizardTherapyArea } from '../project-edit-wizard-reducer';
import { ProjectTherapyAreaFormProp, ProjectTherapyAreaFormUIProps } from '../ProjectTherapyAreaForm';
import { useProjectSave } from './useProjectSave';

const validateTherapyAreaId = (wizardTherapyArea: ProjectEditWizardTherapyArea): boolean =>
  Boolean(wizardTherapyArea.therapyAreaId);
const validateDiseaseNames = (wizardTherapyArea: ProjectEditWizardTherapyArea): boolean =>
  Boolean(wizardTherapyArea.diseaseNames[0]);

export type ProjectEditWizardTherapyAreaValidationMap = {
  isTherapyAreaValid: boolean;
  isDiseaseValid: boolean;
  isValid: boolean;
}[];

export const useProjectTherapyAreaForm = ({
  onBack,
  onNext,
  onClose,
}: ProjectTherapyAreaFormProp): ProjectTherapyAreaFormUIProps => {
  const dispatch = useDispatch();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [groupsValidationMap, setGroupsValidationMap] = useState<ProjectEditWizardTherapyAreaValidationMap>([]);
  const {
    steps: { therapyArea: therapyAreaStepData },
    projectEditId,
  } = useSelector((state: RootState) => state.projectEditWizard);
  const { therapyAreas } = useSelector((state: RootState) => state.therapyAreas);
  const { diseasesByTherapyAreaId } = useSelector((state: RootState) => state.diseases);
  const emptyTherapyAreasListItem: ProjectEditWizardTherapyArea = { therapyAreaId: '', diseaseNames: [] };
  const defaultTherapyAreasList = therapyAreaStepData?.length ? [...therapyAreaStepData] : [emptyTherapyAreasListItem];
  const [therapyAreasList, setTherapyAreasList] = useState<ProjectEditWizardTherapyArea[]>(defaultTherapyAreasList);
  const selectedTherapyAreaIds = therapyAreasList.map(({ therapyAreaId }) => therapyAreaId);
  const therapyAreaOptions: SelectOption[] = orderBy(therapyAreas.map(mapNamedInstanceToSelectOption), 'name');
  const isEditMode = Boolean(projectEditId);
  const { handleProjectSave, loading, success } = useProjectSave();

  useEffect(() => {
    saveStepData();
  }, [therapyAreasList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFormSubmitted) {
      validateInputGroups();
    }
  }, [therapyAreasList, isFormSubmitted]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (success) {
      onClose('success');
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTherapyAreaChange: ProjectTherapyAreaFormUIProps['onTherapyAreaChange'] = index => event => {
    const therapyAreasListUpdated: ProjectEditWizardTherapyArea[] = therapyAreasList.map(therapyAreaItem => ({
      ...therapyAreaItem,
    }));
    therapyAreasListUpdated[index].therapyAreaId = event.target.value as string;
    therapyAreasListUpdated[index].diseaseNames = [];

    setTherapyAreasList(therapyAreasListUpdated);
  };
  const onDiseaseChange: ProjectTherapyAreaFormUIProps['onDiseaseChange'] = index => (_, selectedOptions) => {
    const therapyAreasListUpdated: ProjectEditWizardTherapyArea[] = therapyAreasList.map(therapyAreaItem => ({
      ...therapyAreaItem,
    }));
    therapyAreasListUpdated[index].diseaseNames = selectedOptions;

    setTherapyAreasList(therapyAreasListUpdated);
  };

  const onTherapyAreaRemove: ProjectTherapyAreaFormUIProps['onTherapyAreaRemove'] = index => () => {
    const therapyAreasListUpdated: ProjectEditWizardTherapyArea[] = [...therapyAreasList];

    therapyAreasListUpdated.splice(index, 1);

    setTherapyAreasList(therapyAreasListUpdated);
  };

  const onAddNewTherapyArea = (): void => {
    setTherapyAreasList([...therapyAreasList, emptyTherapyAreasListItem]);
  };

  const saveStepData = (): void => {
    const therapyAreas = therapyAreasList.filter(({ therapyAreaId }) => therapyAreaId);
    dispatch(setProjectEditWizardTherapyArea(therapyAreas));
  };

  const onBackStepChange: ProjectTherapyAreaFormUIProps['onBack'] = () => {
    saveStepData();
    onBack();
  };
  const onSubmit: ProjectTherapyAreaFormUIProps['onSubmit'] = () => {
    const validationMap = validateInputGroups();

    const filledFilledInputGroups = therapyAreasList.filter(({ therapyAreaId }, index) => index === 0 || therapyAreaId);
    const isFormValid = filledFilledInputGroups.every((_, index) => Boolean(validationMap[index]?.isValid));

    if (isFormValid) {
      saveStepData();
      (isEditMode ? handleProjectSave : onNext)();
    }

    setIsFormSubmitted(true);
  };

  const validateInputGroups = (): ProjectEditWizardTherapyAreaValidationMap => {
    const validationMap: ProjectEditWizardTherapyAreaValidationMap = [];

    therapyAreasList.forEach(item => {
      const isTherapyAreaValid = validateTherapyAreaId(item);
      const isDiseaseValid = validateDiseaseNames(item);

      validationMap.push({
        isTherapyAreaValid,
        isDiseaseValid,
        isValid: isTherapyAreaValid && isDiseaseValid,
      });
    });

    setGroupsValidationMap(validationMap);

    return validationMap;
  };

  return {
    isEditMode,
    loading,
    therapyAreasList,
    therapyAreaOptions,
    selectedTherapyAreaIds,
    diseasesByTherapyAreaId,
    groupsValidationMap,
    onTherapyAreaChange,
    onDiseaseChange,
    onTherapyAreaRemove,
    onAddNewTherapyArea,
    onBack: onBackStepChange,
    onSubmit,
    onClose,
  };
};
