import Skeleton from '@mui/material/Skeleton';

import { Component } from '../../types-common';

export const TableLoading: Component = () => (
  <>
    <Skeleton height={45} />
    <Skeleton height={45} />
    <Skeleton height={45} />
    <Skeleton height={45} />
    <Skeleton height={45} />
  </>
);
