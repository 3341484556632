import styled from 'styled-components';

import { DatePickerInputWrap } from '../../../../components/DatePickerInput/DatePickerInput.styled';
import { Label } from '../../../../components/Label';
import { InputWrap, SelectStyled } from '../../../../components/Select/Select.styled';
import { TableRowAlignTop } from '../../../../components/TableRow';
import { TextFieldError, TextFieldRoot } from '../../../../components/TextField/TextField.styled';
import { palette } from '../../../../config/theme';

export const NewsListItemRoot = styled(TableRowAlignTop)`
  align-items: stretch;
  padding: 0;
  border-left: 1px solid ${palette('grayLight')};
  border-bottom: 0;

  ${TextFieldRoot}, ${DatePickerInputWrap}, ${InputWrap}, .MuiAutocomplete-root {
    margin-bottom: 0;
    margin: -24px -14px;
    width: calc(100% + 28px);
    height: calc(100% + 48px);
    gap: 0;

    .MuiInputBase-root {
      border: 0;
      border-radius: 0;

      fieldset.MuiOutlinedInput-notchedOutline {
        border: 0;
        border-radius: 0;
      }
    }
  }

  ${TextFieldError} {
    position: relative;
    bottom: 30px;
    left: 14px;
    font-size: 12px;
    width: calc(100% - 28px);
  }

  ${InputWrap} {
    margin-bottom: 0;
  }

  ${SelectStyled} {
    &.MuiInputBase-root {
      padding: 0;
      border-radius: 0;

      .MuiSelect-select {
        padding-top: 24px;
        height: 100%;
        box-sizing: border-box;
      }

      .notranslate::after {
        color: ${palette('text')};
      }
    }

    .MuiSvgIcon-root {
      top: 20px;
    }
  }

  ${DatePickerInputWrap} {
    .MuiInputBase-root {
      display: flex;
    }
    input {
      padding: 0;
    }
    .MuiInputAdornment-root {
      width: 10px;
      position: relative;
      top: 10px;
      right: 17px;

      svg {
        fill: ${palette('primary')};
      }
    }
  }

  label {
    display: none;
  }

  .MuiFormControl-root {
    height: 100%;
  }
  .MuiFormControl-root,
  .MuiAutocomplete-root {
    &.valid .MuiInputBase-root,
    &.invalid .MuiInputBase-root,
    &.valid .MuiInputBase-root:hover fieldset.MuiOutlinedInput-notchedOutline,
    &.invalid .MuiInputBase-root:hover fieldset.MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-radius: 0;
    }

    &.invalid .MuiInputBase-root {
      background-color: ${palette('redBright')};
    }

    input::placeholder,
    textarea::placeholder {
      color: ${palette('subtext')} !important;
      opacity: 1;
    }
  }

  .invalid {
    input,
    textarea {
      color: ${palette('subtext')} !important;
    }
  }

  textarea::placeholder {
    color: ${palette('subtext')} !important;
    opacity: 1;
  }

  .MuiInputBase-root {
    display: block;
    padding: 24px 14px;
    width: 100%;
    height: 100%;
    align-items: flex-start;
  }

  textarea {
    padding-top: 0;
    padding-left: 0;
    height: 100% !important;
    font-size: 14px;
    min-height: 84px;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  .MuiAutocomplete-root {
    &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
      padding: 0;

      input {
        padding: 3px 6px;
        width: 100%;
      }
    }

    .MuiOutlinedInput-root {
      padding: 24px 14px !important;
    }
    .MuiChip-root {
      margin: 0;
      padding: 0;
      background-color: transparent;

      & + input::placeholder {
        visibility: hidden;
      }
    }

    input.MuiInputBase-input.MuiAutocomplete-input {
      width: 100%;
      padding: 0;
    }
  }

  .Mui-focused {
    background-color: ${palette('background')};
  }
`;

export const NewsListItemTherapyAreas = styled.div`
  height: 100%;

  .MuiAutocomplete-root {
    svg.MuiChip-deleteIcon {
      position: absolute;
      right: -6px;
      top: -6px;
      margin: 0;
    }

    .MuiButtonBase-root.MuiChip-root {
      padding: 0;
      margin-bottom: 6px;

      .MuiChip-label svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;
export const NewsListItemDiseases = styled.div`
  width: 100%;

  ${Label} {
    padding-right: 20px;
    white-space: initial;
    height: auto;
  }

  .MuiAutocomplete-root {
    width: calc(100% + 28px);

    .MuiAutocomplete-inputRoot {
      display: flex;
      gap: 10px;
    }

    .MuiChip-label {
      padding: 0 10px 10px 0;
    }
    .MuiButtonBase-root.MuiChip-root {
      padding: 0;
    }
    svg.MuiChip-deleteIcon {
      position: absolute;
      right: 12px;
      top: 4px;
      margin: 0;
    }
  }
`;

export const NewsListItemLinkEditWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -6px;

  ${TextFieldRoot} {
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
  }

  ${TextFieldError} {
    bottom: auto;
    left: 14px;
    padding-top: 4px;
    margin-bottom: 8px;
  }

  .MuiFormControl-root {
    height: auto;
  }

  .MuiInputBase-root {
    padding: 0;
    height: auto;
    border: 0;
    border-radius: 0;
  }

  input.MuiInputBase-input {
    border: 0;
    width: 100%;
    box-sizing: border-box;
    height: 30px;
    padding: 6px 14px;
  }

  .url-input input.MuiInputBase-input {
    color: ${palette('primary')};
  }
`;
