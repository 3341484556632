import { Configuration, LogLevel } from '@azure/msal-browser';

// @ts-ignore
export const clientId = window._env_.AUTH_CLIENT_ID;

if (!clientId) {
  console.error('ERROR: You must setup AUTH_CLIENT_ID env variable');
}

// @ts-ignore
export const redirectUri = window._env_.AUTH_REDIRECT_URI;

if (!redirectUri) {
  console.error('ERROR: You must setup AUTH_REDIRECT_URI env variable');
}

// @ts-ignore
export const policyName = window._env_.AUTH_POLICY_NAME;

if (!policyName) {
  console.error('ERROR: You must setup AUTH_POLICY_NAME env variable');
}

// @ts-ignore
export const tenantName = window._env_.AUTH_TENANT_NAME;

if (!tenantName) {
  console.error('ERROR: You must setup AUTH_TENANT_NAME env variable');
}

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
const b2cPolicies = {
  names: {
    signUpSignIn: policyName,
    // editProfile: "B2C_1_edit_profile_v2"
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${policyName}`,
    },
  },
  authorityDomain: `${tenantName}.b2clogin.com`,
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      // piiLoggingEnabled: true,
      // logLevel: LogLevel.Verbose,
      // TODO: avoid any
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequestConfig = {
  // scopes: ["User.Read"]
  scopes: ['openid', 'profile', 'email'],
  redirectUri,
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me', //e.g. https://graph.microsoft.com/v1.0/me
};
