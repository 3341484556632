import MuiCircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

export const ProgressContainerStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
`;

export const ProgressContainerStyledCentered = styled(ProgressContainerStyled)`
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
`;

export const CircularProgress = styled(MuiCircularProgress)`
  circle {
    color: ${({ theme }) => theme.palette.primary};
  }
`;
