import withRolePermission from 'access-control/withRolePermission';

import { Layout } from 'components/Layout';
import { Component, UserRole } from 'types-common';

import NewsList from '../../../news-tracker/news-list/NewsList';

const ProjectProfilePage: Component = () => (
  <Layout fullWidth>
    <NewsList />
  </Layout>
);

export default withRolePermission([UserRole.Admin])(ProjectProfilePage);
