import { TextField } from '@mui/material';
import * as React from 'react';

import { IconCloseSmallSVG } from '../../assets/svg';
import { Component } from '../../types-common';
import { TextFieldLabel } from '../TextField/TextField.styled';
import { AutocompleteStyled } from './Autocomplete.styled';

export interface AutocompleteProps {
  id: string;
  label: string;
  placeholder: string;
  options?: string[];
  defaultValue?: string[];
  onChange: (event: React.SyntheticEvent, selectedOptions: string[]) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  disabled?: boolean;
}
export const Autocomplete: Component<AutocompleteProps> = ({
  id,
  label,
  placeholder,
  options = [],
  defaultValue = [],
  onChange,
  isValid,
  isInvalid,
  disabled,
}) => (
  <>
    <TextFieldLabel>{label}</TextFieldLabel>
    <AutocompleteStyled
      multiple
      freeSolo
      filterSelectedOptions
      id={id}
      options={options}
      className={isValid ? 'valid' : isInvalid ? 'invalid' : ''}
      getOptionLabel={option => option as string}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={(event, selectedOptions) => onChange(event, selectedOptions as string[])}
      ChipProps={{ deleteIcon: <IconCloseSmallSVG /> }}
      renderInput={params => (
        <TextField {...params} InputLabelProps={{ disabled: true, shrink: false }} placeholder={placeholder} />
      )}
    />
  </>
);
