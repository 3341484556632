import styled, { css } from 'styled-components';

import { palette } from '../../config/theme';

interface TitleProps {
  withLine?: boolean;
}
export const Title = styled.div<TitleProps>`
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 24px;

  ${({ withLine = false }) =>
    withLine
      ? css`
          border-bottom: 1px solid ${palette('grayMedium')};
        `
      : null}
`;

export const TitleText = styled.div<TitleProps>`
  display: flex;
  align-items: center;
  gap: 14px;
`;
export const TitleActions = styled.div<TitleProps>`
  display: flex;
  gap: 12px;
`;
