import { Component } from '../../types-common';
import { Label } from '../Label/Label.styled';

interface BooleanLabelProps {
  value: unknown;
}
export const BooleanLabel: Component<BooleanLabelProps> = ({ value }) => {
  const boolean = Boolean(value);

  return <Label color={boolean ? 'green' : 'red'}>{boolean ? 'Yes' : 'No'}</Label>;
};
