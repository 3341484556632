import { useState } from 'react';

import { Label } from 'components/Label/Label.styled';

import { Component } from '../../types-common';
import { IconButton } from '../IconButton/IconButton';
import { ExpandableLabelsRoot } from './ExpandableLables.styled';

interface CollapsedLabelsProps {
  labels: string[];
  show?: number;
}
export const ExpandableLabels: Component<CollapsedLabelsProps> = ({ labels, show = 1 }) => {
  const [showCount, setShowCount] = useState(show);
  const elements: JSX.Element[] = labels.map((name, index) => <Label key={`expandable-label-${index}`}>{name}</Label>);
  let hideCount = labels.length - showCount;
  hideCount = hideCount < 1 ? 0 : hideCount;

  const handleExpand = (): void => {
    setShowCount(labels.length);
  };

  return (
    <ExpandableLabelsRoot>
      {elements.slice(0, showCount)}
      {hideCount ? (
        <IconButton onClick={handleExpand}>
          <Label>+{hideCount}</Label>
        </IconButton>
      ) : null}
    </ExpandableLabelsRoot>
  );
};
