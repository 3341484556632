import { Component } from 'types-common';

import { Centered } from '../Centered';
import { LeanHeader } from '../Header';

export const LeanLayout: Component = ({ children }) => (
  <>
    <LeanHeader />
    <Centered>{children}</Centered>
  </>
);
