import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'config/store';

import { User } from '../../types';
import { UserManagementUIProps } from '../UserManagement';
import { fetchUsers } from '../users-reducer';

// const POLLING_INTERVAL = 3000;

export const useUserManagement = (): UserManagementUIProps => {
  const [users, setUsers] = useState<User[]>([]);
  // const [pollingCounter, setPollingCounter] = useState<number>(0);
  const dispatch = useDispatch();
  const { users: usersData, error } = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (!usersData.length) {
      dispatch(fetchUsers());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO: Figure out better option as soon as current solution floods the API logs and makes it hard to debug and monitor
  // useEffect(() => {
  //   setTimeout(() => {
  //     setPollingCounter(counter => counter + 1);
  //     dispatch(fetchUsers());
  //   }, POLLING_INTERVAL);
  // }, [pollingCounter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (usersData) {
      setUsers(usersData);
    }
  }, [usersData]);

  const onSearch: UserManagementUIProps['onSearch'] = event => {
    const inputText = event.target.value.toLowerCase();

    if (inputText) {
      const filteredUsers = usersData.filter(({ firstName, lastName }) =>
        `${firstName} ${lastName}`.toLowerCase().includes(inputText),
      );
      setUsers(filteredUsers);
    } else {
      setUsers(usersData);
    }
  };

  return {
    users,
    loading: !usersData.length,
    error,
    onSearch,
  };
};
