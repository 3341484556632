import styled from 'styled-components';

export const HeaderRoot = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  margin-bottom: 24px;
`;
export const HeaderCentered = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary};
  padding: 0 50px;

  @media screen and (max-width: 980px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
export const HeaderActions = styled.div`
  margin-left: auto;
`;

export const Logo = styled.div`
  margin-right: 56px;
`;
