import { LinearProgress as MuiLinearProgress } from '@mui/material';
import styled from 'styled-components';

export const LinearProgress = styled(MuiLinearProgress)`
  && {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.palette.grayMedium};
    margin-bottom: 18px;

    .MuiLinearProgress-bar {
      border-radius: 5px;
      background-color: ${({ theme }) => theme.palette.greenMedium};
    }
  }
`;
