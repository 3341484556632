import {format, formatInTimeZone} from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US';

export const formatTime = (time: string): string =>
  formatInTimeZone(new Date(time), 'America/New_York', 'MMM dd, yyyy (h:mm a) zzz', {
    locale: enUS,
  });

export const formatDate = (time: string): string =>
  formatInTimeZone(new Date(time), 'America/New_York', 'MM/dd/yyyy', {
    locale: enUS,
  });

export const formatDateSimple = (time: string): string =>
  format(new Date(time), 'MM/dd/yyyy', {
    locale: enUS,
  });
