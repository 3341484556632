import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from 'components/Button/Button';
import { Dialog, DialogActions, DialogTitle } from 'components/Dialog/Dialog.styled';
import { Progress } from 'components/Progress';
import { TextField } from 'components/TextField/TextField';
import { validation } from 'constants/form';
import { Component } from 'types-common';

const defaultValues = {
  email: '',
  password: '',
};

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Please make sure the email format is valid')
    .required('Please make sure you entered your email'),
  password: yup.string().trim().required('Please make sure you entered your password'),
});
type FormData = yup.InferType<typeof schema>;
export type PostAuthDummyLoginPayload = FormData;

export type DummySignInDialogProps = {
  loading: boolean;
  success: boolean;
  onSubmit: (formData: FormData) => void;
};

export const DummySignInDialog: Component<DummySignInDialogProps> = ({ loading, onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const disabled = loading || !isValid;

  if (loading) {
    return <Progress />;
  }

  return (
    <Dialog open>
      <DialogTitle>Welcome to IntelliTraQ</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField name="email" label="Email" control={control} rules={validation.required} />
        <TextField name="password" type="password" label="Password" control={control} rules={validation.required} />
        <DialogActions>
          <Button type="submit" disabled={disabled}>
            {loading ? 'Processing...' : 'Sign-in'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
