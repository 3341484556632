import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponseData } from 'error-handling';

import { ErrorMessage, ID, Maybe } from 'types-common';

import { Disease } from '../../healthcare-category-list/types';
import {
  CreateDiseasePayload,
  PatchUpdateProjectPayload,
  PostCreateProjectPayload,
  Project,
  ProjectServiceId,
} from '../types';

export interface ProjectEditWizardSteps {
  generalInfo: Maybe<ProjectEditWizardGeneralInfoStep>;
  therapyArea: Maybe<ProjectEditWizardTherapyAreaStep>;
  services: Maybe<ProjectEditWizardServicesStep>;
}

export type ProjectEditWizardGeneralInfoStep = Pick<
  Project,
  | 'projectName'
  | 'projectCode'
  | 'clientName'
  | 'description'
  | 'businessUnitId'
  | 'projectStartsAt'
  | 'projectFinishesAt'
>;

export interface ProjectEditWizardTherapyArea {
  therapyAreaId: ID;
  diseaseNames: string[];
}

// TODO: Rely on TherapyArea API interface
export type ProjectEditWizardTherapyAreaStep = ProjectEditWizardTherapyArea[];

export type ProjectEditWizardServicesStep = Record<ProjectServiceId, boolean> & {
  isRepositoryOnly: boolean;
};

export interface ProjectsState {
  steps: ProjectEditWizardSteps;
  projectEditId: ID | null;
  loading: boolean;
  createLoading: boolean;
  updateLoading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const defaultSteps: ProjectEditWizardSteps = {
  generalInfo: null,
  therapyArea: null,
  services: null,
};

const initialState: ProjectsState = {
  steps: defaultSteps,
  projectEditId: null,
  loading: false,
  createLoading: false,
  updateLoading: false,
  error: null,
};

const projectsReducer = createSlice({
  name: 'projectEditWizard',
  initialState,
  reducers: {
    resetProjectEditWizard(state) {
      state.steps = defaultSteps;
      state.projectEditId = null;
      state.loading = false;
      state.error = null;
    },
    setProjectEditId(state, action: PayloadAction<ID | null>) {
      state.projectEditId = action.payload;
    },
    setProjectEditWizardGeneralInfo(state, action: PayloadAction<ProjectEditWizardGeneralInfoStep | null>) {
      state.loading = false;
      state.steps.generalInfo = action.payload;
    },
    setProjectEditWizardTherapyArea(state, action: PayloadAction<ProjectEditWizardTherapyAreaStep | null>) {
      state.loading = false;
      state.steps.therapyArea = action.payload;
    },
    setProjectEditWizardServices(state, action: PayloadAction<ProjectEditWizardServicesStep | null>) {
      state.loading = false;
      state.steps.services = action.payload;
    },
    createProject(state, _action: PayloadAction<PostCreateProjectPayload>) {
      state.createLoading = true;
      state.error = null;
    },
    createProjectSuccess(state, _action: PayloadAction<Project>) {
      state.steps = defaultSteps;
      state.createLoading = false;
      state.error = null;
    },
    createProjectFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.createLoading = false;
      state.error = action.payload;
    },
    updateProject(state, _action: PayloadAction<PatchUpdateProjectPayload>) {
      state.updateLoading = true;
      state.error = null;
    },
    updateProjectSuccess(state, _action: PayloadAction<Project>) {
      state.steps = defaultSteps;
      state.updateLoading = false;
      state.error = null;
    },
    updateProjectFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.updateLoading = false;
      state.error = action.payload;
    },
    createDiseases(state, _action: PayloadAction<CreateDiseasePayload[]>) {
      state.loading = true;
      state.error = null;
    },
    createDiseaseSuccess(_state, _action: PayloadAction<Disease>) {
      console.log('New Diseases created successfully');
    },
    createDiseaseFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetProjectEditWizard,
  setProjectEditId,
  setProjectEditWizardGeneralInfo,
  setProjectEditWizardTherapyArea,
  setProjectEditWizardServices,
  createProject,
  createProjectSuccess,
  createProjectFailure,
  updateProject,
  updateProjectSuccess,
  updateProjectFailure,
  createDiseases,
  createDiseaseSuccess,
  createDiseaseFailure,
} = projectsReducer.actions;
export default projectsReducer.reducer;
