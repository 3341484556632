import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../config/store';
import { ProjectEditWizardGeneralInfoStep, setProjectEditWizardGeneralInfo } from '../project-edit-wizard-reducer';
import { ProjectGeneralInfoFormUIProps } from '../ProjectGeneralInfoForm';

export const useProjectGeneralInfoForm = (onNext: () => void): ProjectGeneralInfoFormUIProps => {
  const dispatch = useDispatch();
  const {
    steps: { generalInfo: generalInfoStepData },
    projectEditId,
  } = useSelector((state: RootState) => state.projectEditWizard);
  const { businessUnits } = useSelector((state: RootState) => state.businessUnits);
  const { projects } = useSelector((state: RootState) => state.projects);
  const usedProjectCodes = projects.filter(({ id }) => id !== projectEditId).map(({ projectCode }) => projectCode);
  const isEditMode = Boolean(projectEditId);

  const defaultValues: ProjectGeneralInfoFormUIProps['defaultValues'] = {
    projectName: generalInfoStepData?.projectName || '',
    projectCode: generalInfoStepData?.projectCode || '',
    businessUnitId: generalInfoStepData?.businessUnitId || '',
    clientName: generalInfoStepData?.clientName || '',
    projectStartsAt: generalInfoStepData?.projectStartsAt || '',
    projectFinishesAt: generalInfoStepData?.projectFinishesAt || '',
    description: generalInfoStepData?.description || '',
  };

  const businessUnitOptions: ProjectGeneralInfoFormUIProps['businessUnitOptions'] = businessUnits.map(
    ({ id, name }) => ({ name, value: id }),
  );

  const onSubmit: ProjectGeneralInfoFormUIProps['onSubmit'] = formData => {
    dispatch(setProjectEditWizardGeneralInfo(formData as ProjectEditWizardGeneralInfoStep));
    onNext();
  };

  return {
    isEditMode,
    defaultValues,
    businessUnitOptions,
    usedProjectCodes,
    onSubmit,
  };
};
