import { formatInTimeZone } from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US';

import { ActionsMenu, ActionsMenuItem } from 'components/ActionsMenu';
import { Highlight } from 'components/Hightlight';
import { Label, LabelColor } from 'components/Label/Label.styled';
import { TableRowLine } from 'components/TableRow';
import { User, UserStatus } from 'modules/identity-management/types';
import { Component, UserRole } from 'types-common';

import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { useUsersListItem } from './hooks/useUsersListItem';
import {
  ColumnActions,
  ColumnCount,
  ColumnEmail,
  ColumnName,
  ColumnProjects,
  ColumnRole,
  ColumnStatus,
} from './styled/Columns.styled';

export type UsersListItemUIProps = {
  actions: ActionsMenuItem[];
};

const rolesMap: Record<UserRole, string> = {
  [UserRole.Client]: 'Client',
  [UserRole.Admin]: 'Admin',
  [UserRole.DataEntry]: 'Data Entry',
};

const statusesMap: Record<UserStatus, string> = {
  [UserStatus.Created]: 'Created',
  [UserStatus.EmailSent]: 'Invitation Sent',
  [UserStatus.EmailSendFailed]: 'Invitation Failed',
  [UserStatus.Active]: 'Active',
  [UserStatus.Expired]: 'Expired',
};

const statusLabelColorMap: Record<UserStatus, LabelColor> = {
  [UserStatus.Created]: 'green',
  [UserStatus.EmailSent]: 'orange',
  [UserStatus.EmailSendFailed]: 'red',
  [UserStatus.Active]: 'green',
  [UserStatus.Expired]: 'red',
};

const UsersListItemUI: Component<UsersListItemProps & UsersListItemUIProps> = ({ rowIndex, user, actions }) => {
  const { firstName, lastName, email, role, status, updatedAt } = user;
  const isActionsDisabled = false;
  const tooltipTitle = formatInTimeZone(new Date(updatedAt), 'America/New_York', 'MMM dd, yyyy (h:mm a) zzz', {
    locale: enUS,
  });

  return (
    <TableRowLine>
      <ColumnCount>{rowIndex}</ColumnCount>
      <ColumnName>
        <Highlight>{`${firstName} ${lastName}`}</Highlight>
      </ColumnName>
      <ColumnEmail>{email}</ColumnEmail>
      <ColumnRole>{rolesMap[role]}</ColumnRole>
      <ColumnStatus>
        <Tooltip title={tooltipTitle}>
          <Label color={statusLabelColorMap[status]}>{statusesMap[status]}</Label>
        </Tooltip>
      </ColumnStatus>
      <ColumnProjects>
        <Label>All projects available</Label>
      </ColumnProjects>
      <ColumnActions>
        <ActionsMenu items={actions} disabled={isActionsDisabled} />
      </ColumnActions>
    </TableRowLine>
  );
};

export interface UsersListItemProps {
  rowIndex: number;
  user: User;
}
const UsersListItem: Component<UsersListItemProps> = ({ rowIndex, user }) => (
  <UsersListItemUI {...useUsersListItem(user)} rowIndex={rowIndex + 1} user={user} />
);
export default UsersListItem;
