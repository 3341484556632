import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponseData } from 'error-handling';

import { ErrorMessage, Maybe } from 'types-common';

import { InviteStatus } from '../types';
import { PostInviteVerifyPayload, PostInviteVerifyResponse } from './sagas';

export interface UsersState {
  inviteId: string | null;
  status: InviteStatus | null;
  expired: boolean;
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: UsersState = {
  inviteId: null,
  status: null,
  expired: false,
  loading: true,
  error: null,
};

const reducer = createSlice({
  name: 'invite-verification',
  initialState,
  reducers: {
    inviteVerify(state, _action: PayloadAction<PostInviteVerifyPayload>) {
      state.loading = true;
      state.error = null;
    },
    inviteVerifySuccess(state, action: PayloadAction<PostInviteVerifyResponse>) {
      state.loading = false;
      state.inviteId = action.payload.inviteId;
      // TODO: handle already accepted and expired statuses here
      state.status = action.payload.status;
    },
    inviteVerifyFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      // TODO: we could have general error here, so setting expired to true is not correct
      state.expired = true;
      state.error = action.payload;
    },
  },
});

export const { inviteVerify, inviteVerifySuccess, inviteVerifyFailure } = reducer.actions;
export default reducer.reducer;
