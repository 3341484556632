import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Progress } from './components/Progress';
import { RootState } from './config/store';
import { fetchDiseases } from './modules/healthcare-category-list/diseases-reducer';
import { fetchTherapyAreas } from './modules/healthcare-category-list/therapy-areas-reducer';
import { fetchUsers } from './modules/identity-management/user-management/users-reducer';
import { fetchBusinessUnits } from './modules/project-management/projects-list/business-units-reducer';
import { fetchProjectServices } from './modules/project-management/projects-list/project-services-reducer';
import { Component } from './types-common';

export const WithPreloadedState: Component = ({ children }) => {
  const dispatch = useDispatch();
  const { users, loading: usersLoading } = useSelector((state: RootState) => state.users);
  const { projectServices, loading: projectServicesLoading } = useSelector((state: RootState) => state.projectServices);
  const { businessUnits, loading: businessUnitsLoading } = useSelector((state: RootState) => state.businessUnits);
  const { therapyAreas, loading: therapyAreasLoading } = useSelector((state: RootState) => state.therapyAreas);
  const { diseases, loading: diseasesLoading } = useSelector((state: RootState) => state.diseases);
  const loading =
    usersLoading || projectServicesLoading || businessUnitsLoading || therapyAreasLoading || diseasesLoading;

  useEffect(() => {
    if (!users.length) {
      dispatch(fetchUsers());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!projectServices.length) {
      dispatch(fetchProjectServices());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!businessUnits.length) {
      dispatch(fetchBusinessUnits());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!therapyAreas.length) {
      dispatch(fetchTherapyAreas());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!diseases.length) {
      dispatch(fetchDiseases());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? <Progress global /> : <>{children}</>;
};
