import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from 'config/store';

import { Maybe } from '../../../../types-common';
import { fetchProjects } from '../../../project-management/projects-list/projects-reducer';
import { NewsTrackerColumnName, NewsTrackerColumnsVisibility } from '../../types';
import { addEmptyNewsAlert, fetchNewsAlerts, updateProjectNewsTrackerConfig } from '../news-alerts-reducer';
import { NewsListUIProps } from '../NewsList';

export const useNewsList = (): NewsListUIProps => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { projects, loading: loadingProjects } = useSelector((state: RootState) => state.projects);
  const project = projects.find(({ id }) => id === projectId);
  const { newsAlerts, loading: loadingNewsAlerts, error } = useSelector((state: RootState) => state.newsAlerts);
  const columnsOrderDefault = project?.newsTrackerConfig.columnsOrder || [];
  const columnsVisibilityDefault = project?.newsTrackerConfig.columnsVisibility || null;
  const [columnsOrder, setColumnsOrder] = useState<NewsTrackerColumnName[]>(columnsOrderDefault);
  const [columnsVisibility, setColumnsVisibility] =
    useState<Maybe<NewsTrackerColumnsVisibility>>(columnsVisibilityDefault);
  const [fromColumnName, setFromColumnName] = useState<Maybe<NewsTrackerColumnName>>();
  const [toColumnName, setToColumnName] = useState<Maybe<NewsTrackerColumnName>>();

  useEffect(() => {
    if (!projects.length) {
      dispatch(fetchProjects());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (projectId) {
      dispatch(fetchNewsAlerts(projectId));
    }
  }, [projectId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (project) {
      setColumnsOrder(project.newsTrackerConfig.columnsOrder);
      setColumnsVisibility(project.newsTrackerConfig.columnsVisibility);
    }
  }, [project]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!project || !projectId) {
      return;
    }

    if (fromColumnName && toColumnName) {
      const newColumnsOrder = [...columnsOrder];
      const fromIndex = newColumnsOrder.indexOf(fromColumnName);
      const toIndex = newColumnsOrder.indexOf(toColumnName);

      const element = newColumnsOrder[fromIndex];
      newColumnsOrder.splice(fromIndex, 1);
      newColumnsOrder.splice(toIndex, 0, element);

      const isOrderChanged =
        newColumnsOrder.length &&
        project?.newsTrackerConfig.columnsOrder.some((columnName, index) => columnName !== newColumnsOrder[index]);

      if (isOrderChanged) {
        dispatch(
          updateProjectNewsTrackerConfig({
            projectId,
            newsTrackerConfig: {
              ...project.newsTrackerConfig,
              columnsOrder: newColumnsOrder,
            },
          }),
        );
      }

      setFromColumnName(null);
      setToColumnName(null);
    }
  }, [project, fromColumnName, toColumnName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!project || !projectId) {
      return;
    }

    const isVisibilityChanged =
      columnsVisibility &&
      Object.keys(project.newsTrackerConfig.columnsVisibility).some(
        columnName =>
          project.newsTrackerConfig.columnsVisibility[columnName as NewsTrackerColumnName] !==
          columnsVisibility[columnName as NewsTrackerColumnName],
      );

    if (isVisibilityChanged) {
      dispatch(
        updateProjectNewsTrackerConfig({
          projectId,
          newsTrackerConfig: {
            ...project.newsTrackerConfig,
            columnsVisibility,
          },
        }),
      );
    }
  }, [columnsVisibility]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // TODO: Add snackbars error handling
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const onOrderChange: NewsListUIProps['onOrderChange'] = (fromColumnName, toColumnName) => {
    setFromColumnName(fromColumnName);
    setToColumnName(toColumnName);
  };

  const onVisibilityChange: NewsListUIProps['onVisibilityChange'] = (columnName, isShown) => {
    setColumnsVisibility({
      ...columnsVisibility,
      [columnName]: isShown,
    } as NewsTrackerColumnsVisibility);
  };

  const onAddNewsAlert = (): void => {
    dispatch(addEmptyNewsAlert());
  };

  return {
    newsAlerts,
    columnsOrder,
    columnsVisibility,
    loading: loadingProjects || loadingNewsAlerts,
    onAddNewsAlert,
    onOrderChange,
    onVisibilityChange,
  };
};
