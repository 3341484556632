import { FC, PropsWithChildren } from 'react';

export type Maybe<T> = T | null | undefined;
export type Component<T = object> = FC<PropsWithChildren<T>>;
export type ID = string;
export type DateISO = string;
export type AccessToken = string;
export type ErrorMessage = string;
export type FailureResponse = {
  error: string;
  message: string;
  status: number;
};
export type ApiResponse<T> = T | FailureResponse;

export enum UserRole {
  Admin = 'Admin',
  DataEntry = 'DataEntry',
  Client = 'Client',
}

export interface NamedInstance {
  id: ID;
  name: string;
}

export enum DraggableObject {
  TABLE_HEAD = 'table-head',
}
