import withRolePermission from 'access-control/withRolePermission';

import { Layout } from 'components/Layout';
import UserManagement from 'modules/identity-management/user-management/UserManagement';
import { Component, UserRole } from 'types-common';

const UserManagementPage: Component = () => (
  <Layout>
    <UserManagement />
  </Layout>
);

export default withRolePermission([UserRole.Admin, UserRole.Client, UserRole.DataEntry])(UserManagementPage);
