import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponseData } from 'error-handling';

import { ErrorMessage, Maybe } from 'types-common';

import { ProjectService } from '../types';

export interface ProjectServicesState {
  projectServices: ProjectService[];
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: ProjectServicesState = {
  projectServices: [],
  loading: true,
  error: null,
};

const projectServicesReducer = createSlice({
  name: 'projectServices',
  initialState,
  reducers: {
    fetchProjectServices(state) {
      state.loading = true;
      state.error = null;
    },
    fetchProjectServicesSuccess(state, action: PayloadAction<ProjectService[]>) {
      state.loading = false;
      state.projectServices = action.payload;
    },
    fetchProjectServicesFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchProjectServices, fetchProjectServicesSuccess, fetchProjectServicesFailure } =
  projectServicesReducer.actions;
export default projectServicesReducer.reducer;
