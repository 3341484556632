import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from 'styled-components';

import { palette } from '../../config/theme';
import { TextFieldError } from '../TextField/TextField.styled';

export const DatePicker = styled(MuiDatePicker)`
  && {
    input {
      font-size: 14px;
      height: 17px;
      line-height: 17px;
      padding: 9px 12px;
      border-radius: 6px;
      color: ${({ theme }) => theme.palette.subtext};
      text-transform: lowercase;
    }

    .MuiOutlinedInput-notchedOutline,
    .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
    .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
      border-width: 1.5px;
      border-color: ${palette('purplePale')};
      border-radius: 6px;
    }

    &.valid {
      .MuiOutlinedInput-notchedOutline,
      .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
        border-width: 1.5px;
        border-color: ${palette('greenMedium')};
      }
    }

    &.valid input {
      color: ${palette('text')};
    }

    &.invalid {
      .MuiOutlinedInput-notchedOutline,
      .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
        border-width: 1.5px;
        border-color: ${palette('redMedium')};
        color: ${palette('redMedium')};
      }
    }

    &.invalid input {
      color: ${palette('redMedium')};
    }
  }
`;

export const DatePickerInputWrap = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-bottom: 18px;

  & ${TextFieldError} {
    padding-top: 0;
  }
`;
export const DatePickerInputLabel = styled.div`
  color: ${palette('subtext')};
`;
