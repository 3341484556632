import { BaseVariant, useSnackbar as useNotistackSnackbar } from 'notistack';
import React from 'react';

import { IconCloseSVG } from '../../assets/svg';
import { Button } from '../Button/Button';
import { IconButton } from '../IconButton/IconButton';
import { SnackbarActions } from './styled/StyledMaterialDesignContent.styled';

interface UseSnackbarResult {
  snackbar: (props: {
    headline: string;
    message?: string | JSX.Element;
    variant: BaseVariant;
    action?: {
      title: string;
      handler: () => void;
    };
    onClose?: () => void;
    withEmailNotice?: boolean;
  }) => void;
}
export const useSnackbar = (): UseSnackbarResult => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  const snackbar: UseSnackbarResult['snackbar'] = ({
    headline,
    message = '',
    variant,
    action,
    onClose,
    withEmailNotice,
  }) => {
    const handleAction = (): void => {
      action?.handler();
      closeSnackbar();
    };

    const closeButton = (
      <IconButton onClick={() => closeSnackbar()}>
        <IconCloseSVG />
      </IconButton>
    );

    enqueueSnackbar(
      <>
        <div className="snackbar-headline">{headline}</div>
        {withEmailNotice ? (
          <>
            {message && <div>{message}</div>}
            <div>
              If the problem persists, please contact{' '}
              <a href="mailto:support@intellitraq.com">support@intellitraq.com</a>
            </div>
          </>
        ) : (
          message
        )}
      </>,
      {
        variant,
        onClose,
        action: action ? (
          <>
            <SnackbarActions>
              <Button onClick={handleAction}>{action.title}</Button>
            </SnackbarActions>
            {closeButton}
          </>
        ) : (
          closeButton
        ),
        preventDuplicate: true,
        autoHideDuration: variant === 'error' || variant === 'warning' ? null : 5000,
      },
    );
  };

  return {
    snackbar,
  };
};
