import { debounce, orderBy } from 'lodash';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from 'config/store';

import { labelColors } from '../../../../components/Label';
import { LabelsItem } from '../../../../components/Labels/Labels';
import { mapNamedInstanceToSelectOption, SelectOption } from '../../../../components/Select';
import { NewsAlert } from '../../types';
import { updateNewsAlert } from '../news-alerts-reducer';
import { NewsListItemFormData, NewsListItemUIProps } from '../NewsListItem';

export const STORE_UPDATE_DEBOUNCE_DELAY = 300;

const formDataToNewsAlert = (newsAlert: NewsAlert, formData: NewsListItemFormData): NewsAlert => {
  const {
    title,
    date,
    linkText,
    linkUrl,
    therapyAreaIds,
    diseaseIds,
    company,
    summary,
    asset,
    modality,
    ageGroup,
    implications,
    other,
    indication,
    businessStream,
    category,
    priorityLevel,
    geography,
  } = formData;

  return {
    ...newsAlert,
    ...(therapyAreaIds ? { therapyAreaIds: therapyAreaIds.map(({ value }) => value) } : {}),
    ...(diseaseIds ? { diseaseIds: diseaseIds.map(({ value }) => value) } : {}),
    title,
    date,
    company,
    summary,
    asset,
    modality,
    ageGroup,
    implications,
    other,
    indication,
    businessStream,
    link: {
      url: linkUrl,
      text: linkText,
    },
    category,
    priorityLevel,
    geography,
  } as NewsAlert;
};

export const useNewsListItem = (newsAlert: NewsAlert): NewsListItemUIProps => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { therapyAreaIds, diseaseIds } = newsAlert;
  const { projects } = useSelector((state: RootState) => state.projects);
  const project = projects.find(({ id }) => id === projectId);
  const { therapyAreas } = useSelector((state: RootState) => state.therapyAreas);
  const { diseases } = useSelector((state: RootState) => state.diseases);

  const diseaseOptions: SelectOption[] = useMemo(() => {
    return project
      ? diseases
          .filter(({ id }) => project.diseaseIds.includes(id))
          .map(({ id, name }) => ({
            name,
            value: id,
          }))
      : [];
  }, [project, diseases]);
  const diseasesSelected: SelectOption[] = diseaseOptions.filter(({ value }) => diseaseIds.includes(value));
  const diseasesSelectedLabels: LabelsItem[] = diseasesSelected.map(({ value, name }) => {
    const nameToColorsScale = Math.floor(name.length / labelColors.length);
    const colorIndex = name.length - labelColors.length * nameToColorsScale;

    return {
      id: value,
      name,
      color: labelColors[colorIndex],
    };
  });

  const therapyAreaOptions: SelectOption[] = useMemo(() => {
    return project
      ? orderBy(
          therapyAreas.filter(({ id }) => project.therapyAreaIds.includes(id)).map(mapNamedInstanceToSelectOption),
          'name',
        )
      : [];
  }, [project, therapyAreas]);
  const therapyAreasSelected: SelectOption[] = therapyAreaOptions.filter(({ value }) => therapyAreaIds.includes(value));

  const onSubmit = debounce((formData: NewsListItemFormData): void => {
    dispatch(updateNewsAlert(formDataToNewsAlert(newsAlert, formData)));
  }, STORE_UPDATE_DEBOUNCE_DELAY);

  return {
    loading: false,
    therapyAreaOptions,
    therapyAreasSelected,
    diseasesSelected,
    diseasesSelectedLabels,
    diseaseOptions,
    onSubmit,
  };
};
