import { Link as RouteLink } from 'react-router-dom';

import { Component } from 'types-common';

import StyledLink from './Link.styled';

export interface LinkProps {
  to: string;
  active?: boolean;
}

export const Link: Component<LinkProps> = ({ to, active, children }) => {
  const activeClass = active ? ' active' : '';

  return (
    <RouteLink to={to} className={activeClass}>
      <StyledLink>{children}</StyledLink>
    </RouteLink>
  );
};
