import React from 'react';
import { Controller } from 'react-hook-form';

import { NO_VALIDATION_MESSAGE } from '../../constants/form';
import { TextFieldError } from '../TextField/TextField.styled';
import { Select, SelectOption, SelectProps } from './Select';
import { InputWrap } from './Select.styled';

export type SelectInputProps<T> = {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  rules?: {
    required?: any;
  };
  helperText?: string;
  options: SelectOption[];
  onChange?: (option: SelectOption<T>) => void;
  disabled?: boolean;
};

export const SelectInput = <T,>({
  control,
  name,
  label,
  placeholder,
  rules,
  options,
  onChange: onChangeCallback,
  disabled = false,
}: SelectInputProps<T>): JSX.Element => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value }, fieldState: { error, invalid } }) => {
      const isValid = value && !invalid;
      const isInvalid = Boolean(error?.message);
      const showValidationError = error?.message && error.message !== NO_VALIDATION_MESSAGE;

      const handleChange: SelectProps['onChange'] = event => {
        onChange(event);
        onChangeCallback?.(options.find(({ value }) => value === event.target.value) as SelectOption<T>);
      };

      return (
        <InputWrap>
          <Select
            label={label}
            options={options}
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            isValid={isValid}
            isInvalid={isInvalid}
            onChange={handleChange}
          />
          {showValidationError && <TextFieldError>{error?.message}</TextFieldError>}
        </InputWrap>
      );
    }}
  />
);
