import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Profile } from 'modules/identity-management/types';
import { ErrorMessage, Maybe } from 'types-common';

import { FailureResponseData } from '../../../error-handling';

export interface ProfileState {
  profile: Maybe<Profile>;
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: ProfileState = {
  profile: null,
  loading: true,
  error: null,
};

const reducer = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    fetchProfile(state) {
      state.loading = true;
      state.error = null;
    },
    fetchProfileSuccess(state, action: PayloadAction<Profile>) {
      state.loading = false;
      state.profile = action.payload;
    },
    fetchProfileFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchProfile, fetchProfileSuccess, fetchProfileFailure } = reducer.actions;
export default reducer.reducer;
