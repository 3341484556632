import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../../../config/store';
import { ID } from '../../../../types-common';
import { IdToBooleanMap, Project, ProjectService } from '../../types';
import { ProjectsServicesListItemUIProps } from '../ProjectServicesListItem';

const mapProjectServiceIdToBoolean = (project: Project, services: ProjectService[]): IdToBooleanMap => {
  const map: IdToBooleanMap = {};

  services.forEach(({ id }) => {
    map[id] = project[`${id}Config`]?.enabled;
  });

  return map;
};

export const useProjectServiceListItem = (
  project: Project,
  onEdit: (projectId: ID) => void,
): ProjectsServicesListItemUIProps => {
  const navigate = useNavigate();
  const { projectServices } = useSelector((state: RootState) => state.projectServices);

  const onEditInfo = (): void => {
    onEdit(project.id);
  };

  const onViewProject = (): void => {
    navigate(`/project-management/${project.id}`);
  };

  return {
    servicesBooleanMap: mapProjectServiceIdToBoolean(project, projectServices),
    actions: [
      { name: 'Edit Project', actionHandler: onEditInfo },
      { name: 'View Project Profile', actionHandler: onViewProject },
    ],
  };
};
