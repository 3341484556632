import { AxiosError } from 'axios';

import { ErrorMessage, FailureResponse } from './types-common';

export interface FailureResponseData {
  error: string;
  message: string;
}

export const getErrorMessage = (error: unknown): FailureResponseData | ErrorMessage => {
  if (error instanceof AxiosError) {
    const failureResponse = (error as AxiosError<FailureResponse>).response?.data;
    return failureResponse || String(error);
  }

  return String(error);
};
