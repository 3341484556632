import { Component, Maybe } from 'types-common';

import { IconAddSVG } from '../../../assets/svg';
import { NewsTrackerSVG } from '../../../assets/svg/modules';
import { Centered } from '../../../components/Centered';
import { Content } from '../../../components/Content';
import { IconButton } from '../../../components/IconButton/IconButton';
import { Progress } from '../../../components/Progress';
import { Title, TitleActions, TitleText } from '../../../components/Title';
import { NewsAlert, NewsTrackerColumnName, NewsTrackerColumnsVisibility } from '../types';
import { useNewsList } from './hooks/useNewsList';
import { NewsColumnsVisibilityMenu } from './NewsColumnsVisibilityMenu';
import NewsListHead from './NewsListHead';
import NewsListItem from './NewsListItem';
import SaveButton from './SaveButton';
import { AddNewsAlert, NewsListRoot, NewsListTable, NoRecords, Scroll, ScrollContent } from './styled/NewsList.styled';

export interface NewsListUIProps {
  newsAlerts: NewsAlert[];
  columnsOrder: Maybe<NewsTrackerColumnName[]>;
  columnsVisibility: Maybe<NewsTrackerColumnsVisibility>;
  loading: boolean;
  onAddNewsAlert: () => void;
  onOrderChange: (fromColumnName: NewsTrackerColumnName, toColumnName: NewsTrackerColumnName) => void;
  onVisibilityChange: (key: keyof NewsTrackerColumnsVisibility, isEnabled: boolean) => void;
}

const NewsListUI: Component<NewsListUIProps> = ({
  newsAlerts,
  loading,
  columnsOrder,
  columnsVisibility,
  onAddNewsAlert,
  onOrderChange,
  onVisibilityChange,
}) =>
  loading || !columnsOrder || !columnsVisibility ? (
    <Centered>
      <Progress />
    </Centered>
  ) : (
    <Centered>
      <Content>
        <NewsListRoot>
          <Title>
            <TitleText>
              <NewsTrackerSVG />
              <div>News Tracker</div>
            </TitleText>
            <TitleActions>
              <NewsColumnsVisibilityMenu
                columnsVisibility={columnsVisibility}
                onVisibilityChange={onVisibilityChange}
              />
              <SaveButton />
            </TitleActions>
          </Title>
          <Scroll>
            <ScrollContent>
              <AddNewsAlert>
                <IconButton onClick={onAddNewsAlert}>
                  <IconAddSVG />
                </IconButton>
              </AddNewsAlert>
              <NewsListTable>
                <NewsListHead
                  columnsOrder={columnsOrder}
                  columnsVisibility={columnsVisibility}
                  onOrderChange={onOrderChange}
                />
                {newsAlerts.map(newsAlert => (
                  <NewsListItem
                    key={`news-alert-${newsAlert.id || newsAlert.tempId}`}
                    newsAlert={newsAlert}
                    columnsOrder={columnsOrder}
                    columnsVisibility={columnsVisibility}
                  />
                ))}
              </NewsListTable>
            </ScrollContent>
          </Scroll>
          {!newsAlerts.length ? (
            <NoRecords>
              There are no records to show yet. Please click “plus” button to add the first News alert
            </NoRecords>
          ) : null}
        </NewsListRoot>
      </Content>
    </Centered>
  );

const NewsList: Component = () => <NewsListUI {...useNewsList()} />;
export default NewsList;
