import styled, { css, FlattenInterpolation, ThemeProps } from 'styled-components';

import { Theme } from '../../config/theme';

export type LabelColor = 'green' | 'red' | 'gray' | 'yellow' | 'orange' | 'cyan' | 'blue' | 'pink' | 'purple';

export const labelColors: LabelColor[] = ['green', 'red', 'gray', 'yellow', 'orange', 'cyan', 'blue', 'pink', 'purple'];

interface LabelProps {
  color?: LabelColor;
}

const colorStyles = (_: Theme, variant: LabelColor): FlattenInterpolation<ThemeProps<Theme>> =>
  ({
    green: css`
      background: ${({ theme }) => theme.palette.greenLight};
    `,
    red: css`
      background: ${({ theme }) => theme.palette.redLight};
    `,
    gray: css`
      background: ${({ theme }) => theme.palette.grayMedium};
    `,
    yellow: css`
      background: ${({ theme }) => theme.palette.orangeLight};
    `,
    orange: css`
      background: ${({ theme }) => theme.palette.orangeMedium};
    `,
    cyan: css`
      background: ${({ theme }) => theme.palette.cyanMedium};
    `,
    blue: css`
      background: ${({ theme }) => theme.palette.blueLight};
    `,
    pink: css`
      background: ${({ theme }) => theme.palette.pinkLight};
    `,
    purple: css`
      color: ${({ theme }) => theme.palette.primary};
      background: ${({ theme }) => theme.palette.purpleBright};
    `,
  }[variant]);

export const Label = styled.div<LabelProps>`
  display: inline-flex;
  align-items: center;
  height: 21px;
  padding: 4px 6px;
  width: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  border-radius: 11px;
  white-space: nowrap;

  ${({ theme, color = 'gray' }) => colorStyles(theme, color as LabelColor)}
`;
