import { UserType } from '../modules/identity-management/types';
import { ID } from '../types-common';

export const FETCH_USERS_DELAY = 1000;

export enum AxiosErrors {
  NETWORK_ERROR = 'AxiosError: Network Error',
}

interface ApiUrlList {
  postAuthDummyLogin: () => string;
  postAuthAADB2CLogin: () => string;
  getAuthProfile: () => string;
  getUsersByType: (userType: UserType) => string;
  postInviteUser: () => string;
  getFindInviteById: (inviteId: ID) => string;
  patchInviteVerify: (code: string) => string;
  patchResendInvite: (inviteId: ID) => string;
  getProjects: () => string;
  getProjectServices: () => string;
  postCreateProject: () => string;
  patchUpdateProject: (projectId: ID) => string;
  patchUpdateProjectTherapyAreaIds: (projectId: ID) => string;
  patchUpdateProjectDiseaseIds: (projectId: ID) => string;
  patchUpdateProjectNewsTrackerConfig: (projectId: ID) => string;
  getBusinessUnits: () => string;
  getTherapyAreas: () => string;
  getDiseases: () => string;
  postCreateDisease: () => string;
  getNewsAlerts: (projectId: ID) => string;
  createNewsAlertMeta: (projectId: ID) => string;
  updateNewsAlertMeta: (projectId: ID, newsAlertId: ID) => string;
}

const apiUrl: ApiUrlList = {} as ApiUrlList;

/**
 * Auth
 */
apiUrl.postAuthDummyLogin = () => '/auth/dummy-login';
apiUrl.postAuthAADB2CLogin = () => '/auth/aadb2c-login';
apiUrl.getAuthProfile = () => '/auth/profile';

/**
 * User
 */
apiUrl.getUsersByType = userType => `/users?type=${userType}`;
apiUrl.postInviteUser = () => `/users/invite`;
apiUrl.getFindInviteById = inviteId => `/users/invite/${inviteId}`;
apiUrl.patchInviteVerify = code => `/users/invite/${code}/verify`;
apiUrl.patchResendInvite = inviteId => `/users/invite/${inviteId}/resend`;

/**
 * Project
 */
apiUrl.getProjects = () => `/projects`;
apiUrl.postCreateProject = () => `/projects`;
apiUrl.patchUpdateProject = projectId => `/projects/${projectId}`;
apiUrl.patchUpdateProjectTherapyAreaIds = projectId => `/projects/${projectId}/therapyAreaIds`;
apiUrl.patchUpdateProjectDiseaseIds = projectId => `/projects/${projectId}/diseaseIds`;
apiUrl.patchUpdateProjectNewsTrackerConfig = projectId => `/projects/${projectId}/newsTrackerConfig`;

/**
 * Project Service
 */
apiUrl.getProjectServices = () => `/services`;

/**
 * Business Unit
 */
apiUrl.getBusinessUnits = () => `/business-units`;

/**
 * Therapy Area
 */
apiUrl.getTherapyAreas = () => `/therapy-areas`;

/**
 * Diseases
 */
apiUrl.getDiseases = () => `/diseases`;
apiUrl.postCreateDisease = () => `/diseases`;

/**
 * News Tracker
 */
apiUrl.getNewsAlerts = projectId => `/projects/${projectId}/news`;
apiUrl.createNewsAlertMeta = projectId => `/projects/${projectId}/news`;
apiUrl.updateNewsAlertMeta = (projectId, newsAlertId) => `/projects/${projectId}/news/${newsAlertId}`;

export default apiUrl;
