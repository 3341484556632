import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { GlobalStyles, theme } from 'config/theme';

import { SnackbarProvider } from './components/SnackbarProvider/SnackbarProvider';
import { PageRouter } from './config/router';
import { store } from './config/store';
import { Component } from './types-common';

const App: Component = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <SnackbarProvider>
        <PageRouter />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>
);

export default App;
