import styled from 'styled-components';

export default styled.span`
  color: ${({ theme }) => theme.palette.primary};
  font-weight: 500;
  cursor: pointer;

  &:hover,
  &.active {
    color: ${({ theme }) => theme.palette.primary};
    text-decoration: underline;
  }
`;
