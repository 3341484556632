import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import Menu from '@mui/material/Menu';
import * as React from 'react';

import { Button } from '../../../components/Button/Button';
import { FormControlLabel } from '../../../components/Checkbox/Checkbox.styled';
import { CheckboxInput } from '../../../components/Checkbox/CheckboxInput';
import { Component } from '../../../types-common';
import { NewsTrackerColumnName, NewsTrackerColumnsVisibility } from '../types';
import { MenuTitle, NewsColumnsVisibilityMenuItem } from './styled/NewsColumnsVisibilityMenu.styled';

export interface NewsColumnsVisibilityMenuProps {
  columnsVisibility: NewsTrackerColumnsVisibility;
  onVisibilityChange: (key: NewsTrackerColumnName, isShown: boolean) => void;
}

const columnsConfig: Record<NewsTrackerColumnName, { name: string; hidden?: boolean; disabled?: boolean }> = {
  date: { name: 'Date', disabled: true },
  businessStream: { name: 'Business Stream' },
  diseaseIds: { name: 'Disease', disabled: true },
  company: { name: 'Company' },
  title: { name: 'Title', disabled: true },
  asset: { name: 'Asset' },
  summary: { name: 'Summary', disabled: true },
  link: { name: 'Source', disabled: true },
  modality: { name: 'Modality' },
  category: { name: 'Category' },
  priorityLevel: { name: 'Priority Level' },
  therapyAreaIds: { name: 'TA', disabled: true },
  ageGroup: { name: 'Age Group' },
  indication: { name: 'Indication' },
  geography: { name: 'Geography' },
  implications: { name: 'Implications' },
  other: { name: 'Other' },
};

export const NewsColumnsVisibilityMenu: Component<NewsColumnsVisibilityMenuProps> = ({
  columnsVisibility,
  onVisibilityChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleChange =
    (columnName: NewsTrackerColumnName): SwitchBaseProps['onChange'] =>
    (event): void => {
      onVisibilityChange(columnName, event.target.checked);
    };

  return (
    <div>
      <Button variant="outlined" onClick={handleClick}>
        Show Columns
      </Button>
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuTitle>Columns</MenuTitle>
        {Object.keys(columnsConfig).map((columnName, index) => {
          const { name, hidden, disabled } = columnsConfig[columnName as NewsTrackerColumnName];

          return hidden ? null : (
            <NewsColumnsVisibilityMenuItem key={`news-tracker-column-visibility-item-${index}`}>
              <FormControlLabel
                label={name}
                control={
                  <CheckboxInput
                    disabled={disabled}
                    defaultChecked={Boolean(columnsVisibility[columnName as NewsTrackerColumnName])}
                    onChange={handleChange(columnName as NewsTrackerColumnName)}
                  />
                }
              />
            </NewsColumnsVisibilityMenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
