import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from 'error-handling';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import api from 'config/api';
import apiUrl from 'constants/network';

import { ID } from '../../../types-common';
import { Invite, User, UserType } from '../types';
import { PostInviteUserPayload } from './UserInvitationForm';
import {
  findInviteByIdFailure,
  findInviteByIdSuccess,
  inviteUserFailure,
  inviteUserSuccess,
  PostResendInvitePayload,
  resendInviteFailure,
  resendInviteSuccess,
} from './users-invite-reducer';
import { fetchUsersFailure, fetchUsersSuccess } from './users-reducer';

// Fetch Users
export function* fetchUsersSaga(): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<User[]>> {
  try {
    const { status, data }: AxiosResponse<User[]> = yield call(api.get, apiUrl.getUsersByType(UserType.Internal));
    switch (status) {
      case 200:
        yield put(fetchUsersSuccess(data));
        break;
      default:
        yield put(fetchUsersFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(fetchUsersFailure(getErrorMessage(error)));
  }
}

// Invite User
export function* inviteUserSaga(
  action: PayloadAction<PostInviteUserPayload>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<Invite>> {
  try {
    const { status, data }: AxiosResponse<Invite> = yield call(api.post, apiUrl.postInviteUser(), action.payload);
    switch (status) {
      case 201:
        yield put(inviteUserSuccess(data));
        break;
      default:
        yield put(inviteUserFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(inviteUserFailure(getErrorMessage(error)));
  }
}

// Find Invite By ID
export function* findInviteByIdSaga(
  action: PayloadAction<ID>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<Invite>> {
  try {
    const { status, data }: AxiosResponse<Invite> = yield call(api.get, apiUrl.getFindInviteById(action.payload));
    switch (status) {
      case 200:
        yield put(findInviteByIdSuccess(data));
        break;
      default:
    }
  } catch (error) {
    yield put(findInviteByIdFailure(getErrorMessage(error)));
  }
}

// Resend Invite
export function* resendInviteSaga(
  action: PayloadAction<PostResendInvitePayload>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<Invite>> {
  try {
    const { status, data }: AxiosResponse<Invite> = yield call(
      api.patch,
      apiUrl.patchResendInvite(action.payload.inviteId),
    );
    switch (status) {
      case 200:
        yield put(resendInviteSuccess(data));
        break;
      default:
        yield put(resendInviteFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(resendInviteFailure(getErrorMessage(error)));
  }
}
