import { useEffect } from 'react';
import { useDrag } from 'react-dnd';

import { Component, DraggableObject } from '../../types-common';

export interface DraggableProps {
  id: string;
  type: DraggableObject;
  onDragStart?: (id: string) => void;
}

export const Draggable: Component<DraggableProps> = ({ id, children, type, onDragStart }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: type,
      item: { id },
      collect: monitor => ({
        item: monitor.getItem(),
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [],
  );

  useEffect(() => {
    if (isDragging && onDragStart) {
      onDragStart(id);
    }
  }, [isDragging, onDragStart]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={drag}
      style={{
        width: '100%',
        backgroundColor: isDragging ? '#9d98d9' : '#fff',
      }}>
      {children}
    </div>
  );
};
