import { Component } from '../../types-common';
import { CircularProgress, ProgressContainerStyled, ProgressContainerStyledCentered } from './Progress.styled';

interface ProgressProps {
  global?: boolean;
}
export const Progress: Component<ProgressProps> = ({ global }) =>
  global ? (
    <ProgressContainerStyledCentered>
      <CircularProgress />
    </ProgressContainerStyledCentered>
  ) : (
    <ProgressContainerStyled>
      <CircularProgress />
    </ProgressContainerStyled>
  );
