import withRolePermission from 'access-control/withRolePermission';

import { Layout } from 'components/Layout';
import { Component, UserRole } from 'types-common';

import MyProfile from './MyProfile';

const MyProfilePage: Component = () => (
  <Layout>
    <MyProfile />
  </Layout>
);

export default withRolePermission([UserRole.Admin, UserRole.Client, UserRole.DataEntry])(MyProfilePage);
