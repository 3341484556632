import { Component } from '../../types-common';
import { CircularProgress } from '../Progress/Progress.styled';
import { ButtonIcon, ButtonStyled } from './Button.styled';

export type ButtonVariant = 'contained' | 'outlined' | 'text';
export type ButtonSize = 'medium' | 'small';
export type ButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  icon?: Component;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

export const Button: Component<ButtonProps> = ({
  icon: Icon,
  variant = 'contained',
  size = 'small',
  type,
  onClick,
  fullWidth = false,
  disabled = false,
  loading = false,
  children,
}) => (
  <ButtonStyled
    variant={variant}
    size={size}
    type={type}
    onClick={onClick}
    fullWidth={fullWidth}
    disabled={disabled || loading}
    className={loading ? 'button-loading' : ''}>
    {loading ? (
      <span className="button-progress">
        <CircularProgress size={20} />
      </span>
    ) : (
      <>
        {Icon ? (
          <ButtonIcon>
            <Icon />
          </ButtonIcon>
        ) : null}
      </>
    )}
    <span className="button-text">{children}</span>
  </ButtonStyled>
);
