import styled, { css, FlattenInterpolation, ThemeProps } from 'styled-components';

import { Theme } from 'config/theme';

import { ButtonProps, ButtonSize, ButtonVariant } from './Button';

interface ButtonStyledProps extends Omit<ButtonProps, 'icon' | 'loading'> {
  variant: ButtonVariant;
  size: ButtonSize;
}

const variantStyles = (_: Theme, variant: ButtonVariant): FlattenInterpolation<ThemeProps<Theme>> =>
  ({
    contained: css`
      color: ${({ theme }) => theme.palette.white};
      background: ${({ theme }) => theme.palette.primary};
      border-color: ${({ theme }) => theme.palette.primary};

      &:hover {
        color: ${({ theme }) => theme.palette.white};
        background: ${({ theme }) => theme.palette.purpleMedium};
        border-color: ${({ theme }) => theme.palette.purpleMedium};
      }

      &:active,
      &[disabled] {
        color: ${({ theme }) => theme.palette.primary};
        background: ${({ theme }) => theme.palette.purpleBright};
        border-color: ${({ theme }) => theme.palette.purpleBright};

        & svg {
          fill: ${({ theme }) => theme.palette.purpleMedium};
        }
      }
    `,
    outlined: css`
      color: ${({ theme }) => theme.palette.primary};
      background: ${({ theme }) => theme.palette.white};
      border-color: ${({ theme }) => theme.palette.primary};

      &:hover {
        color: ${({ theme }) => theme.palette.primary};
        background: ${({ theme }) => theme.palette.background};
        border-color: ${({ theme }) => theme.palette.primary};
      }

      &:active,
      &[disabled] {
        color: ${({ theme }) => theme.palette.subtext};
        background: ${({ theme }) => theme.palette.white};
        border-color: ${({ theme }) => theme.palette.subtext};

        & svg {
          fill: ${({ theme }) => theme.palette.subtext};
        }
      }
      svg circle {
        color: ${({ theme }) => theme.palette.white};
      }
    `,
    text: css`
      color: ${({ theme }) => theme.palette.primary};
      background: ${({ theme }) => theme.palette.white};
      border-color: ${({ theme }) => theme.palette.white};

      &:hover {
        color: ${({ theme }) => theme.palette.primary};
        background: ${({ theme }) => theme.palette.background};
        border-color: ${({ theme }) => theme.palette.background};
      }

      &:active,
      &[disabled] {
        color: ${({ theme }) => theme.palette.subtext};
        background: ${({ theme }) => theme.palette.white};
        border-color: ${({ theme }) => theme.palette.white};

        & svg {
          fill: ${({ theme }) => theme.palette.subtext};
        }
      }
    `,
  }[variant]);

const sizeStyles = (_: Theme, size: ButtonSize): FlattenInterpolation<ThemeProps<Theme>> =>
  ({
    medium: css`
      padding: 16px 20px;
    `,
    small: css`
      padding: 8px 12px;
    `,
  }[size]);

export const ButtonStyled = styled.button<ButtonStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  border-width: 1.5px;
  border-style: solid;
  border-radius: 4px;

  ${({ fullWidth }) => css`
    width: ${fullWidth ? '100%' : 'auto'};
  `}

  ${({ theme, variant }) => variantStyles(theme, variant)}
  ${({ theme, size }) => sizeStyles(theme, size)}

  &[disabled] {
    cursor: default;
  }

  &.button-loading .button-progress {
    position: absolute;
    display: flex;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  &.button-loading .button-text {
    visibility: hidden;
  }
`;

export const ButtonIcon = styled.span`
  display: flex;
  margin-right: 8px;
`;
