import Skeleton from '@mui/material/Skeleton';

import { Content } from 'components/Content';
import { Title } from 'components/Title';
import { Profile } from 'modules/identity-management/types';
import { Component, Maybe } from 'types-common';

import { useMyProfile } from './hooks/useMyProfile';
import { MyProfileInfo, MyProfileItem } from './styled/MyProfile.styled';

export interface MyProfileUIProps {
  profile: Maybe<Profile>;
  loading: boolean;
}

const MyProfileUI: Component<MyProfileUIProps> = ({ profile, loading }) => {
  return (
    <Content>
      <Title>My Profile</Title>
      {loading || !profile ? (
        <>
          <Skeleton height={30} />
          <Skeleton height={30} />
        </>
      ) : (
        <MyProfileInfo>
          <MyProfileItem>
            <span>Name</span>
            {`${profile.firstName} ${profile.lastName}`}
          </MyProfileItem>
          <MyProfileItem>
            <span>Email</span>
            {profile.email}
          </MyProfileItem>
          <MyProfileItem>
            <span>Role</span>
            {profile.role}
          </MyProfileItem>
        </MyProfileInfo>
      )}
    </Content>
  );
};

const MyProfile: Component = () => <MyProfileUI {...useMyProfile()} />;
export default MyProfile;
