import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from 'error-handling';
import { all, call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import api from 'config/api';
import apiUrl from 'constants/network';

import { fetchDiseasesSaga } from '../../healthcare-category-list/sagas';
import { Disease } from '../../healthcare-category-list/types';
import { CreateDiseasePayload, PatchUpdateProjectPayload, PostCreateProjectPayload, Project } from '../types';
import {
  createDiseaseFailure,
  createDiseaseSuccess,
  createProjectFailure,
  createProjectSuccess,
  updateProjectFailure,
  updateProjectSuccess,
} from './project-edit-wizard-reducer';

export function* createProjectSaga(
  action: PayloadAction<PostCreateProjectPayload>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<Project>> {
  try {
    const { status, data }: AxiosResponse<Project> = yield call(api.post, apiUrl.postCreateProject(), action.payload);
    switch (status) {
      case 201:
        yield put(createProjectSuccess(data));
        break;
      default:
        yield put(createProjectFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(createProjectFailure(getErrorMessage(error)));
  }
}

export function* updateProjectSaga(
  action: PayloadAction<PatchUpdateProjectPayload>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<Project>> {
  try {
    const {
      projectId,
      meta: { newsTrackerConfig, therapyAreaIds, diseaseIds, ...meta },
    } = action.payload;
    const { status: newsTrackerConfigUpdateStatus }: AxiosResponse<Project> = yield call(
      api.patch,
      apiUrl.patchUpdateProjectNewsTrackerConfig(projectId),
      { newsTrackerConfig },
    );
    const { status: therapyAreaIdsUpdateStatus }: AxiosResponse<Project> = yield call(
      api.patch,
      apiUrl.patchUpdateProjectTherapyAreaIds(projectId),
      { therapyAreaIds },
    );
    const { status: diseaseIdsUpdateStatus }: AxiosResponse<Project> = yield call(
      api.patch,
      apiUrl.patchUpdateProjectDiseaseIds(projectId),
      { diseaseIds },
    );
    const { status: metaUpdateStatus, data }: AxiosResponse<Project> = yield call(
      api.patch,
      apiUrl.patchUpdateProject(projectId),
      meta,
    );

    if (
      newsTrackerConfigUpdateStatus === 200 &&
      therapyAreaIdsUpdateStatus === 200 &&
      diseaseIdsUpdateStatus === 200 &&
      metaUpdateStatus === 200
    ) {
      yield put(updateProjectSuccess(data));
    } else {
      yield put(
        updateProjectFailure(
          `Oops, something went wrong on saving Project (status codes ${newsTrackerConfigUpdateStatus}/${therapyAreaIdsUpdateStatus}/${diseaseIdsUpdateStatus}/${metaUpdateStatus})`,
        ),
      );
    }
  } catch (error) {
    yield put(createProjectFailure(getErrorMessage(error)));
  }
}

export function* createDiseaseSaga(
  payload: CreateDiseasePayload,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<Disease>> {
  try {
    const { status, data }: AxiosResponse<Disease> = yield call(api.post, apiUrl.postCreateDisease(), payload);
    switch (status) {
      case 201:
        yield put(createDiseaseSuccess(data));
        break;
      default:
        yield put(createDiseaseFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(createDiseaseFailure(getErrorMessage(error)));
  }
}

export function* createDiseasesSaga(
  action: PayloadAction<CreateDiseasePayload[]>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<Disease>> {
  try {
    // TODO: Fix `all()` effect typing
    // @ts-ignore
    yield all(action.payload.map(diseasePayload => call(api.post, apiUrl.postCreateDisease(), diseasePayload)));

    yield call(fetchDiseasesSaga);
  } catch (error) {
    yield put(createDiseaseFailure(getErrorMessage(error)));
  }
}
