import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponseData } from 'error-handling';

import { ErrorMessage, ID, Maybe } from 'types-common';

import { Disease } from './types';

export interface DiseasesState {
  diseases: Disease[];
  diseasesByTherapyAreaId: Record<ID, Disease[]>;
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: DiseasesState = {
  diseases: [],
  diseasesByTherapyAreaId: {},
  loading: true,
  error: null,
};

const diseasesReducer = createSlice({
  name: 'diseases',
  initialState,
  reducers: {
    fetchDiseases(state) {
      state.loading = true;
      state.error = null;
    },
    fetchDiseasesSuccess(state, action: PayloadAction<Disease[]>) {
      state.loading = false;
      state.diseases = action.payload;

      const diseasesByTherapyAreaId: Record<ID, Disease[]> = {};
      action.payload.forEach(disease => {
        const { therapyAreaId } = disease;

        if (!diseasesByTherapyAreaId[therapyAreaId]) {
          diseasesByTherapyAreaId[therapyAreaId] = [];
        }
        diseasesByTherapyAreaId[therapyAreaId].push(disease);
      });

      state.diseasesByTherapyAreaId = diseasesByTherapyAreaId;
    },
    fetchDiseasesFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchDiseases, fetchDiseasesSuccess, fetchDiseasesFailure } = diseasesReducer.actions;
export default diseasesReducer.reducer;
