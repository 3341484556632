import { TextField } from '@mui/material';
import * as React from 'react';

import { MenuItem } from 'components/Menu/Menu.styled';

import { IconCloseSmallSVG } from '../../assets/svg';
import { Component } from '../../types-common';
import {AutocompleteStyled} from "../Autocomplete/Autocomplete.styled";
import { SelectOption } from '../Select';
import { TextFieldLabel } from '../TextField/TextField.styled';

export interface AutocompleteSelectOptionsProps {
  id: string;
  label?: string;
  placeholder: string;
  options?: SelectOption[];
  defaultValue?: SelectOption[];
  onChange: (event: React.SyntheticEvent, selectedOptions: SelectOption[]) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  disabled?: boolean;
  // Currently the MUI 'getOptionLabel' handler should return only a string value.
  // TODO: Look for another labels customisation solution
  getOptionLabel?: (option: SelectOption) => string | JSX.Element;
  deleteIcon?: JSX.Element;
}
export const AutocompleteSelectOptions: Component<AutocompleteSelectOptionsProps> = ({
  id,
  label,
  placeholder,
  options = [],
  defaultValue = [],
  onChange,
  isValid,
  isInvalid,
  disabled,
  getOptionLabel,
  deleteIcon,
  ...props
}) => (
  <>
    {label ? <TextFieldLabel>{label}</TextFieldLabel> : null}
    <AutocompleteStyled
      multiple
      freeSolo
      filterSelectedOptions
      id={id}
      options={options}
      className={isValid ? 'valid' : isInvalid ? 'invalid' : ''}
      getOptionLabel={getOptionLabel || (((option: SelectOption) => option.name) as any)}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={(event, selectedOptions) => onChange(event, selectedOptions as SelectOption[])}
      ChipProps={{ deleteIcon: deleteIcon || <IconCloseSmallSVG /> }}
      renderOption={
        ((props: React.HTMLAttributes<HTMLLIElement>, option: SelectOption) => (
          <MenuItem {...props}>{option.name}</MenuItem>
        )) as any
      }
      renderInput={params => (
        <TextField {...params} InputLabelProps={{ disabled: true, shrink: false }} placeholder={placeholder} />
      )}
      componentsProps={{
        paper: {
          sx: {
            minWidth: 'fit-content',
          },
        },
      }}
      {...props}
    />
  </>
);
