import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponseData } from 'error-handling';
import { v4 as uuidV4 } from 'uuid';

import { ErrorMessage, ID, Maybe } from 'types-common';

import { Project } from '../../project-management/types';
import {
  CreateNewsAlertPayload,
  NewsAlert,
  UpdateNewsAlertMetaPayload,
  UpdateProjectNewsTrackerConfigPayload,
} from '../types';

export interface NewsAlertsState {
  newsAlerts: NewsAlert[];
  loading: boolean;
  createLoading: boolean;
  updateLoading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: NewsAlertsState = {
  newsAlerts: [],
  loading: true,
  createLoading: false,
  updateLoading: false,
  error: null,
};

const newsAlertsReducer = createSlice({
  name: 'newsAlerts',
  initialState,
  reducers: {
    addEmptyNewsAlert(state) {
      state.newsAlerts.unshift({
        tempId: uuidV4(),
        createdAt: new Date().toISOString(),
        id: '',
        version: 0,
        projectId: '',
        userId: '',
        therapyAreaIds: [],
        diseaseIds: [],
        status: '',
        title: '',
        date: '',
        summary: '',
        link: {
          url: '',
          text: '',
        },
        category: null,
        priorityLevel: null,
        company: null,
        asset: null,
        businessStream: null,
        modality: null,
        ageGroup: null,
        implications: null,
        other: null,
        indication: null,
        geography: null,
      });
    },
    updateNewsAlert(state, action: PayloadAction<NewsAlert>) {
      const index = state.newsAlerts.findIndex(({ tempId, id }) => {
        if (action.payload.tempId) {
          return action.payload.tempId === tempId;
        } else {
          return action.payload.id === id;
        }
      });
      const oldNewsAlert = state.newsAlerts[index];
      const newNewsAlert = action.payload;

      state.newsAlerts[index] = {
        ...oldNewsAlert,
        ...newNewsAlert,
      };
    },
    fetchNewsAlerts(state, _action: PayloadAction<ID>) {
      state.loading = true;
      state.error = null;
    },
    fetchNewsAlertsSuccess(state, action: PayloadAction<NewsAlert[]>) {
      state.loading = false;
      action.payload.sort((a, b) => {
        return new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1;
      });
      state.newsAlerts = action.payload;
    },
    fetchNewsAlertsFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateProjectNewsTrackerConfig(state, _action: PayloadAction<UpdateProjectNewsTrackerConfigPayload>) {
      state.error = null;
    },
    updateProjectNewsTrackerConfigSuccess(state, _action: PayloadAction<Project>) {
      state.error = null;
    },
    updateProjectNewsTrackerConfigFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.error = action.payload;
    },
    createNewsAlerts(state, _action: PayloadAction<CreateNewsAlertPayload[]>) {
      state.createLoading = true;
      state.error = null;
    },
    createNewsAlertsSuccess(state, _action: PayloadAction<undefined>) {
      state.createLoading = false;
      state.error = null;
    },
    createNewsAlertsFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.createLoading = false;
      state.error = action.payload;
    },
    updateNewsAlerts(state, _action: PayloadAction<UpdateNewsAlertMetaPayload[]>) {
      state.updateLoading = true;
      state.error = null;
    },
    updateNewsAlertsSuccess(state, _action: PayloadAction<undefined>) {
      state.updateLoading = false;
      state.error = null;
    },
    updateNewsAlertsFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.updateLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  addEmptyNewsAlert,
  updateNewsAlert,
  fetchNewsAlerts,
  fetchNewsAlertsSuccess,
  fetchNewsAlertsFailure,
  updateProjectNewsTrackerConfig,
  updateProjectNewsTrackerConfigSuccess,
  updateProjectNewsTrackerConfigFailure,
  createNewsAlerts,
  createNewsAlertsSuccess,
  createNewsAlertsFailure,
  updateNewsAlerts,
  updateNewsAlertsSuccess,
  updateNewsAlertsFailure,
} = newsAlertsReducer.actions;
export default newsAlertsReducer.reducer;
