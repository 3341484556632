import { AnyAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from 'error-handling';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import api from 'config/api';
import apiUrl from 'constants/network';

import { fetchDiseasesFailure, fetchDiseasesSuccess } from './diseases-reducer';
import { fetchTherapyAreasFailure, fetchTherapyAreasSuccess } from './therapy-areas-reducer';
import { Disease, TherapyArea } from './types';

export function* fetchTherapyAreasSaga(): Generator<
  CallEffect | PutEffect<AnyAction>,
  void,
  AxiosResponse<TherapyArea[]>
> {
  try {
    const { status, data }: AxiosResponse<TherapyArea[]> = yield call(api.get, apiUrl.getTherapyAreas());
    switch (status) {
      case 200:
        yield put(fetchTherapyAreasSuccess(data));
        break;
      default:
        yield put(fetchTherapyAreasFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(fetchTherapyAreasFailure(getErrorMessage(error)));
  }
}

export function* fetchDiseasesSaga(): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<Disease[]>> {
  try {
    const { status, data }: AxiosResponse<Disease[]> = yield call(api.get, apiUrl.getDiseases());
    switch (status) {
      case 200:
        yield put(fetchDiseasesSuccess(data));
        break;
      default:
        yield put(fetchDiseasesFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(fetchDiseasesFailure(getErrorMessage(error)));
  }
}
