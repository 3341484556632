import { DateISO, ID } from '../../types-common';

export interface NewsAlert {
  tempId?: ID;
  id: ID;
  version: number;
  projectId: ID;
  userId: ID;
  therapyAreaIds: ID[];
  diseaseIds: ID[];
  status: string;
  title: string;
  date: DateISO;
  category: NewsAlertCategory | null;
  summary: string;
  priorityLevel: NewsAlertPriorityLevel | null;
  link: {
    url: string;
    text: string;
  };
  company: string | null;
  asset: string | null;
  businessStream: string | null;
  modality: string | null;
  ageGroup: string | null;
  implications: string | null;
  other: string | null;
  indication: string | null;
  geography: NewsAlertGeography | null;
  createdAt: DateISO;
}

export type NewsTrackerColumnName =
  | 'therapyAreaIds'
  | 'diseaseIds'
  | 'title'
  | 'date'
  | 'company'
  | 'category'
  | 'summary'
  | 'asset'
  | 'priorityLevel'
  | 'link'
  | 'businessStream'
  | 'modality'
  | 'ageGroup'
  | 'implications'
  | 'other'
  | 'indication'
  | 'geography';

export type NewsTrackerColumnsVisibility = Record<NewsTrackerColumnName, boolean>;

export interface NewsTrackerConfig {
  enabled: boolean;
  columnsOrder: NewsTrackerColumnName[];
  columnsVisibility: NewsTrackerColumnsVisibility;
}

export interface UpdateProjectNewsTrackerConfigPayload {
  projectId: ID;
  newsTrackerConfig: NewsTrackerConfig;
}

export type CreateNewsAlertMeta = {
  title: string;
  date: DateISO;
  therapyAreaIds: ID[];
  diseaseIds: ID[];
  summary: string;
  linkUrl: string;
  linkText: string;
  company: string | null;
  category: NewsAlertCategory | null;
  asset: string | null;
  priorityLevel: NewsAlertPriorityLevel | null;
  businessStream: string | null;
  modality: string | null;
  ageGroup: string | null;
  implications: string | null;
  other: string | null;
  indication: string | null;
  geography: NewsAlertGeography | null;
};
export type CreateNewsAlertPayload = {
  projectId: ID;
  newsAlertId: ID | '';
  meta: CreateNewsAlertMeta;
};

type UpdateNewsAlertMeta = CreateNewsAlertMeta;
export type UpdateNewsAlertMetaPayload = {
  projectId: ID;
  newsAlertId: ID;
  meta: UpdateNewsAlertMeta;
};

export enum NewsAlertCategory {
  Clinical = 'Clinical',
  Commercial = 'Commercial',
  EarningsCall = 'Earnings Call',
  InvestorEvent = 'Investor Event',
  Regulatory = 'Regulatory',
  Other = 'Other',
}

export enum NewsAlertPriorityLevel {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum NewsAlertGeography {
  NorthAmerica = 'North America',
  LATAM = 'LATAM',
  Europe = 'Europe',
  APAC = 'APAC',
  MENA = 'MENA',
  Global = 'Global',
}
