import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import RootPage from 'components/RootPage/RootPage';

import { AppRoutes } from '../constants/navigation';
import InviteVerificationPage from '../modules/identity-management/invite-verification/InviteVerificationPage';
import MyProfilePage from '../modules/identity-management/my-profile/MyProfilePage';
import SignInPage from '../modules/identity-management/sign-in/SignInPage';
import { SignInBackdoorPage } from '../modules/identity-management/sign-in/SignInPageBackdoor';
import { WithAuth } from '../modules/identity-management/sign-in/WithAuth';
import UserManagementPage from '../modules/identity-management/user-management/UserManagementPage';
import ProjectProfilePage from '../modules/project-management/projects-list/project-profile/ProjectProfilePage';
import ProjectManagementPage from '../modules/project-management/projects-list/ProjectManagementPage';
import { Component } from '../types-common';

export const PageRouter: Component = () => (
  <Router>
    <WithAuth>
      <Routes>
        <Route path={AppRoutes.ROOT} element={<RootPage />} />
        <Route path={AppRoutes.INVITE_VERIFICATION} element={<InviteVerificationPage />} />
        <Route path={AppRoutes.USER_MANAGEMENT} element={<UserManagementPage />} />
        <Route path={AppRoutes.PROJECT_MANAGEMENT} element={<ProjectManagementPage />} />
        <Route path={AppRoutes.PROJECT_PROFILE} element={<ProjectProfilePage />} />
        <Route path={AppRoutes.MY_PROFILE} element={<MyProfilePage />} />
        <Route path={AppRoutes.SIGN_IN} element={<SignInPage />} />
        <Route path={AppRoutes.SIGN_IN_BACKDOOR} element={<SignInBackdoorPage />} />
        <Route path="*" element={<Navigate to={AppRoutes.ROOT} replace />} />
      </Routes>
    </WithAuth>
  </Router>
);
