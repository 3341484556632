import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';
import {Controller} from 'react-hook-form';

import {NO_VALIDATION_MESSAGE} from '../../constants/form';
import {Component} from '../../types-common';
import {TextFieldError} from '../TextField/TextField.styled';
import {DatePicker, DatePickerInputLabel, DatePickerInputWrap} from './DatePickerInput.styled';

export type DatePickerInputProps = {
  name: string;
  label?: string;
  control: any;
  minDate?: string;
  maxDate?: string;
  customErrorMessage?: string;
  onChange?: (isoDateString: string) => void;
};

export const DatePickerInput: Component<DatePickerInputProps> = ({
  name,
  control,
  label,
  minDate,
  maxDate,
  customErrorMessage,
  onChange,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onBlur, onChange: onChangeCallback }, fieldState: { error, invalid } }) => {
        const isValid = value && !invalid;
        const errorMessage = customErrorMessage || error?.message;
        const isInvalid = errorMessage;
        const showValidationError = errorMessage && errorMessage !== NO_VALIDATION_MESSAGE;

        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePickerInputWrap>
              <DatePickerInputLabel>{label}</DatePickerInputLabel>
              <DatePicker
                value={dayjs(value)}
                minDate={minDate ? dayjs(minDate) : undefined}
                maxDate={maxDate ? dayjs(maxDate) : undefined}
                className={`${isValid ? 'valid' : ''} ${isInvalid ? 'invalid' : ''}`}
                onAccept={onBlur}
                onChange={date => {
                  try {
                    // TODO: Optimise ISO string formatting using Date or Dayjs
                    const newDate = date ? `${dayjs(date as dayjs.Dayjs).format('YYYY-MM-DDTHH:mm:ss.000')}Z` : '';

                    onChangeCallback(newDate);
                    onChange?.(newDate);
                  } catch (err) {
                    console.warn(err);
                  }
                }}
                format="MM/DD/YYYY"
              />
              {showValidationError && <TextFieldError>{errorMessage}</TextFieldError>}
            </DatePickerInputWrap>
          </LocalizationProvider>
        );
      }}
    />
  );
};
