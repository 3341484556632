import { Component } from 'types-common';

import { WithPreloadedState } from '../../state-management';
import { Centered } from '../Centered';
import { Header } from '../Header';

interface LayoutProps {
  fullWidth?: boolean;
}
export const Layout: Component<LayoutProps> = ({ fullWidth, children }) => (
  <>
    <Header />
    {fullWidth ? (
      <WithPreloadedState>{children}</WithPreloadedState>
    ) : (
      <Centered>
        <WithPreloadedState>{children}</WithPreloadedState>
      </Centered>
    )}
  </>
);
