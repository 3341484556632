import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { User } from '../../types';
import { resendInvite } from '../users-invite-reducer';
import { UsersListItemUIProps } from '../UsersListItem';
import { useUserInvitationSnackbars } from './useUserInvitationSnackbars';

export const useUsersListItem = (user: User): UsersListItemUIProps => {
  const [resendEmail, setResendEmail] = useState<string>('');
  const dispatch = useDispatch();

  useUserInvitationSnackbars({ userEmail: resendEmail });

  const onResendInvitation = (): void => {
    const inviteId = user.inviteId;

    dispatch(resendInvite({ inviteId }));
    setResendEmail(user.email);
  };
  // const onEditProfile = (): void => {
  //   console.log('onEditProfile', id);
  // };
  // const onDeactivate = (): void => {
  //   console.log('onDeactivate', id);
  // };
  // const onDelete = (): void => {
  //   console.log('onDelete', id);
  // };

  return {
    actions: [
      { name: 'Resend invitation', actionHandler: onResendInvitation, disabled: false },
      // { name: 'Edit', actionHandler: onEditProfile },
      // { name: 'Deactivate', actionHandler: onDeactivate },
      // { name: 'Delete', actionHandler: onDelete },
    ],
  };
};
