import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FailureResponseData } from '../../../error-handling';
import { ErrorMessage, Maybe } from '../../../types-common';
import { Site } from './types';

export interface SitesState {
  sites: Site[];
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: SitesState = {
  sites: [],
  loading: true,
  error: null,
};

const reducer = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    fetchSites(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSitesSuccess(state, action: PayloadAction<Site[]>) {
      state.loading = false;
      state.sites = action.payload;
    },
    fetchSitesFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchSites, fetchSitesSuccess, fetchSitesFailure } = reducer.actions;
export default reducer.reducer;
