import { SyntheticEvent, useState } from 'react';

import { Component, ID, Maybe } from 'types-common';

import { Content } from '../../../components/Content';
import { TabPanel } from '../../../components/TabPanel/TabPanel';
import { Tab, Tabs } from '../../../components/Tabs';
import { Title } from '../../../components/Title';
import { ProjectEditWizard } from '../project-edit-wizard/ProjectEditWizard';
import { Project, ProjectService } from '../types';
import CreateNewProjectButton from './CreateNewProjectButton';
import { useProjectManagement } from './hooks/useProjectManagement';
import { ProjectGeneralInfoList } from './ProjectGeneralInfoList';
import { ProjectServicesList } from './ProjectServicesList';

export interface ProjectManagementListProps {
  projects: Project[];
  projectToEdit: Maybe<Project>;
  projectServices: ProjectService[];
  loading: boolean;
  onEdit: (projectId: ID) => void;
  onEditClose: () => void;
}

const ProjectManagementUI: Component<ProjectManagementListProps> = ({
  projects,
  projectServices,
  projectToEdit,
  loading,
  onEdit,
  onEditClose,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: SyntheticEvent, newValue: number): void => {
    setSelectedTab(newValue);
  };

  return (
    <>
      {projects.length ? <CreateNewProjectButton /> : null}
      {projectToEdit && (
        <ProjectEditWizard key={'project-edit-wizard-update'} project={projectToEdit} onClose={onEditClose} />
      )}
      <Content>
        <Title withLine>
          <div>Project Management</div>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab disableRipple label="General Information" />
            <Tab disableRipple label="Services" />
          </Tabs>
        </Title>
        <TabPanel value={selectedTab} index={0}>
          <ProjectGeneralInfoList projects={projects} onEdit={onEdit} loading={loading} />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <ProjectServicesList
            projects={projects}
            projectServices={projectServices}
            onEdit={onEdit}
            loading={loading}
          />
        </TabPanel>
      </Content>
    </>
  );
};

const ProjectManagement: Component = () => <ProjectManagementUI {...useProjectManagement()} />;
export default ProjectManagement;
