import styled from 'styled-components';

import { TableColumn } from 'components/TableColumn';
import { palette } from 'config/theme';

export const ColumnCount = styled(TableColumn)`
  min-width: 4%;
  color: ${palette('purplePale')};
  white-space: nowrap;
`;

export const ColumnName = styled(TableColumn)`
  min-width: 21%;
`;

export const ColumnEmail = styled(TableColumn)`
  min-width: 24%;
`;

export const ColumnRole = styled(TableColumn)`
  min-width: 14%;
`;

export const ColumnStatus = styled(TableColumn)`
  min-width: 14%;
`;

export const ColumnProjects = styled(TableColumn)`
  min-width: 19%;
`;

export const ColumnActions = styled(TableColumn)`
  min-width: 6%;
`;
