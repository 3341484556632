import styled from 'styled-components';

export const RepositoryOnly = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 18px;
  margin-bottom: 24px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.grayMedium}`};
`;

export const ProjectServices = styled.div`
  display: flex;
  margin-bottom: 36px;
`;
export const ProjectServicesGroup = styled.div`
  width: 50%;
`;
export const ProjectServicesGroupTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
`;
export const ProjectServiceCheckbox = styled.div``;
export const FormError = styled.div`
  color: ${({ theme }) => theme.palette.redMedium};
`;
