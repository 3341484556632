import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Invite } from 'modules/identity-management/types';
import { ErrorMessage, ID, Maybe } from 'types-common';

import { FailureResponseData } from '../../../error-handling';
import { PostInviteUserPayload } from './UserInvitationForm';

export interface PostResendInvitePayload {
  inviteId: ID;
}

export interface UsersInviteState {
  invitations: Invite[];
  version: number;
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: UsersInviteState = {
  invitations: [],
  version: 0,
  loading: true,
  error: null,
};

const reducer = createSlice({
  name: 'users-invite',
  initialState,
  reducers: {
    inviteUser(state, _action: PayloadAction<PostInviteUserPayload>) {
      state.loading = true;
      state.error = null;
    },
    inviteUserSuccess(state, action: PayloadAction<Invite>) {
      state.loading = false;
      state.invitations = [action.payload];
      state.version = state.version + 1;
    },
    inviteUserFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
    findInviteById(state, _action: PayloadAction<ID>) {
      state.loading = true;
      state.error = null;
    },
    findInviteByIdSuccess(state, action: PayloadAction<Invite>) {
      const invitation = action.payload;

      state.loading = false;
      state.invitations = [invitation];
      state.version = state.version + 1;
    },
    findInviteByIdFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
    resetInviteError(state) {
      state.error = null;
    },
    resendInvite(state, _action: PayloadAction<PostResendInvitePayload>) {
      state.loading = true;
      state.error = null;
    },
    resendInviteSuccess(state, action: PayloadAction<Invite>) {
      state.loading = false;
      state.invitations = [action.payload];
      state.version = state.version + 1;
    },
    resendInviteFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  inviteUser,
  inviteUserSuccess,
  inviteUserFailure,
  findInviteById,
  findInviteByIdSuccess,
  findInviteByIdFailure,
  resetInviteError,
  resendInvite,
  resendInviteSuccess,
  resendInviteFailure,
} = reducer.actions;
export default reducer.reducer;
