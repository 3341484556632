import { ID, NamedInstance } from '../../types-common';
import { NewsTrackerColumnName, NewsTrackerColumnsVisibility } from '../news-tracker/types';

export type Project = ProjectServicesConfig & {
  id: ID;
  projectName: string;
  projectCode: string;
  clientName: string;
  description: string;
  businessUnitId: string;
  isRepositoryOnly: boolean;
  projectStartsAt: string;
  projectFinishesAt: string;
  dataEntryUserIds: ID[];
  clientUserIds: ID[];
  therapyAreaIds: ID[];
  diseaseIds: ID[];
  version: number;
  createdAt: string;
  updatedAt: string;
};

export type ProjectServicesConfig = Record<ProjectServiceConfigKey, ProjectServiceConfig> & {
  newsTrackerConfig: NewsTrackerConfig;
};

export interface ProjectMetaPayload {
  projectName: string;
  projectCode: string;
  clientName: string;
  description: string;
  businessUnitId: string;
  isRepositoryOnly: boolean;
  projectStartsAt: string;
  projectFinishesAt: string;
  therapyAreaIds: ID[];
  diseaseIds: ID[];
}
export type PostCreateProjectPayload = ProjectMetaPayload & {
  enableNewsTracker: boolean;
};

export type PatchUpdateProjectPayload = {
  projectId: ID;
  meta: ProjectMetaPayload & ProjectServicesConfig;
};

export interface ProjectServiceConfig {
  enabled: boolean;
}

export type NewsTrackerConfig = ProjectServiceConfig & {
  columnsOrder: NewsTrackerColumnName[];
  columnsVisibility: NewsTrackerColumnsVisibility;
};

export interface CreateDiseasePayload {
  name: string;
  therapyAreaId: ID;
}

export enum ProjectServiceId {
  ClinicalTrialTracker = 'clinicalTrialTracker',
  NewsTracker = 'newsTracker',
  ProductProfiles = 'productProfiles',
  CompetitorProfiles = 'competitorProfiles',
  ConferenceMaterials = 'conferenceMaterials',
  Publications = 'publications',
  Newsletter = 'newsletter',
  CompanyMaterials = 'companyMaterials',
  PowerBI = 'powerBI',
  Reports = 'reports',
  KeyEvents = 'keyEvents',
}

export enum ProjectServiceConfigKey {
  ClinicalTrialTrackerConfig = 'clinicalTrialTrackerConfig',
  NewsTrackerConfig = 'newsTrackerConfig',
  ProductProfilesConfig = 'productProfilesConfig',
  CompetitorProfilesConfig = 'competitorProfilesConfig',
  ConferenceMaterialsConfig = 'conferenceMaterialsConfig',
  PublicationsConfig = 'publicationsConfig',
  NewsletterConfig = 'newsletterConfig',
  CompanyMaterialsConfig = 'companyMaterialsConfig',
  PowerBIConfig = 'powerBIConfig',
  ReportsConfig = 'reportsConfig',
  KeyEventsConfig = 'keyEventsConfig',
}
export type ProjectService = NamedInstance & {
  id: ProjectServiceId;
};
export type BusinessUnit = NamedInstance;
export type IdToNameMap = Record<ID, string>;
export type IdToBooleanMap = Record<ID, boolean>;

export interface OnCloseDialogHandler {
  onClose: () => void;
}
