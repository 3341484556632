import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../config/store';
import { ProjectEditWizardServicesStep, setProjectEditWizardServices } from '../project-edit-wizard-reducer';
import { ProjectServicesFormUIProps } from '../ProjectServicesForm';
import { useProjectSave } from './useProjectSave';

export const useProjectServicesForm = (
  onBack: () => void,
  onClose: (reason: string) => void,
): ProjectServicesFormUIProps => {
  const dispatch = useDispatch();
  const {
    steps: { services: servicesStepData },
  } = useSelector((state: RootState) => state.projectEditWizard);
  const { handleProjectSave, loading, success } = useProjectSave();
  const defaultServicesData: ProjectEditWizardServicesStep = {
    isRepositoryOnly: false,
    clinicalTrialTracker: false,
    newsTracker: false,
    productProfiles: false,
    competitorProfiles: false,
    conferenceMaterials: false,
    publications: false,
    newsletter: false,
    companyMaterials: false,
    powerBI: false,
    reports: false,
    keyEvents: false,
  };

  useEffect(() => {
    if (success) {
      onClose('success');
    }
  }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange: ProjectServicesFormUIProps['onChange'] = selectedServices => {
    if (!_.isEmpty(selectedServices)) {
      dispatch(setProjectEditWizardServices(selectedServices as ProjectEditWizardServicesStep));
    }
  };
  const onSubmit: ProjectServicesFormUIProps['onSubmit'] = selectedServices => {
    if (!_.isEmpty(selectedServices)) {
      handleProjectSave();
    } else {
      console.warn('Oops, form data is empty');
    }
  };

  return {
    defaultValues: servicesStepData || defaultServicesData,
    loading,
    onBack,
    onChange,
    onSubmit,
  };
};
