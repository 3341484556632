import { useLocation } from 'react-router-dom';

import { NavigationMenuItem, navigationMenuList } from 'constants/navigation';
import { Component } from 'types-common';

import { Link } from '../Link';
import NavigationStyled from './Navigation.syled';

const NavigationItem: Component<NavigationMenuItem> = ({ name, route }) => {
  const location = useLocation();
  const active = location.pathname.includes(route);

  return (
    <Link to={route} active={active}>
      {name}
    </Link>
  );
};

export const Navigation: Component = () => (
  <NavigationStyled>
    {navigationMenuList.map(({ name, route }) => (
      <NavigationItem key={`navigation-link-${name}`} name={name} route={route} />
    ))}
  </NavigationStyled>
);
