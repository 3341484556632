import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { Component } from 'types-common';

import { msalConfig } from '../../../config/msal-auth';

export const msalInstance = new PublicClientApplication(msalConfig);

export const WithAuth: Component = ({ children }) => <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
