import { FailureResponseData } from 'error-handling';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbar } from 'components/SnackbarProvider/useSnackbar';
import { RootState } from 'config/store';

import { FETCH_USERS_DELAY } from '../../../../constants/network';
import { resetInviteError } from '../users-invite-reducer';

const errorHeadlinesMap: Record<string, string> = {
  'Entity already exists': 'Invitation sending failed',
};
const errorMessagesMap: Record<string, string> = {
  'Entity already exists': 'The email address you provided already belongs to an existing user.',
};

interface UseUserInvitationSnackbarsProps {
  userEmail: string;
}
export const useUserInvitationSnackbars = ({ userEmail }: UseUserInvitationSnackbarsProps): void => {
  const dispatch = useDispatch();
  const { snackbar } = useSnackbar();
  const { invitations, error: errorData, version } = useSelector((state: RootState) => state.usersInvite);
  const invite = invitations.find(({ email }) => email === userEmail);

  const onSnackbarClose = (): void => {
    dispatch(resetInviteError());
  };

  useEffect(() => {
    if (invite?.id) {
      setTimeout(() => {
        snackbar({
          variant: 'success',
          headline: 'Invitation Email Sending in Progress',
          message:
            'Your user invitation email is currently being processed. Please wait while we ensure successful delivery to the recipient.',
        });
      }, FETCH_USERS_DELAY);
    }
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userEmail && errorData) {
      const { message = '' } = errorData as FailureResponseData;
      const defaultHeadline = 'We encountered an issue while delivering the invitation email.';
      const defaultMessage = 'Please ensure the email address is correct and try again later.';

      snackbar({
        variant: 'error',
        headline: errorHeadlinesMap[message] || defaultHeadline,
        message: errorMessagesMap[message] || defaultMessage,
        withEmailNotice: !errorMessagesMap[message],
        onClose: onSnackbarClose,
      });
    }
  }, [userEmail, errorData]); // eslint-disable-line react-hooks/exhaustive-deps
};
