export const validation = {
  required: { required: 'Field is required' },
};

export const MAX_INPUT_LENGTH = 60;
export const MAX_INPUT_LENGTH_SHORT = 50;
export const MAX_INPUT_LENGTH_LONG = 100;
export const MIN_PROJECT_CODE_LENGTH = 5;
export const MAX_TEXTAREA_LENGTH_SHORT = 500;
export const MAX_TEXTAREA_LENGTH_LONG = 1000;

export const MAX_NEWS_BUSINESS_STREAM_LENGTH = 50;

export const MATCH_ALPHABET_CHARACTERS = /^[aA-zZ\s]+$/;
export const NO_VALIDATION_MESSAGE = 'no_validation_message';

export const FORM_DIALOG_ASK_BEFORE_CLOSE = 'askBeforeClose';
export const FORM_DIALOG_CLOSE = 'close';
