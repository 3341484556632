import { IconDragSVG } from '../../../assets/svg';
import { Draggable } from '../../../components/Draggable/Draggable';
import { Droppable, DroppableProps } from '../../../components/Droppable/Droppable';
import { Component, DraggableObject } from '../../../types-common';
import { NewsTrackerColumnName } from '../types';
import { Cell, CellDragArea } from './styled/Columns.styled';

interface NewsListHeadCellProps {
  id: string;
  name: string;
  onDrop: (fromColumnName: NewsTrackerColumnName, toColumnName: NewsTrackerColumnName) => void;
}

export const NewsListHeadCell: Component<NewsListHeadCellProps> = ({ id, name, onDrop }) => (
  <Draggable id={id} type={DraggableObject.TABLE_HEAD}>
    <Droppable id={id} type={DraggableObject.TABLE_HEAD} onDrop={onDrop as DroppableProps['onDrop']}>
      <Cell>
        <div>{name}</div>
        <CellDragArea>
          <IconDragSVG />
        </CellDragArea>
      </Cell>
    </Droppable>
  </Draggable>
);
