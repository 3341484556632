import { AnyAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from 'error-handling';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import api from 'config/api';
import apiUrl from 'constants/network';

import { BusinessUnit, Project, ProjectService } from '../types';
import { fetchBusinessUnitsFailure, fetchBusinessUnitsSuccess } from './business-units-reducer';
import { fetchProjectServicesFailure, fetchProjectServicesSuccess } from './project-services-reducer';
import { fetchProjectsFailure, fetchProjectsSuccess } from './projects-reducer';

export function* fetchProjectsSaga(): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<Project[]>> {
  try {
    const { status, data }: AxiosResponse<Project[]> = yield call(api.get, apiUrl.getProjects());
    switch (status) {
      case 200:
        yield put(fetchProjectsSuccess(data));
        break;
      default:
        yield put(fetchProjectsFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(fetchProjectsFailure(getErrorMessage(error)));
  }
}

export function* fetchProjectServicesSaga(): Generator<
  CallEffect | PutEffect<AnyAction>,
  void,
  AxiosResponse<ProjectService[]>
> {
  try {
    const { status, data }: AxiosResponse<ProjectService[]> = yield call(api.get, apiUrl.getProjectServices());
    switch (status) {
      case 200:
        yield put(fetchProjectServicesSuccess(data));
        break;
      default:
        yield put(fetchProjectServicesFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(fetchProjectServicesFailure(getErrorMessage(error)));
  }
}

export function* fetchBusinessUnitsSaga(): Generator<
  CallEffect | PutEffect<AnyAction>,
  void,
  AxiosResponse<BusinessUnit[]>
> {
  try {
    const { status, data }: AxiosResponse<BusinessUnit[]> = yield call(api.get, apiUrl.getBusinessUnits());
    switch (status) {
      case 200:
        yield put(fetchBusinessUnitsSuccess(data));
        break;
      default:
        yield put(fetchBusinessUnitsFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(fetchBusinessUnitsFailure(getErrorMessage(error)));
  }
}
