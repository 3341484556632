import styled, { css } from 'styled-components';

import { palette } from '../../config/theme';

export const ActionsMenuStyled = styled.div`
  display: flex;
  cursor: pointer;
`;

const actionsMenuButtonActiveState = css`
  svg {
    fill: ${palette('yellowMedium')};

    path {
      fill: ${palette('white')};
    }
  }
`;

export const ActionsMenuButton = styled.button<{ isOpen: boolean }>`
  margin: 0;
  bottom: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  ${({ isOpen }) => (isOpen ? actionsMenuButtonActiveState : '')}

  &:disabled {
    cursor: default;
  }

  &:hover:not(:disabled) {
    ${actionsMenuButtonActiveState}
  }
`;
