import { MaterialDesignContent } from 'notistack';
import styled from 'styled-components';

import IconButtonStyled from 'components/IconButton/IconButton.styled';

export const StyledMaterialDesignContent = styled(MaterialDesignContent)`
  position: relative;
  width: 100%;
  min-height: 62px;
  padding: 10px 22px;
  color: ${({ theme }) => theme.palette.text};
  border-radius: 6px;
  background: ${({ theme }) => theme.palette.greenLight};
  box-shadow: 0 2px 9px 6px rgba(31, 39, 62, 0.08);
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 8px;
  }

  &.notistack-MuiContent-success {
    background: ${({ theme }) => theme.palette.greenLight};

    &:before {
      background: ${({ theme }) => theme.palette.greenMedium};
    }
  }

  &.notistack-MuiContent-warning {
    background: ${({ theme }) => theme.palette.orangeLight};

    &:before {
      background: ${({ theme }) => theme.palette.yellowMedium};
    }
  }

  &.notistack-MuiContent-error {
    background: ${({ theme }) => theme.palette.redLight};

    &:before {
      background: ${({ theme }) => theme.palette.redMedium};
    }
  }

  #notistack-snackbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }

  .snackbar-headline {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  & ${IconButtonStyled} {
    align-self: flex-start;
  }
`;

export const SnackbarActions = styled.div`
  margin-right: 36px;
`;
