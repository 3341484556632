import styled from 'styled-components';

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  min-height: 45px;
  font-size: 14px;
  line-height: 15px;
`;

export const TableRowLine = styled(TableRow)`
  border-bottom: ${({ theme }) => ` 1px solid ${theme.palette.grayLight}`};
`;

export const TableRowAlignTop = styled(TableRowLine)`
  align-items: flex-start;
  padding: 12px 0;
`;
