import styled from 'styled-components';

import { TableColumn } from 'components/TableColumn';
import { palette } from 'config/theme';

export const ColumnAuto = styled(TableColumn)`
  display: flex;
  //min-width: 129px;
  max-width: initial;
  white-space: nowrap;
  padding-right: 0;
  align-items: stretch;
`;
export const Cell = styled.div`
  width: 100%;
  padding: 24px 14px;
  border-right: 1px solid ${palette('grayMedium')};
  border-bottom: 1px solid ${palette('grayMedium')};
  text-wrap: initial;
  display: flex;
  justify-content: space-between;
`;
export const CellDragArea = styled.div`
  display: flex;
  padding: 0 4px;
  cursor: grab;
  margin-right: -4px;
`;

export const TherapyAreaCell = styled(Cell)`
  display: flex;
  flex-direction: column;
  width: 68px;
  gap: 10px;
`;

export const ColumnTherapyArea = styled(ColumnAuto)`
  min-width: 70px;
`;
export const ColumnTitle = styled(ColumnAuto)`
  min-width: 352px;
`;
export const ColumnDate = styled(ColumnAuto)`
  min-width: 129px;
`;
export const ColumnCompany = styled(ColumnAuto)`
  min-width: 185px;
`;
export const ColumnCategory = styled(ColumnAuto)`
  min-width: 156px;
`;
export const ColumnDisease = styled(ColumnAuto)`
  min-width: 169px;
`;
export const ColumnSummary = styled(ColumnAuto)`
  min-width: 293px;
`;
export const ColumnAsset = styled(ColumnAuto)`
  min-width: 135px;
`;
export const ColumnPriorityLevel = styled(ColumnAuto)`
  min-width: 177px;
`;
export const ColumnLink = styled(ColumnAuto)`
  min-width: 126px;
`;
export const ColumnBusinessStream = styled(ColumnAuto)`
  min-width: 198px;
`;
export const ColumnGeography = styled(ColumnAuto)`
  min-width: 156px;
`;
export const ColumnModality = styled(ColumnAuto)`
  min-width: 152px;
`;
export const ColumnAgeGroup = styled(ColumnAuto)`
  min-width: 164px;
`;
export const ColumnImplications = styled(ColumnAuto)`
  min-width: 271px;
`;
export const ColumnIndications = styled(ColumnAuto)`
  min-width: 165px;
`;
export const ColumnOther = styled(ColumnAuto)`
  min-width: 271px;
`;
