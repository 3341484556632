import { Component } from 'types-common';

import { Button } from '../../../components/Button/Button';
import { useSaveButton } from './hooks/useSaveButton';

export interface SaveButtonUIProps {
  loading: boolean;
  onSave: () => void;
}

const SaveButtonUI: Component<SaveButtonUIProps> = ({ loading, onSave }) => (
  <Button loading={loading} onClick={onSave}>
    Save
  </Button>
);

const SaveButton: Component = () => <SaveButtonUI {...useSaveButton()} />;
export default SaveButton;
