import MuiDialog from '@mui/material/Dialog/Dialog';
import MuiDialogActions from '@mui/material/DialogActions/DialogActions';
import MuiDialogTitle from '@mui/material/DialogTitle/DialogTitle';
import styled from 'styled-components';

export const Dialog = styled(MuiDialog)`
  && {
    .MuiDialog-container {
      align-items: baseline;
    }

    .MuiPaper-root {
      width: 400px;
      max-width: 100%;
      padding: 24px 24px 32px;
      margin-top: 92px;
      border-radius: 6px;
      background: #fff;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
      font-size: 12px;
      line-height: 14px;
      color: ${({ theme }) => theme.palette.text};
    }
  }
`;

export const DialogTitle = styled(MuiDialogTitle)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    padding: 0;
    margin-bottom: 26px;
    color: ${({ theme }) => theme.palette.grayDark};
  }
`;
export const DialogIconClose = styled.div`
  cursor: pointer;

  svg {
    display: flex;
  }
`;
export const DialogDescription = styled.div`
  margin-top: -18px;
  margin-bottom: 24px;

  color: ${({ theme }) => theme.palette.subtext};
`;
export const DialogActions = styled(MuiDialogActions)`
  && {
    justify-content: flex-end;
    padding: 36px 0 0;
    gap: 12px;
  }
`;
