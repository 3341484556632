import { SnackbarProvider as NotistackSnackbarProvider, useSnackbar } from 'notistack';

import { IconCloseSVG } from '../../assets/svg';
import { Component } from '../../types-common';
import { IconButton } from '../IconButton/IconButton';
import { StyledMaterialDesignContent } from './styled/StyledMaterialDesignContent.styled';

interface SnackbarCloseButtonProps {
  snackbarKey: string | number;
}
const SnackbarCloseButton: Component<SnackbarCloseButtonProps> = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <IconCloseSVG />
    </IconButton>
  );
};

export const SnackbarProvider: Component = ({ children }) => (
  <NotistackSnackbarProvider
    hideIconVariant
    Components={{
      success: StyledMaterialDesignContent,
      warning: StyledMaterialDesignContent,
      error: StyledMaterialDesignContent,
    }}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
    {children}
  </NotistackSnackbarProvider>
);
