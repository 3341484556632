import { ActionsMenu, ActionsMenuItem } from 'components/ActionsMenu';
import { Label } from 'components/Label/Label.styled';
import { TableRowAlignTop } from 'components/TableRow';
import { Component, ID } from 'types-common';

import { BooleanLabel } from '../../../components/BooleanLabel';
import { ExpandableLabels } from '../../../components/ExpandableLabels';
import { Link } from '../../../components/Link';
import { NumericDate } from '../../../components/NumericDate/NumericDate.styled';
import { Subtext } from '../../../components/Subtext/Subtext.styled';
import { UserInitialsLabel } from '../../../components/UserInitialsLabel/UserInitialsLabel';
import { formatDateSimple } from '../../../time-format';
import { User } from '../../identity-management/types';
import { IdToNameMap, Project } from '../types';
import { useProjectGeneralInfoListItem } from './hooks/useProjectGeneralInfoListItem';
import {
  ColumnActions,
  ColumnBusinessUnit,
  ColumnClientName,
  ColumnClientsInvited,
  ColumnCount,
  ColumnCreated,
  ColumnDataEntry,
  ColumnDisease,
  ColumnProjectDuration,
  ColumnProjectName,
  ColumnProjectСode,
  ColumnTherapyArea,
  ColumnUpdated,
} from './styled/Columns.styled';

export type ProjectsGeneralInfoListItemUIProps = {
  therapyAreaNamesMap: IdToNameMap;
  diseaseNamesMap: IdToNameMap;
  businessUnitNamesMap: IdToNameMap;
  dataEntryUsers: User[];
  actions: ActionsMenuItem[];
};

const ProjectGeneralInfoListItemUI: Component<
  Omit<ProjectsListItemProps, 'onEdit'> & ProjectsGeneralInfoListItemUIProps
> = ({ rowIndex, project, therapyAreaNamesMap, diseaseNamesMap, businessUnitNamesMap, dataEntryUsers, actions }) => {
  const {
    id,
    projectName,
    projectCode,
    clientName,
    businessUnitId,
    projectStartsAt,
    projectFinishesAt,
    clientUserIds,
    therapyAreaIds,
    diseaseIds,
    createdAt,
    updatedAt,
  } = project;

  return (
    <TableRowAlignTop>
      <ColumnCount>{rowIndex}</ColumnCount>
      <ColumnClientName>{clientName}</ColumnClientName>
      <ColumnProjectСode>{projectCode}</ColumnProjectСode>
      <ColumnProjectName>
        <Link to={`/project-management/${id}`}>{projectName}</Link>
      </ColumnProjectName>
      <ColumnTherapyArea>
        {therapyAreaIds.map((id, index) => (
          <Label color="purple" key={`therapy-area-${projectCode}-${index}`}>
            {therapyAreaNamesMap[id]}
          </Label>
        ))}
      </ColumnTherapyArea>
      <ColumnDisease>
        <ExpandableLabels labels={diseaseIds.map(id => diseaseNamesMap[id])} />
      </ColumnDisease>
      <ColumnBusinessUnit>{businessUnitNamesMap[businessUnitId]}</ColumnBusinessUnit>
      <ColumnDataEntry>
        {dataEntryUsers.length ? (
          dataEntryUsers.map((user, index) => <UserInitialsLabel key={`user-initials-label-${index}`} user={user} />)
        ) : (
          <Subtext>no assignment</Subtext>
        )}
      </ColumnDataEntry>
      <ColumnClientsInvited>
        <BooleanLabel value={clientUserIds?.length || 0} />
      </ColumnClientsInvited>
      <ColumnProjectDuration>
        <NumericDate>
          {formatDateSimple(projectStartsAt)} - {formatDateSimple(projectFinishesAt)}
        </NumericDate>
      </ColumnProjectDuration>
      <ColumnCreated>
        <NumericDate>{formatDateSimple(createdAt)}</NumericDate>
      </ColumnCreated>
      <ColumnUpdated>
        <NumericDate>{formatDateSimple(updatedAt)}</NumericDate>
      </ColumnUpdated>
      <ColumnActions>
        <ActionsMenu items={actions} />
      </ColumnActions>
    </TableRowAlignTop>
  );
};

export interface ProjectsListItemProps {
  rowIndex: number;
  project: Project;
  onEdit: (projectId: ID) => void;
}
const ProjectGeneralInfoListItem: Component<ProjectsListItemProps> = ({ rowIndex, project, onEdit }) => (
  <ProjectGeneralInfoListItemUI
    {...useProjectGeneralInfoListItem(project, onEdit)}
    rowIndex={rowIndex + 1}
    project={project}
  />
);
export default ProjectGeneralInfoListItem;
