import styled from 'styled-components';

export const MyProfileInfo = styled.div`
  display: flex;
  width: 100%;
  gap: 14px;
`;

export const MyProfileItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 16px;
  font-weight: 500;

  span {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 19px;
    color: ${({ theme }) => theme.palette.subtext};
  }
`;
