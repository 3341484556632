import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'config/store';
import { FETCH_USERS_DELAY } from 'constants/network';
import { Maybe } from 'types-common';

import { PostInviteUserPayload, UserInvitationFormProps, UserInvitationFormUIProps } from '../UserInvitationForm';
import { inviteUser } from '../users-invite-reducer';
import { fetchUsers } from '../users-reducer';
import { useUserInvitationSnackbars } from './useUserInvitationSnackbars';

export const useUserInvitationForm = ({ onClose }: UserInvitationFormProps): UserInvitationFormUIProps => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<Maybe<PostInviteUserPayload>>();
  const dispatch = useDispatch();
  const { invitations, error: errorData, version } = useSelector((state: RootState) => state.usersInvite);
  const invite = invitations.find(({ email }) => email === formData?.email);

  useEffect(() => {
    if (invite?.id) {
      setTimeout(() => {
        dispatch(fetchUsers());
        setLoading?.(false);
        onClose?.();
      }, FETCH_USERS_DELAY);
    }
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorData) {
      setLoading?.(false);
    }
  }, [errorData]); // eslint-disable-line react-hooks/exhaustive-deps

  useUserInvitationSnackbars({ userEmail: formData?.email || '' });

  const onSubmit: UserInvitationFormUIProps['onSubmit'] = (formData): void => {
    setLoading(true);
    setFormData(formData);
    dispatch(inviteUser(formData));
  };

  return {
    loading,
    onClose,
    onSubmit,
  };
};
