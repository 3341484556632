import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import styled from 'styled-components';

export const Tabs = styled(MuiTabs)`
  && {
    .MuiTabs-indicator {
      height: 5px;
      border-radius: 5px 5px 0 0;
      background-color: ${({ theme }) => theme.palette.yellowMedium};
    }
  }
`;

export const Tab = styled(MuiTab)`
  && {
    min-width: auto;
    margin-left: 24px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    padding: 16px 0 17px;
    color: ${({ theme }) => theme.palette.subtext};

    &.Mui-selected {
      color: ${({ theme }) => theme.palette.primary};
    }
  }
`;
