import { Controller } from 'react-hook-form';

import { Component } from '../../types-common';
import { FormControlLabel } from './Checkbox.styled';
import { CheckboxInput, CheckboxInputProps } from './CheckboxInput';

export type CheckboxProps = CheckboxInputProps & {
  name: string;
  label?: string;
  control: any;
  rules?: {
    required?: any;
  };
  onChangeCallback?: (option: string | number | boolean) => void;
};
export const Checkbox: Component<CheckboxProps> = ({ name, control, label, rules, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          label={label}
          control={<CheckboxInput name={name} value={value} onChange={onChange} {...props} />}
        />
      )}
    />
  );
};
