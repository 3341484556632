import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'config/store';

import { Maybe } from '../../../../types-common';
import { Project } from '../../types';
import { ProjectManagementListProps } from '../ProjectManagement';
import { fetchProjects } from '../projects-reducer';

export const useProjectManagement = (): ProjectManagementListProps => {
  const dispatch = useDispatch();
  const [projectToEdit, setProjectToEdit] = useState<Maybe<Project>>();
  const { projects, loading, error: projectsError } = useSelector((state: RootState) => state.projects);
  const { projectServices, error: projectServicesError } = useSelector((state: RootState) => state.projectServices);
  const error = projectsError || projectServicesError;

  useEffect(() => {
    dispatch(fetchProjects());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // TODO: Add snackbars error handling
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEdit: ProjectManagementListProps['onEdit'] = projectId => {
    setProjectToEdit(projects.find(({ id }) => id === projectId));
  };

  const onEditClose: ProjectManagementListProps['onEditClose'] = () => {
    setProjectToEdit(null);
  };

  return {
    projects,
    projectServices,
    projectToEdit,
    loading,
    onEdit,
    onEditClose,
  };
};
