import { LogoWhiteSVG } from 'assets/svg';

import { Component } from 'types-common';

import { MyProfileButton } from '../MyProfileButton/MyProfileButton';
import { Navigation } from '../Navigation';
import { HeaderActions, HeaderCentered, HeaderRoot, Logo } from './Header.syled';

export const Header: Component = () => (
  <HeaderRoot>
    <HeaderCentered>
      <Logo>
        <LogoWhiteSVG />
      </Logo>
      <Navigation />
      <HeaderActions>
        <MyProfileButton />
      </HeaderActions>
    </HeaderCentered>
  </HeaderRoot>
);
