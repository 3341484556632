import { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import { RootState } from '../config/store';
import { AppRoutes } from '../constants/navigation';
import { useIsAuthenticated } from '../modules/identity-management/sign-in/hooks/useIsAuthenticated';
// import {User} from "../modules/identity-management/user-management/types";

const useSessionRedirect = (): void => {
  const navigate = useNavigate();
  // TODO: Use API to validate current session
  // const user = useSelector((state: RootState) => state.auth.user);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    // navigate(AppRoutes.USER_MANAGEMENT);
    if (isAuthenticated) {
      navigate(AppRoutes.USER_MANAGEMENT);
    } else {
      navigate(AppRoutes.SIGN_IN);
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useSessionRedirect;
