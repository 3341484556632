import MuiSelect from '@mui/material/Select';
import styled from 'styled-components';

import { palette } from '../../config/theme';

export const InputWrap = styled.div`
  margin-bottom: 18px;
`;

export const SelectStyled = styled(MuiSelect)`
  && {
    margin-top: 0;
    border-radius: 6px;
    color: ${({ theme }) => theme.palette.text};
    border: ${({ theme }) => `1.5px solid ${theme.palette.purplePale}`};
    width: 100%;

    &.valid {
      border-color: ${palette('greenMedium')};
    }
    &.invalid {
      border-color: ${palette('redMedium')};
      color: ${palette('redMedium')};
    }
    &.invalid .notranslate::after {
      color: ${palette('redMedium')};
    }

    .MuiInputBase-input.MuiSelect-select {
      font-size: 14px;
      line-height: 17px;
      padding: 8px 12px;
      min-height: 17px;
    }

    fieldset {
      border: 0;
    }
  }
`;
