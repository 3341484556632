import { IconCloseSVG, IconPlusSVG } from 'assets/svg';
import { useState } from 'react';

import { Button } from 'components/Button/Button';
import { Dialog, DialogIconClose, DialogTitle } from 'components/Dialog';
import { Component } from 'types-common';

import { UserInvitationStyled } from './styled/UserInvitation.styled';
import { UserInvitationForm } from './UserInvitationForm';

const UserInvitation: Component = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = (): void => {
    setOpenDialog(true);
  };
  const handleClose = (): void => {
    setOpenDialog(false);
  };

  return (
    <UserInvitationStyled>
      <Button icon={IconPlusSVG} onClick={handleOpen}>
        Invite New User
      </Button>
      {openDialog && (
        <Dialog open onClose={handleClose}>
          <DialogTitle>
            <div>Invite New User</div>
            <DialogIconClose role="button" onClick={handleClose}>
              <IconCloseSVG />
            </DialogIconClose>
          </DialogTitle>
          <UserInvitationForm onClose={handleClose} />
        </Dialog>
      )}
    </UserInvitationStyled>
  );
};

export default UserInvitation;
