import { Component } from 'types-common';

import { useDummySignIn } from './hooks/useDummySignIn';
import { DummySignInDialog } from './SignInForm';

export const SignInBackdoorPage: Component = () => {
  const props = useDummySignIn();

  return <DummySignInDialog {...props} />;
};
