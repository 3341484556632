import { useDrop } from 'react-dnd';

import { Component, DraggableObject } from '../../types-common';

export interface DroppableProps {
  id: string;
  type: DraggableObject;
  onDrop: (dragItemId: string, propItemId: string) => void;
}
export const Droppable: Component<DroppableProps> = ({ id, type, onDrop, children }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: type,
    // canDrop: () => game.canMoveKnight(x, y),
    drop: (dragItem: { id: string }) => {
      onDrop(dragItem.id, id);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      // canDrop: !!monitor.canDrop(),
    }),
  }));

  return (
    <div
      ref={drop}
      style={{
        width: '100%',
        backgroundColor: isOver ? '#d5d2fb' : '#fff',
      }}>
      {children}
    </div>
  );
};
