import { styled } from '@mui/material/styles';
import MUITooltip, { tooltipClasses, TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';

type TooltipProps = MuiTooltipProps & {
  background?: string;
};
export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip placement="top" {...props} arrow classes={{ popper: className }} />
))(({ background }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: background || '#1F273E',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 500,
    padding: 10,
    borderRadius: 6,
    backgroundColor: background || '#1F273E',
  },
}));
