import { useIsAuthenticated } from '@azure/msal-react';

/**
 * Hook to check if the user is authenticated globally.
 *
 * Globally means that the user has authenticated in IAM Provider.
 * Locally means that the user has an Backend API's access token in the local storage.
 *
 * To determine global authentication, we fully rely on MSAL's `useIsAuthenticated` hook.
 */
export const useIsGlobalAuthenticated = useIsAuthenticated;
