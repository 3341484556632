import { StrictEffect, takeEvery } from 'redux-saga/effects';

import { fetchDiseases } from '../modules/healthcare-category-list/diseases-reducer';
import { fetchDiseasesSaga, fetchTherapyAreasSaga } from '../modules/healthcare-category-list/sagas';
import { fetchTherapyAreas } from '../modules/healthcare-category-list/therapy-areas-reducer';
import { inviteVerify } from '../modules/identity-management/invite-verification/reducer';
import { inviteVerifySaga } from '../modules/identity-management/invite-verification/sagas';
import { fetchProfile } from '../modules/identity-management/my-profile/reducer';
import { fetchProfileSaga } from '../modules/identity-management/my-profile/sagas';
import { authenticateAADB2C, authenticateLocalDevelopment } from '../modules/identity-management/sign-in/reducer';
import { authAADB2CSaga, authLocalDevelopmentSaga } from '../modules/identity-management/sign-in/sagas';
import {
  fetchUsersSaga,
  findInviteByIdSaga,
  inviteUserSaga,
  resendInviteSaga,
} from '../modules/identity-management/user-management/sagas';
import {
  findInviteById,
  inviteUser,
  resendInvite,
} from '../modules/identity-management/user-management/users-invite-reducer';
import { fetchUsers } from '../modules/identity-management/user-management/users-reducer';
import {
  createNewsAlerts,
  fetchNewsAlerts,
  updateNewsAlerts,
  updateProjectNewsTrackerConfig,
} from '../modules/news-tracker/news-list/news-alerts-reducer';
import {
  createNewsAlertsSaga,
  fetchNewsAlertsSaga,
  updateNewsAlertsSaga,
  updateProjectNewsTrackerConfigSaga,
} from '../modules/news-tracker/news-list/sagas';
import {
  createDiseases,
  createProject,
  updateProject,
} from '../modules/project-management/project-edit-wizard/project-edit-wizard-reducer';
import {
  createDiseasesSaga,
  createProjectSaga,
  updateProjectSaga,
} from '../modules/project-management/project-edit-wizard/sagas';
import { fetchBusinessUnits } from '../modules/project-management/projects-list/business-units-reducer';
import { fetchProjectServices } from '../modules/project-management/projects-list/project-services-reducer';
import { fetchProjects } from '../modules/project-management/projects-list/projects-reducer';
import {
  fetchBusinessUnitsSaga,
  fetchProjectServicesSaga,
  fetchProjectsSaga,
} from '../modules/project-management/projects-list/sagas';

export function* rootSaga(): Generator<StrictEffect> {
  yield takeEvery(authenticateLocalDevelopment.type, authLocalDevelopmentSaga);
  yield takeEvery(authenticateAADB2C.type, authAADB2CSaga);
  yield takeEvery(fetchProfile.type, fetchProfileSaga);
  yield takeEvery(fetchUsers.type, fetchUsersSaga);
  yield takeEvery(inviteUser.type, inviteUserSaga);
  yield takeEvery(findInviteById.type, findInviteByIdSaga);
  yield takeEvery(inviteVerify.type, inviteVerifySaga);
  yield takeEvery(resendInvite.type, resendInviteSaga);
  yield takeEvery(fetchProjects.type, fetchProjectsSaga);
  yield takeEvery(createProject.type, createProjectSaga);
  yield takeEvery(updateProject.type, updateProjectSaga);
  yield takeEvery(fetchProjectServices.type, fetchProjectServicesSaga);
  yield takeEvery(fetchBusinessUnits.type, fetchBusinessUnitsSaga);
  yield takeEvery(fetchTherapyAreas.type, fetchTherapyAreasSaga);
  yield takeEvery(fetchDiseases.type, fetchDiseasesSaga);
  yield takeEvery(createDiseases.type, createDiseasesSaga);
  yield takeEvery(fetchNewsAlerts.type, fetchNewsAlertsSaga);
  yield takeEvery(updateProjectNewsTrackerConfig.type, updateProjectNewsTrackerConfigSaga);
  yield takeEvery(createNewsAlerts.type, createNewsAlertsSaga);
  yield takeEvery(updateNewsAlerts.type, updateNewsAlertsSaga);
}
