import { ID, UserRole } from 'types-common';

export enum UserType {
  Internal = 'Internal',
}

export enum UserStatus {
  Created = 'Created',
  EmailSent = 'EmailSent',
  EmailSendFailed = 'EmailSendFailed',
  Active = 'Active',
  Expired = 'Expired',
}

export type User = {
  id: ID;
  inviteId: ID;
  email: string;
  firstName: string;
  lastName: string;
  role: UserRole;
  status: UserStatus;
  createdAt: string;
  updatedAt: string;
  version: number;
};

export enum InviteStatus {
  Created = 'Created',
  EmailSent = 'EmailSent',
  EmailSendFailed = 'EmailSendFailed',
  Accepted = 'Accepted',
  Expired = 'Expired',
}

export interface Invite {
  id: ID;
  invitingUserId: ID;
  status: InviteStatus;
  role: UserRole;
  email: string;
  firstName: string;
  lastName: string;
}

export type Profile = {
  userId: ID;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
};
