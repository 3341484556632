import { IdToNameMap } from './modules/project-management/types';
import { NamedInstance } from './types-common';

export const mapIdToName = (instances: NamedInstance[]): IdToNameMap => {
  const map: IdToNameMap = {};

  instances.forEach(({ id, name }) => {
    map[id] = name;
  });

  return map;
};
