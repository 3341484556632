import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import { UseFormRegisterReturn } from 'react-hook-form';

import { IconCheckboxCheckedSVG, IconCheckboxUncheckedSVG } from '../../assets/svg';
import { Component } from '../../types-common';

export type CheckboxInputProps = MuiCheckboxProps & {
  defaultChecked: boolean;
  register?: UseFormRegisterReturn;
  icon?: JSX.Element;
  checkedIcon?: JSX.Element;
};
export const CheckboxInput: Component<CheckboxInputProps> = ({ register, ...props }) => {
  return (
    <MuiCheckbox
      checkedIcon={<IconCheckboxCheckedSVG />}
      icon={<IconCheckboxUncheckedSVG />}
      {...props}
      {...register}
    />
  );
};
