export enum AppRoutes {
  ROOT = '/',
  SIGN_IN = '/sign-in',
  SIGN_IN_BACKDOOR = '/sign-in-backdoor',
  MY_PROFILE = '/my-profile',
  INVITE_VERIFICATION = '/invite/verify',
  USER_MANAGEMENT = '/user-management',
  PROJECT_MANAGEMENT = '/project-management',
  PROJECT_PROFILE = '/project-management/:projectId',
}

export interface NavigationMenuItem {
  name: string;
  route: string;
}

export const navigationMenuList: NavigationMenuItem[] = [
  {
    name: 'User Management',
    route: AppRoutes.USER_MANAGEMENT,
  },
  {
    name: 'Project Management',
    route: AppRoutes.PROJECT_MANAGEMENT,
  },
];
