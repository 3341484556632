import { SearchInputProps } from 'components/SearchInput/SearchInput';
import { Component } from 'types-common';

import { useUserManagement } from './hooks/useUserManagement';
import UserInvitation from './UserInvitation';
import UsersList, { UsersListProps } from './UsersList';

export type UserManagementUIProps = UsersListProps & {
  onSearch: SearchInputProps['onChange'];
};

const UserManagementUI: Component<UserManagementUIProps> = ({ users, loading, error }) => {
  return (
    <>
      <UserInvitation />
      <UsersList users={users} loading={loading} error={error} />
    </>
  );
};

const UserManagement: Component = () => <UserManagementUI {...useUserManagement()} />;
export default UserManagement;
