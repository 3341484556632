import styled from 'styled-components';

import { TableColumn } from 'components/TableColumn';
import { palette } from 'config/theme';

import { Label } from '../../../../components/Label';

export const ColumnCount = styled(TableColumn)`
  min-width: 3%;
  color: ${palette('purplePale')};
  white-space: nowrap;
`;

export const ColumnClientName = styled(TableColumn)`
  min-width: 10%;
`;

export const ColumnProjectСode = styled(TableColumn)`
  min-width: 6%;
`;

export const ColumnProjectName = styled(TableColumn)`
  min-width: 14%;
`;

export const ColumnTherapyArea = styled(TableColumn)`
  min-width: 8%;
  display: flex;
  column-gap: 8px;
  row-gap: 10px;
  flex-wrap: wrap;
  flex-direction: column;

  ${Label} {
    margin: 0 auto 0 0;
  }
`;

export const ColumnDisease = styled(TableColumn)`
  min-width: 9%;
`;

export const ColumnBusinessUnit = styled(TableColumn)`
  min-width: 6%;
`;

export const ColumnDataEntry = styled(TableColumn)`
  min-width: 7%;
  display: flex;
  column-gap: 8px;
  row-gap: 10px;
  flex-wrap: wrap;
`;

export const ColumnClientsInvited = styled(TableColumn)`
  min-width: 7%;
`;

export const ColumnProjectDuration = styled(TableColumn)`
  min-width: 13%;
`;

export const ColumnCreated = styled(TableColumn)`
  min-width: 7%;
`;

export const ColumnUpdated = styled(TableColumn)`
  min-width: 7%;
`;

export const ColumnActions = styled(TableColumn)`
  min-width: 5%;
`;

export const ColumnServices = styled(TableColumn)`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  min-width: 68%;
  word-wrap: normal;
`;

export const ColumnService = styled(TableColumn)`
  min-width: 100%;
`;
