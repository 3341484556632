import styled from 'styled-components';

export const Centered = styled.div`
  width: 100%;
  height: calc(100% - 92px);
  max-width: 100%;
  padding: 0 50px 100px;
  margin: 0 auto;

  @media screen and (max-width: 980px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
