import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from 'components/Button/Button';
import { DialogActions } from 'components/Dialog';
import { SelectInput } from 'components/Select/SelectInput';
import { TextField } from 'components/TextField';
import { MATCH_ALPHABET_CHARACTERS, MAX_INPUT_LENGTH, NO_VALIDATION_MESSAGE, validation } from 'constants/form';
import { Component, UserRole } from 'types-common';

import { SelectOption } from '../../../components/Select/Select';
import { useUserInvitationForm } from './hooks/useUserInvitationForm';

const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Email must be valid: example@example.com')
    .required(NO_VALIDATION_MESSAGE)
    .max(MAX_INPUT_LENGTH, 'Must be exactly 5 digits'),
  firstName: yup
    .string()
    .trim()
    .required('First Name must be at least 1 character long')
    .max(MAX_INPUT_LENGTH, `First Name must be not longer than ${MAX_INPUT_LENGTH} characters`)
    .matches(MATCH_ALPHABET_CHARACTERS, 'First Name cannot contain  numbers or special characters'),
  lastName: yup
    .string()
    .trim()
    .required('Last Name must be at least 1 character long')
    .max(MAX_INPUT_LENGTH, `Last Name must be not longer than ${MAX_INPUT_LENGTH} characters`)
    .matches(MATCH_ALPHABET_CHARACTERS, 'Last Name cannot contain  numbers or special characters'),
  role: yup.string().required('Please make sure you selected user role'),
});
type FormData = yup.InferType<typeof schema>;
export type PostInviteUserPayload = FormData;

const defaultValues: FormData = {
  email: '',
  firstName: '',
  lastName: '',
  role: '',
};

const roleOptions: SelectOption[] = [
  {
    name: 'Admin',
    value: UserRole.Admin,
  },
  {
    name: 'Data Entry',
    value: UserRole.DataEntry,
  },
];

export interface UserInvitationFormUIProps {
  loading: boolean;
  onSubmit: (formData: PostInviteUserPayload) => void;
  onClose: () => void;
}
const UserInvitationFormUI: Component<UserInvitationFormUIProps> = ({ loading, onSubmit, onClose }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const disabled = loading || !isValid;

  return (
    <div className="form-content">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="email"
          type="email"
          label="Email"
          placeholder="Enter Email (e.g. name@example.com)"
          control={control}
          rules={validation.required}
          disabled={loading}
        />
        <TextField
          name="firstName"
          label="First Name"
          placeholder="Enter your First Name here"
          control={control}
          rules={validation.required}
          disabled={loading}
        />
        <TextField
          name="lastName"
          label="Last Name"
          placeholder="Enter your Last Name here"
          control={control}
          rules={validation.required}
          disabled={loading}
        />
        <SelectInput<UserRole>
          name="role"
          label="Role"
          placeholder="Select Role"
          options={roleOptions}
          control={control}
          rules={validation.required}
          disabled={loading}
        />
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={disabled} loading={loading}>
            Send Invitation
          </Button>
        </DialogActions>
      </form>
    </div>
  );
};

export interface UserInvitationFormProps {
  onClose: () => void;
}
export const UserInvitationForm: Component<UserInvitationFormProps> = props => (
  <UserInvitationFormUI {...useUserInvitationForm(props)} />
);
