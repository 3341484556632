import { ActionsMenu, ActionsMenuItem } from 'components/ActionsMenu';
import { Highlight } from 'components/Hightlight';
import { TableRowAlignTop } from 'components/TableRow';
import { Component, ID } from 'types-common';

import { IconCheckSVG, IconToggleDisabledSVG, IconToggleEnabledSVG } from '../../../assets/svg';
import { IdToBooleanMap, Project } from '../types';
import { useProjectServiceListItem } from './hooks/useProjectServicesListItem';
import {
  ColumnActions,
  ColumnClientName,
  ColumnCount,
  ColumnProjectName,
  ColumnService,
  ColumnServices,
} from './styled/Columns.styled';

export type ProjectsServicesListItemUIProps = {
  servicesBooleanMap: IdToBooleanMap;
  actions: ActionsMenuItem[];
};

const ProjectServicesListItemUI: Component<Omit<ProjectsListItemProps, 'onEdit'> & ProjectsServicesListItemUIProps> = ({
  rowIndex,
  project,
  servicesBooleanMap,
  actions,
}) => {
  const { projectName, projectCode, clientName, isRepositoryOnly } = project;

  return (
    <TableRowAlignTop>
      <ColumnCount>{rowIndex}</ColumnCount>
      <ColumnClientName>{clientName}</ColumnClientName>
      <ColumnProjectName>
        <Highlight>{projectName}</Highlight>
      </ColumnProjectName>
      <ColumnServices>
        <ColumnService>{isRepositoryOnly ? <IconToggleEnabledSVG /> : <IconToggleDisabledSVG />}</ColumnService>
        {Object.keys(servicesBooleanMap).map((serviceId, index) => (
          <ColumnService key={`service-${projectCode}-${index}`}>
            {servicesBooleanMap[serviceId] ? <IconCheckSVG /> : null}
          </ColumnService>
        ))}
      </ColumnServices>
      <ColumnActions>
        <ActionsMenu items={actions} />
      </ColumnActions>
    </TableRowAlignTop>
  );
};

export interface ProjectsListItemProps {
  rowIndex: number;
  project: Project;
  onEdit: (projectId: ID) => void;
}
const ProjectServicesListItem: Component<ProjectsListItemProps> = ({ rowIndex, project, onEdit }) => (
  <ProjectServicesListItemUI
    {...useProjectServiceListItem(project, onEdit)}
    rowIndex={rowIndex + 1}
    project={project}
  />
);
export default ProjectServicesListItem;
