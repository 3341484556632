import withRolePermission from 'access-control/withRolePermission';

import { Layout } from 'components/Layout';
import { Component, UserRole } from 'types-common';

import ProjectManagement from './ProjectManagement';

const ProjectManagementPage: Component = () => (
  <Layout>
    <ProjectManagement />
  </Layout>
);

export default withRolePermission([UserRole.Admin])(ProjectManagementPage);
