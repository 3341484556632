import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponseData } from 'error-handling';

import { ErrorMessage, Maybe } from 'types-common';

import { TherapyArea } from './types';

export interface TherapyAreasState {
  therapyAreas: TherapyArea[];
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: TherapyAreasState = {
  therapyAreas: [],
  loading: true,
  error: null,
};

const therapyAreasReducer = createSlice({
  name: 'therapyAreas',
  initialState,
  reducers: {
    fetchTherapyAreas(state) {
      state.loading = true;
      state.error = null;
    },
    fetchTherapyAreasSuccess(state, action: PayloadAction<TherapyArea[]>) {
      state.loading = false;
      state.therapyAreas = action.payload;
    },
    fetchTherapyAreasFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchTherapyAreas, fetchTherapyAreasSuccess, fetchTherapyAreasFailure } = therapyAreasReducer.actions;
export default therapyAreasReducer.reducer;
