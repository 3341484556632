import { User } from '../modules/identity-management/types';
import { Component, UserRole } from '../types-common';

const withRolePermission =
  <T extends object>(allowedRoles: UserRole[]) =>
  (WrappedComponent: Component<T>): Component<T> =>
  props => {
    // TODO: Use profile Role from API
    // const profile = useSelector((state: RootState) => state.auth.profile);
    const profile = { role: UserRole.Admin } as User;
    const hasPermission = profile && allowedRoles.includes(profile.role);

    if (!hasPermission) {
      return (
        <>
          You are not authorized to access this feature.
          <br />
          <br />
        </>
      );
    }

    return <WrappedComponent {...props} />;
  };

export default withRolePermission;
