import styled from 'styled-components';

import { TableRow } from '../TableRow';

export const TableHeadRow = styled(TableRow)`
  color: ${({ theme }) => theme.palette.subtext};
  font-size: 12px;
`;

export const TableHeadRowAlignTop = styled(TableHeadRow)`
  align-items: flex-start;
`;
