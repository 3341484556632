import styled from 'styled-components';

import { Component } from '../../types-common';

const Navigation: Component = props => <div {...props} data-cy-element="navigation" />;

export default styled(Navigation)`
  display: flex;
  height: 100%;
  gap: 24px;
  position: relative;

  a {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;

    span {
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.palette.purplePale};
    }

    &:hover {
      span {
        text-decoration: none;
        color: ${({ theme }) => theme.palette.white};
      }
    }

    &.active {
      span {
        color: ${({ theme }) => theme.palette.white};
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 100%;
        background-color: ${({ theme }) => theme.palette.yellowMedium};
        border-radius: 5px 5px 0 0;
      }
    }
  }
`;
