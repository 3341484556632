import useSessionRedirect from 'access-control/useSessionRedirect';

import { Centered } from 'components/Centered';
import { Component } from 'types-common';

import { Progress } from '../Progress';

const RootPage: Component = () => {
  useSessionRedirect();

  return (
    <Centered>
      <Progress global />
    </Centered>
  );
};

export default RootPage;
