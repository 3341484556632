import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from 'error-handling';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import api from 'config/api';

import apiUrl from '../../../constants/network';
import { AADB2CLoginDTO, authFailure, authSuccess } from './reducer';
import { PostAuthDummyLoginPayload } from './SignInForm';

export interface PostAuthDummyLoginData {
  accessToken: string;
}

export interface PostAuthAADB2CLoginData {
  accessToken: string;
}

export function* authLocalDevelopmentSaga(
  action: PayloadAction<PostAuthDummyLoginPayload>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<PostAuthDummyLoginData>> {
  try {
    const { status, data }: AxiosResponse<PostAuthDummyLoginData> = yield call(
      api.post,
      apiUrl.postAuthDummyLogin(),
      action.payload,
    );

    switch (status) {
      case 201:
        yield put(authSuccess(data));
        break;
      default:
        yield put(authFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(authFailure(getErrorMessage(error)));
  }
}

export function* authAADB2CSaga(
  action: PayloadAction<AADB2CLoginDTO>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<PostAuthAADB2CLoginData>> {
  try {
    const { status, data }: AxiosResponse<PostAuthDummyLoginData> = yield call(
      api.post,
      apiUrl.postAuthAADB2CLogin(),
      action.payload,
    );

    switch (status) {
      case 201:
        yield put(authSuccess(data));
        break;
      default:
        yield put(authFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(authFailure(getErrorMessage(error)));
  }
}
