import axios from 'axios';

import { ACCESS_TOKEN_STORAGE_KEY } from '../constants/auth';
import { AppRoutes } from '../constants/navigation';

// @ts-ignore
export const API_URL = window._env_.API_URL;

if (!API_URL) {
  console.error('ERROR: You must setup API_URL env variable');
}

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(function (config) {
  const jwtToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

  if (jwtToken) {
    config.headers.Authorization = `Bearer ${jwtToken}`;
  }

  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, '');
      // TODO: add AAD B2C logout here
      document.location.href = AppRoutes.SIGN_IN;
    }

    throw error;
  },
);

export default api;
