import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useSnackbar } from 'components/SnackbarProvider/useSnackbar';
import { RootState } from 'config/store';
import { AppRoutes } from 'constants/navigation';

import { inviteVerify } from '../reducer';

// TODO: Avoid this workaround. For some reason React makes two re-renders for page load and thus two requests are sent.
// First request is successful, invite accepter, but the second is sent leading to 422 (already accepted) error.
// `state.inviteVerification.loading` doesn't represent the actual state, so we cannot rely on it to avoid double request.
let inviteVerifyRequestSent = false;

interface UseInviteVerificationResult {
  loading: boolean;
}
export const useInviteVerification = (): UseInviteVerificationResult => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const { status, expired, loading } = useSelector((state: RootState) => state.inviteVerification);
  const code = searchParams.get('code');

  useEffect(() => {
    if (code && !inviteVerifyRequestSent) {
      inviteVerifyRequestSent = true;

      dispatch(inviteVerify({ code }));
    }
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (status) {
      snackbar({
        variant: 'success',
        headline: 'Invitation Verified',
        message: 'You can now proceed to log in and access the platform',
        onClose: () => navigate(AppRoutes.ROOT),
      });

      navigate(AppRoutes.ROOT);
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (expired) {
      snackbar({
        variant: 'error',
        headline: 'Invitation Expired',
        message:
          'The invitation link has expired. If you still need access to the platform, please contact support@intellitraq.com to request a new invitation from the administrator',
        onClose: () => navigate(AppRoutes.ROOT),
      });
    }
  }, [expired]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
  };
};
