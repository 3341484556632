import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../../../config/store';
import { mapIdToName } from '../../../../data-processing';
import { ID } from '../../../../types-common';
import { Project } from '../../types';
import { ProjectsGeneralInfoListItemUIProps } from '../ProjectGeneralInfoListItem';

export const useProjectGeneralInfoListItem = (
  project: Project,
  onEdit: (projectId: ID) => void,
): ProjectsGeneralInfoListItemUIProps => {
  const navigate = useNavigate();
  const { businessUnits } = useSelector((state: RootState) => state.businessUnits);
  const { therapyAreas } = useSelector((state: RootState) => state.therapyAreas);
  const { diseases } = useSelector((state: RootState) => state.diseases);
  const { users } = useSelector((state: RootState) => state.users);
  const dataEntryUsers = project.dataEntryUserIds
    ? users.filter(({ id }) => project.dataEntryUserIds.includes(id))
    : [];

  const onEditInfo = (): void => {
    onEdit(project.id);
  };

  const onViewProject = (): void => {
    navigate(`/project-management/${project.id}`);
  };

  return {
    therapyAreaNamesMap: mapIdToName(therapyAreas),
    diseaseNamesMap: mapIdToName(diseases),
    businessUnitNamesMap: mapIdToName(businessUnits),
    dataEntryUsers,
    actions: [
      { name: 'Edit Project', actionHandler: onEditInfo },
      { name: 'View Project Profile', actionHandler: onViewProject },
    ],
  };
};
