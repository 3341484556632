import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';

export const Menu = styled(MuiMenu)`
  && {
    .MuiPaper-root {
      box-shadow: 0 2px 9px 6px rgba(31, 39, 62, 0.08);
      min-width: 184px;
    }
    .MuiMenu-list {
      padding: 12px 0;
      border-radius: 6px;
    }
    .Mui-disabled {
      color: ${({ theme }) => theme.palette.purplePale};
      opacity: 1;
    }
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  && {
    padding: 12px 24px;
    font-size: 12px;
    font-weight: 500;

    &:hover {
      color: ${({ theme }) => theme.palette.primary};
      background-color: ${({ theme }) => theme.palette.purpleBright};
    }
  }
`;
