import { IconPlusSVG } from 'assets/svg';
import { useState } from 'react';

import { Button } from 'components/Button/Button';
import { Component } from 'types-common';

import { ProjectEditWizard } from '../project-edit-wizard/ProjectEditWizard';
import { CreateNewProjectStyled } from './styled/CreateNewProject.styled';

const CreateNewProjectButton: Component = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <CreateNewProjectStyled>
      <Button icon={IconPlusSVG} onClick={handleOpen}>
        Create New Project
      </Button>
      {open && <ProjectEditWizard key={'project-edit-wizard-create'} onClose={handleClose} />}
    </CreateNewProjectStyled>
  );
};

export default CreateNewProjectButton;
