import MuiMenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';

export const NewsColumnsVisibilityMenuItem = styled(MuiMenuItem)`
  && {
    padding: 0;

    label {
      width: 100%;
      padding: 2px 24px;
      margin: 0 0 0 -6px;
    }
  }
`;

export const MenuTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 14px 24px;
  width: 209px;
`;
