import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button } from 'components/Button/Button';
import { DialogActions, DialogDescription, DialogIconClose, DialogTitle } from 'components/Dialog';
import { SelectInput, SelectOption } from 'components/Select';
import { TextField } from 'components/TextField';
import {
  FORM_DIALOG_ASK_BEFORE_CLOSE,
  FORM_DIALOG_CLOSE,
  MAX_INPUT_LENGTH,
  MAX_TEXTAREA_LENGTH_SHORT,
  MIN_PROJECT_CODE_LENGTH,
  NO_VALIDATION_MESSAGE,
} from 'constants/form';
import { Component } from 'types-common';

import { IconCloseSVG } from '../../../assets/svg';
import { DatePickerInput } from '../../../components/DatePickerInput';
import { Subtext } from '../../../components/Subtext/Subtext.styled';
import { useProjectGeneralInfoForm } from './hooks/useProjectGeneralInfoForm';
import { DatepickersGroup } from './styled/ProjectGeneralInfoForm.styled';

type FormData = {
  projectName: string;
  projectCode: string;
  businessUnitId: string;
  clientName: string;
  projectStartsAt: string;
  projectFinishesAt: string;
  description: string;
};

// TODO: Update Schema type
const schema = (usedProjectCodes: string[]): any =>
  yup.object().shape(
    {
      projectName: yup
        .string()
        .trim()
        .required(NO_VALIDATION_MESSAGE)
        .max(MAX_INPUT_LENGTH, `Please ensure Name is under ${MAX_INPUT_LENGTH} characters`),
      projectCode: yup
        .string()
        .trim()
        .required(NO_VALIDATION_MESSAGE)
        .min(MIN_PROJECT_CODE_LENGTH, `Project code must contain at least ${MIN_PROJECT_CODE_LENGTH} characters`)
        .max(MAX_INPUT_LENGTH, `Project Code must be not longer than ${MAX_INPUT_LENGTH} characters`)
        .test(
          'codeAlreadyExists',
          'A project with such project code already exists. Please create a new one',
          function () {
            return !usedProjectCodes.includes(this.parent.projectCode);
          },
        ),
      businessUnitId: yup.string().trim().required(NO_VALIDATION_MESSAGE),
      clientName: yup
        .string()
        .trim()
        .required(NO_VALIDATION_MESSAGE)
        .max(MAX_INPUT_LENGTH, `Client Name must be not longer than ${MAX_INPUT_LENGTH} characters`),
      projectStartsAt: yup
        .string()
        .nullable()
        .default('')
        .required(NO_VALIDATION_MESSAGE)
        .test('dateRange', 'Please select a valid date range', function () {
          if (this.parent.projectStartsAt && this.parent.projectFinishesAt) {
            return new Date(this.parent.projectStartsAt) <= new Date(this.parent.projectFinishesAt);
          }
          return true;
        }),
      projectFinishesAt: yup
        .string()
        .nullable()
        .default('')
        .required(NO_VALIDATION_MESSAGE)
        .test('dateRange', 'Please select a valid date range', function () {
          if (this.parent.projectStartsAt && this.parent.projectFinishesAt) {
            return new Date(this.parent.projectStartsAt) <= new Date(this.parent.projectFinishesAt);
          }
          return true;
        }),
      description: yup
        .string()
        .max(MAX_TEXTAREA_LENGTH_SHORT, `Please ensure Description is under ${MAX_TEXTAREA_LENGTH_SHORT} characters`)
        .notRequired()
        .when('description', {
          is: (value: string) => value?.length,
          then: rule => rule.min(3),
        }),
    },
    [['description', 'description']],
  );

export interface ProjectGeneralInfoFormUIProps {
  isEditMode: boolean;
  defaultValues: FormData;
  businessUnitOptions: SelectOption[];
  usedProjectCodes: string[];
  onSubmit: (formData: FormData) => void;
}
const ProjectGeneralInfoFormUI: Component<
  ProjectGeneralInfoFormUIProps & {
    onClose: (reason: string) => void;
  }
> = ({ isEditMode, defaultValues, businessUnitOptions, usedProjectCodes, onSubmit, onClose }) => {
  const [durationStartDate, setDurationStartDate] = useState<string>(defaultValues.projectStartsAt);
  const [durationFinishDate, setDurationFinishDate] = useState<string>(defaultValues.projectFinishesAt);
  const {
    control,
    handleSubmit,
    trigger,
    getValues,
    formState: { isSubmitted },
  } = useForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver<FormData>(schema(usedProjectCodes)),
  });

  const handleDurationStartDateChange = (startDate: string): void => {
    setDurationStartDate(startDate);

    trigger(['projectStartsAt']);

    if (isSubmitted) {
      trigger(['projectFinishesAt']);
    }
  };
  const handleDurationFinishDateChange = (finishDate: string): void => {
    setDurationFinishDate(finishDate);

    trigger(['projectFinishesAt']);

    if (isSubmitted) {
      trigger(['projectStartsAt']);
    }
  };

  const handleClose = (): void => {
    const formValues = getValues();
    const isEmpty = Object.values(formValues).every(value => !value);

    onClose(isEmpty ? FORM_DIALOG_CLOSE : FORM_DIALOG_ASK_BEFORE_CLOSE);
  };

  return (
    <div>
      <DialogTitle>
        <div>{isEditMode ? 'Edit' : 'Name'} your Project</div>
        <DialogIconClose role="button" onClick={handleClose}>
          <IconCloseSVG />
        </DialogIconClose>
      </DialogTitle>
      <DialogDescription>
        {isEditMode ? 'Update General Information' : 'Give your project a name that reflects its purpose and scope'}
      </DialogDescription>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="projectName"
          type="input"
          label="Project Name"
          placeholder="Enter Project Name here"
          control={control}
        />
        <TextField
          name="projectCode"
          type="input"
          label="Project Code"
          placeholder="Enter Project Code here"
          control={control}
        />

        <SelectInput<string>
          name="businessUnitId"
          label="Business Unit"
          placeholder="Select Business Unit here"
          options={businessUnitOptions}
          control={control}
        />

        <TextField
          name="clientName"
          type="input"
          label="Client Name"
          placeholder="Enter Client Name here"
          control={control}
        />

        <Subtext>Project Duration</Subtext>
        <DatepickersGroup>
          <DatePickerInput
            name="projectStartsAt"
            label="from"
            control={control}
            maxDate={durationFinishDate}
            onChange={handleDurationStartDateChange}
          />
          <DatePickerInput
            name="projectFinishesAt"
            label="to"
            control={control}
            minDate={durationStartDate}
            onChange={handleDurationFinishDateChange}
          />
        </DatepickersGroup>

        <TextField
          name="description"
          type="textarea"
          label="Description (optional)"
          placeholder="Enter Description here"
          control={control}
        />
        <DialogActions>
          <Button type="submit">Next</Button>
        </DialogActions>
      </form>
    </div>
  );
};

export type ProjectGeneralInfoFormProps = {
  onNext: () => void;
  onClose: (reason: string) => void;
};
export const ProjectGeneralInfoForm: Component<ProjectGeneralInfoFormProps> = ({ onNext, onClose }) => (
  <ProjectGeneralInfoFormUI onClose={onClose} {...useProjectGeneralInfoForm(onNext)} />
);
