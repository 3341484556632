import { AnyAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from 'error-handling';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import api from 'config/api';
import apiUrl from 'constants/network';

import { Profile } from '../types';
import { fetchProfileFailure, fetchProfileSuccess } from './reducer';

export function* fetchProfileSaga(): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<Profile>> {
  try {
    const { status, data }: AxiosResponse<Profile> = yield call(api.get, apiUrl.getAuthProfile());
    switch (status) {
      case 200:
        yield put(fetchProfileSuccess(data));
        break;
      default:
        yield put(fetchProfileFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(fetchProfileFailure(getErrorMessage(error)));
  }
}
