import MuiAutocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components';

export const AutocompleteStyled = styled(MuiAutocomplete)`
  && {
    .MuiOutlinedInput-root {
      padding: 0 0 0 5px;
    }

    &.MuiAutocomplete-hasClearIcon label {
      display: none;
    }

    label {
      transform: translate(12px, 8px) scale(1);
      font-size: 14px;
    }
    fieldset {
      border-radius: 6px;
    }
    input.MuiAutocomplete-input {
      padding: 9px 12px;
      height: 17px;
      font-size: 14px;
    }
    &.invalid input::placeholder {
      color: ${({ theme }) => theme.palette.redMedium};
      opacity: 1;
    }

    .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: ${({ theme }) => `1.5px solid ${theme.palette.purplePale}`};
    }
    .Mui-disabled .MuiOutlinedInput-notchedOutline,
    &.invalid .Mui-disabled .MuiOutlinedInput-notchedOutline {
      border: ${({ theme }) => `1.5px solid ${theme.palette.grayMedium}`};
    }
    &.valid .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.greenMedium};
    }
    &.invalid .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.redMedium};
    }

    .MuiChip-root {
      padding: 3px 9px;
      border-radius: 15px;
      line-height: 15px;
      font-size: 12px;
      height: auto;
    }
    .MuiChip-label {
      padding: 0 3px 0 0;
    }
    .MuiChip-deleteIcon {
      margin: 0 -3px 0 0;
    }
  }
`;
