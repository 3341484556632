import { Fragment } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Component } from 'types-common';

import { NewsTrackerColumnName, NewsTrackerColumnsVisibility } from '../types';
import { NewsListHeadCell } from './NewsListHeadCell';
import {
  ColumnAgeGroup,
  ColumnAsset,
  ColumnBusinessStream,
  ColumnCategory,
  ColumnCompany,
  ColumnDate,
  ColumnDisease,
  ColumnGeography,
  ColumnImplications,
  ColumnIndications,
  ColumnLink,
  ColumnModality,
  ColumnOther,
  ColumnPriorityLevel,
  ColumnSummary,
  ColumnTherapyArea,
  ColumnTitle,
} from './styled/Columns.styled';
import { NewsListTableHead } from './styled/NewsList.styled';

export interface NewsListHeadProps {
  columnsOrder: NewsTrackerColumnName[];
  columnsVisibility: NewsTrackerColumnsVisibility;
  onOrderChange: (fromColumnName: NewsTrackerColumnName, toColumnName: NewsTrackerColumnName) => void;
}
const NewsListHead: Component<NewsListHeadProps> = ({ columnsOrder, columnsVisibility, onOrderChange }) => {
  const columns: Partial<Record<NewsTrackerColumnName, JSX.Element>> = {
    title: (
      <ColumnTitle>
        <NewsListHeadCell id="title" name="Title" onDrop={onOrderChange} />
      </ColumnTitle>
    ),
    therapyAreaIds: (
      <ColumnTherapyArea>
        <NewsListHeadCell id="therapyAreaIds" name="TA" onDrop={onOrderChange} />
      </ColumnTherapyArea>
    ),
    date: (
      <ColumnDate>
        <NewsListHeadCell id="date" name="Date" onDrop={onOrderChange} />
      </ColumnDate>
    ),
    company: (
      <ColumnCompany>
        <NewsListHeadCell id="company" name="Company" onDrop={onOrderChange} />
      </ColumnCompany>
    ),
    category: (
      <ColumnCategory>
        <NewsListHeadCell id="category" name="Category" onDrop={onOrderChange} />
      </ColumnCategory>
    ),
    diseaseIds: (
      <ColumnDisease>
        <NewsListHeadCell id="diseaseIds" name="Disease" onDrop={onOrderChange} />
      </ColumnDisease>
    ),
    summary: (
      <ColumnSummary>
        <NewsListHeadCell id="summary" name="Summary" onDrop={onOrderChange} />
      </ColumnSummary>
    ),
    asset: (
      <ColumnAsset>
        <NewsListHeadCell id="asset" name="Asset" onDrop={onOrderChange} />
      </ColumnAsset>
    ),
    priorityLevel: (
      <ColumnPriorityLevel>
        <NewsListHeadCell id="priorityLevel" name="Priority Level" onDrop={onOrderChange} />
      </ColumnPriorityLevel>
    ),
    link: (
      <ColumnLink>
        <NewsListHeadCell id="link" name="Source" onDrop={onOrderChange} />
      </ColumnLink>
    ),
    businessStream: (
      <ColumnBusinessStream>
        <NewsListHeadCell id="businessStream" name="Business Stream" onDrop={onOrderChange} />
      </ColumnBusinessStream>
    ),
    geography: (
      <ColumnGeography>
        <NewsListHeadCell id="geography" name="Geography" onDrop={onOrderChange} />
      </ColumnGeography>
    ),
    modality: (
      <ColumnModality>
        <NewsListHeadCell id="modality" name="Modality" onDrop={onOrderChange} />
      </ColumnModality>
    ),
    ageGroup: (
      <ColumnAgeGroup>
        <NewsListHeadCell id="ageGroup" name="Age Group" onDrop={onOrderChange} />
      </ColumnAgeGroup>
    ),
    implications: (
      <ColumnImplications>
        <NewsListHeadCell id="implications" name="Implications" onDrop={onOrderChange} />
      </ColumnImplications>
    ),
    indication: (
      <ColumnIndications>
        <NewsListHeadCell id="indication" name="Indication" onDrop={onOrderChange} />
      </ColumnIndications>
    ),
    other: (
      <ColumnOther>
        <NewsListHeadCell id="other" name="Other" onDrop={onOrderChange} />
      </ColumnOther>
    ),
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <NewsListTableHead>
        {columnsOrder?.map(key => (columnsVisibility[key] ? <Fragment key={key}>{columns[key]}</Fragment> : null))}
      </NewsListTableHead>
    </DndProvider>
  );
};

export default NewsListHead;
