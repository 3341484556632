import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSnackbar } from 'components/SnackbarProvider/useSnackbar';
import { RootState } from 'config/store';
import { ACCESS_TOKEN_STORAGE_KEY } from 'constants/auth';

import { authenticateLocalDevelopment } from '../reducer';
import { DummySignInDialogProps } from '../SignInForm';

const TEMP_PASSWORD = 'dysAV96KzyaNjpo';

export const useDummySignIn = (): DummySignInDialogProps => {
  const [isSessionValid, setIsSessionValid] = useState(Boolean(localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)));
  const dispatch = useDispatch();
  const { snackbar } = useSnackbar();
  const { accessToken, loading } = useSelector((state: RootState) => state.auth);

  const onSubmit: DummySignInDialogProps['onSubmit'] = (formData): void => {
    // TODO: Remove temp password
    if (formData.password === TEMP_PASSWORD) {
      dispatch(authenticateLocalDevelopment(formData));
    } else {
      snackbar({
        variant: 'error',
        headline: 'Invalid Credentials',
        message: 'Please ensure you have entered the correct username/email and password.',
      });
    }
  };

  useEffect(() => {
    if (accessToken) {
      setIsSessionValid(true);
      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
      window.location.pathname = '/user-management';
    }
  }, [accessToken]);

  return {
    loading: Boolean(loading),
    success: Boolean(isSessionValid),
    onSubmit,
  };
};
