import { Table } from '../../../components/Table';
import { TableHeadRowAlignTop } from '../../../components/TableHeadRow';
import { TableLoading } from '../../../components/TableLoading/TableLoading';
import { Component } from '../../../types-common';
import { ProjectService } from '../types';
import { EmptyProjectsList } from './EmptyProjectsList';
import { ProjectManagementListProps } from './ProjectManagement';
import ProjectServicesListItem from './ProjectServicesListItem';
import {
  ColumnActions,
  ColumnClientName,
  ColumnCount,
  ColumnProjectName,
  ColumnService,
  ColumnServices,
} from './styled/Columns.styled';

type ProjectServicesListProps = Omit<ProjectManagementListProps, 'projectToEdit' | 'onEditClose'> & {
  projectServices: ProjectService[];
};
export const ProjectServicesList: Component<ProjectServicesListProps> = ({
  projects,
  projectServices,
  loading,
  onEdit,
}) => {
  return (
    <Table>
      <TableHeadRowAlignTop>
        <ColumnCount> </ColumnCount>
        <ColumnClientName>Client Name</ColumnClientName>
        <ColumnProjectName>Project Name</ColumnProjectName>
        <ColumnServices>
          <ColumnService>Repository Only</ColumnService>
          {projectServices.map((service, index) => (
            <ColumnService key={`services-head-${index}`}>{service.name}</ColumnService>
          ))}
        </ColumnServices>
        <ColumnActions> </ColumnActions>
      </TableHeadRowAlignTop>
      {loading ? (
        <TableLoading />
      ) : projects.length ? (
        <>
          {projects.map((project, index) => (
            <ProjectServicesListItem
              key={`project-services-list-item-${index}`}
              onEdit={onEdit}
              rowIndex={index}
              project={project}
            />
          ))}
        </>
      ) : (
        <EmptyProjectsList />
      )}
    </Table>
  );
};
