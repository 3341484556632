import { SelectInputProps } from '@mui/material/Select/SelectInput';
import React from 'react';

import { MenuItem } from 'components/Menu/Menu.styled';

import { theme } from '../../config/theme';
import { Component, NamedInstance } from '../../types-common';
import { TextFieldLabel } from '../TextField/TextField.styled';
import { SelectStyled } from './Select.styled';

export interface SelectOption<T = string> {
  name: string;
  value: T;
}

export const mapNamedInstanceToSelectOption = ({ id, name }: NamedInstance): SelectOption => ({ name, value: id });

export type SelectProps = {
  value: string;
  label?: string;
  placeholder?: string;
  options: SelectOption[];
  onChange?: SelectInputProps['onChange'];
  disabled?: boolean;
  disabledOptionValues?: string[];
  isValid?: boolean;
  isInvalid?: boolean;
  error?: boolean;
};

export const Select: Component<SelectProps> = ({
  value,
  label,
  placeholder,
  options,
  onChange,
  disabled = false,
  disabledOptionValues = [],
  isValid,
  isInvalid,
}) => (
  <>
    {label ? <TextFieldLabel>{label}</TextFieldLabel> : null}
    <SelectStyled
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={isValid ? 'valid' : isInvalid ? 'invalid' : ''}
      sx={{
        '& .MuiSelect-select .notranslate::after': placeholder
          ? {
              content: `"${placeholder}"`,
              color: theme.palette.subtext,
            }
          : {},
      }}>
      {options.map(({ name, value: optionValue }) => {
        const isOptionDisabled = disabledOptionValues.includes(optionValue);

        return (
          <MenuItem key={`select-${value}-option-${optionValue}`} value={optionValue} disabled={isOptionDisabled}>
            {name}
          </MenuItem>
        );
      })}
    </SelectStyled>
  </>
);
