import { LeanLayout } from 'components/Layout';
import { Progress } from 'components/Progress';
import { Component } from 'types-common';

import { useInviteVerification } from './hooks/useInviteVerification';

const InviteVerificationPage: Component = () => {
  const { loading } = useInviteVerification();

  return <LeanLayout>{loading ? <Progress /> : <></>}</LeanLayout>;
};

export default InviteVerificationPage;
