import { Content } from 'components/Content';
import { Table } from 'components/Table';
import { TableHeadRow } from 'components/TableHeadRow';
import { Title } from 'components/Title';
import { User } from 'modules/identity-management/types';
import { Component, ErrorMessage, Maybe } from 'types-common';

import { TableLoading } from '../../../components/TableLoading/TableLoading';
import { FailureResponseData } from '../../../error-handling';
import {
  ColumnActions,
  ColumnCount,
  ColumnEmail,
  ColumnName,
  ColumnProjects,
  ColumnRole,
  ColumnStatus,
} from './styled/Columns.styled';
import UsersListItem from './UsersListItem';

export interface UsersListProps {
  users: User[];
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const UsersList: Component<UsersListProps> = ({ users, loading }) => {
  return (
    <Content>
      <Title>User Management</Title>
      <Table>
        <TableHeadRow>
          <ColumnCount> </ColumnCount>
          <ColumnName>Name</ColumnName>
          <ColumnEmail>Email</ColumnEmail>
          <ColumnRole>Role</ColumnRole>
          <ColumnStatus>Status</ColumnStatus>
          <ColumnProjects>Projects Assigned</ColumnProjects>
          <ColumnActions> </ColumnActions>
        </TableHeadRow>
        {loading ? (
          <TableLoading />
        ) : users.length ? (
          <>
            {users.map((user, index) => (
              <UsersListItem key={`user-list-item-${index}`} rowIndex={index} user={user} />
            ))}
          </>
        ) : (
          <>Sorry, no users found</>
        )}
      </Table>
    </Content>
  );
};

export default UsersList;
