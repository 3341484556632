import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from 'error-handling';
import { all, call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import api from 'config/api';
import apiUrl from 'constants/network';

import { ID } from '../../../types-common';
import { createDiseaseFailure } from '../../project-management/project-edit-wizard/project-edit-wizard-reducer';
import { fetchProjectsSaga } from '../../project-management/projects-list/sagas';
import { Project } from '../../project-management/types';
import {
  CreateNewsAlertPayload,
  NewsAlert,
  UpdateNewsAlertMetaPayload,
  UpdateProjectNewsTrackerConfigPayload,
} from '../types';
import {
  createNewsAlertsFailure,
  createNewsAlertsSuccess,
  fetchNewsAlertsFailure,
  fetchNewsAlertsSuccess,
  updateNewsAlertsFailure,
  updateNewsAlertsSuccess,
  updateProjectNewsTrackerConfigFailure,
  updateProjectNewsTrackerConfigSuccess,
} from './news-alerts-reducer';

type ProjectID = ID;
export function* fetchNewsAlertsSaga(
  action: PayloadAction<ProjectID>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<NewsAlert[]>> {
  try {
    const { status, data }: AxiosResponse<NewsAlert[]> = yield call(api.get, apiUrl.getNewsAlerts(action.payload));
    switch (status) {
      case 200:
        yield put(fetchNewsAlertsSuccess(data));
        break;
      default:
        yield put(fetchNewsAlertsFailure(`Oops, something went wrong (status code ${status})`));
    }
  } catch (error) {
    yield put(fetchNewsAlertsFailure(getErrorMessage(error)));
  }
}

export function* updateProjectNewsTrackerConfigSaga(
  action: PayloadAction<UpdateProjectNewsTrackerConfigPayload>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<Project>> {
  try {
    const { projectId, newsTrackerConfig } = action.payload;
    const { status, data }: AxiosResponse<Project> = yield call(
      api.patch,
      apiUrl.patchUpdateProjectNewsTrackerConfig(projectId),
      { newsTrackerConfig },
    );
    switch (status) {
      case 200:
        yield put(updateProjectNewsTrackerConfigSuccess(data));
        break;
      default:
        yield put(updateProjectNewsTrackerConfigFailure(`Oops, something went wrong (status code ${status})`));
    }

    yield call(fetchProjectsSaga);
  } catch (error) {
    yield put(updateProjectNewsTrackerConfigFailure(getErrorMessage(error)));
  }
}

export function* createNewsAlertsSaga(
  action: PayloadAction<CreateNewsAlertPayload[]>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<NewsAlert>[]> {
  try {
    // TODO: Fix `all()` effect typing
    // @ts-ignore
    const result = yield all(
      action.payload.map(({ projectId, meta }) => call(api.post, apiUrl.createNewsAlertMeta(projectId), meta)),
    );

    const success = result.every(({ status }) => status === 201);

    if (success) {
      yield put(createNewsAlertsSuccess());
    } else {
      yield put(createNewsAlertsFailure(`Oops, some News Alerts were not created`));
    }
  } catch (error) {
    yield put(createDiseaseFailure(getErrorMessage(error)));
  }
}

export function* updateNewsAlertsSaga(
  action: PayloadAction<UpdateNewsAlertMetaPayload[]>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<NewsAlert>[]> {
  try {
    // TODO: Fix `all()` effect typing
    // @ts-ignore
    const result = yield all(
      action.payload.map(({ projectId, newsAlertId, meta }) =>
        call(api.put, apiUrl.updateNewsAlertMeta(projectId, newsAlertId), meta),
      ),
    );

    const success = result.every(({ status }) => status === 200);

    if (success) {
      yield put(updateNewsAlertsSuccess());
    } else {
      yield put(updateNewsAlertsFailure(`Oops, some News Alerts were not updated`));
    }
  } catch (error) {
    yield put(createDiseaseFailure(getErrorMessage(error)));
  }
}
