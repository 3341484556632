import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from 'config/store';

import { useSnackbar } from '../../../../components/SnackbarProvider/useSnackbar';
import { CreateNewsAlertPayload, NewsAlert, UpdateNewsAlertMetaPayload } from '../../types';
import { createNewsAlerts, updateNewsAlerts } from '../news-alerts-reducer';
import { SaveButtonUIProps } from '../SaveButton';

const validateNewsAlert = ({ title, date, summary, therapyAreaIds, diseaseIds, link }: NewsAlert): boolean =>
  Boolean(title && date && summary && therapyAreaIds.length && diseaseIds.length && link.url && link.text);

export const useSaveButton = (): SaveButtonUIProps => {
  const dispatch = useDispatch();
  const { snackbar } = useSnackbar();
  const { projectId } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const { newsAlerts, createLoading, updateLoading, error } = useSelector((state: RootState) => state.newsAlerts);

  useEffect(() => {
    if (submitted && !createLoading && !updateLoading) {
      snackbar({
        variant: 'success',
        headline: 'News Alert have been saved',
      });

      setSubmitted(false);
    }
    // TODO: Error handling
  }, [createLoading, updateLoading, error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      snackbar({
        variant: 'error',
        headline: 'Something went wrong, and News haven’t been saved.',
        message: 'Please try again later.',
        withEmailNotice: true,
      });
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave: SaveButtonUIProps['onSave'] = () => {
    if (!projectId) {
      console.warn(`Oops, can not save New Alerts. 'projectId' is missing`);
      return;
    }
    if (newsAlerts) {
      const payload: (CreateNewsAlertPayload | UpdateNewsAlertMetaPayload)[] = newsAlerts
        .filter(validateNewsAlert)
        .map(
          ({
            id,
            title,
            date,
            therapyAreaIds,
            diseaseIds,
            summary,
            link,
            company,
            category,
            asset,
            priorityLevel,
            businessStream,
            modality,
            ageGroup,
            implications,
            other,
            indication,
            geography,
          }) => ({
            projectId: projectId,
            newsAlertId: id,
            meta: {
              title,
              date,
              therapyAreaIds,
              diseaseIds,
              summary,
              linkUrl: link.url,
              linkText: link.text,
              company: company || null,
              category: category || null,
              asset: asset || null,
              priorityLevel: priorityLevel || null,
              businessStream: businessStream || null,
              modality: modality || null,
              ageGroup: ageGroup || null,
              implications: implications || null,
              other: other || null,
              indication: indication || null,
              geography: geography || null,
            },
          }),
        );

      const createPayload = payload.filter(({ newsAlertId }) => !newsAlertId);
      const updatePayload = payload.filter(({ newsAlertId }) => newsAlertId);

      setSubmitted(true);

      dispatch(createNewsAlerts(createPayload));
      dispatch(updateNewsAlerts(updatePayload));
    }
  };

  return {
    loading: createLoading || updateLoading,
    onSave,
  };
};
