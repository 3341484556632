import { useIsGlobalAuthenticated } from './useIsGlobalAuthenticated';
import { useIsLocalAuthenticated } from './useIsLocalAuthenticated';

/**
 * Hook to check if the user is authenticated locally.
 *
 * Locally means that the user has an Backend API's access token in the local storage.
 * Globally means that the user has authenticated in IAM Provider.
 */
export const useIsAuthenticated = (): boolean => {
  const isLocalAuthenticated = useIsLocalAuthenticated();
  const isGlobalAuthenticated = useIsGlobalAuthenticated();

  return isLocalAuthenticated && isGlobalAuthenticated;
};
