import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'config/store';
import { ACCESS_TOKEN_STORAGE_KEY } from 'constants/auth';

/**
 * Hook to check if the user is authenticated locally.
 *
 * Locally means that the user has an Backend API's access token in the local storage.
 * Globally means that the user has authenticated in IAM Provider.
 */
export const useIsLocalAuthenticated = (): boolean => {
  const hasAccessTokenInLocalStorage = Boolean(localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY));
  const [isAuthenticated, setIsAuthenticated] = useState(hasAccessTokenInLocalStorage);
  const { accessToken } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (accessToken) {
      setIsAuthenticated(true);
      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
    }
  }, [accessToken]);

  return Boolean(isAuthenticated);
};
