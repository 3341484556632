import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'config/store';

import { MyProfileUIProps } from '../MyProfile';
import { fetchProfile } from '../reducer';

export const useMyProfile = (): MyProfileUIProps => {
  const dispatch = useDispatch();
  const { profile, loading } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  return {
    profile,
    loading,
  };
};
