import {
  AuthenticationResult,
  IPublicClientApplication,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Dispatch, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';

import { Component } from 'types-common';

import { Centered } from '../../../components/Centered';
import { Progress } from '../../../components/Progress';
import { loginRequestConfig } from '../../../config/msal-auth';
import { AppRoutes } from '../../../constants/navigation';
import { useIsAuthenticated } from './hooks/useIsAuthenticated';
import { authenticateAADB2C } from './reducer';

const useSessionRedirect = (): void => {
  const navigate = useNavigate();
  // TODO: Use API to validate current session
  // const user = useSelector((state: RootState) => state.auth.user);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    // navigate(AppRoutes.USER_MANAGEMENT);
    if (isAuthenticated) {
      navigate(AppRoutes.USER_MANAGEMENT);
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps
};

const requestLocalSessionToken = (dispatch: Dispatch<AnyAction>, redirectState: AuthenticationResult): void => {
  console.log('finalizeLoginWithBackend', redirectState);
  const idToken = redirectState?.idToken;

  if (!idToken) {
    console.error('idToken is not defined in redirectState', redirectState);

    throw new Error(`Unable to finalize login with backend`);
  }

  // TODO: maybe pass just idToken instead of DTO?
  dispatch(authenticateAADB2C({ idToken }));
};

const doSsoSignInOrHandleResponse = async (
  dispatch: Dispatch<AnyAction>,
  instance: IPublicClientApplication,
): Promise<void> => {
  await instance.initialize();
  const redirectState = await instance.handleRedirectPromise();

  // by design, `null` means that there is no auth redirect detected
  if (redirectState !== null) {
    console.info('Auth redirect detected, request local session token...');
    // if we've got redirect state, it means that IAM provider called redirectUrl with token
    requestLocalSessionToken(dispatch, redirectState);
  } else {
    console.info('No auth redirect detected, start SSO sign in flow...');

    doSsoSignIn(instance);
  }
};

const doSsoSignIn = (instance: IPublicClientApplication): void => {
  const loginRedirectParams: RedirectRequest = {
    scopes: loginRequestConfig.scopes,
    redirectUri: loginRequestConfig.redirectUri,

    onRedirectNavigate: (url: string) => {
      console.info('Redirecting to...', url);
      return true;
    },
  };

  console.log('Starting SSO Login Redirect flow...', loginRedirectParams);

  instance.loginRedirect(loginRedirectParams).catch(e => {
    console.error('An error occurred during login redirect to AADB2C', e);
  });
};

const SignInPage: Component = () => {
  const { instance, inProgress } = useMsal();
  const dispatch = useDispatch();

  useSessionRedirect();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      doSsoSignInOrHandleResponse(dispatch, instance);
    }
  }, [inProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Centered>
      <Progress global />
    </Centered>
  );
};

export default SignInPage;
