import { Component } from 'types-common';

import {
  TherapyAreaAestheticsSVG,
  TherapyAreaAntiInfectivesSVG,
  TherapyAreaCardiovascularSVG,
  TherapyAreaCnsSVG,
  TherapyAreaDermatologySVG,
  TherapyAreaImmunologySVG,
  TherapyAreaInfectiousDiseaseSVG,
  TherapyAreaMedicalDevicesSVG,
  TherapyAreaMetabolicSVG,
  TherapyAreaOncologySVG,
  TherapyAreaOpthalmologySVG,
  TherapyAreaRareDiseaseSVG,
  TherapyAreaRespiratorySVG,
  TherapyAreaVaccinesSVG,
  TherapyAreaWomensHealthSVG,
} from '../../assets/svg/therapy-areas';
import { TherapyAreaName } from '../../modules/healthcare-category-list/types';
import { Tooltip } from '../Tooltip/Tooltip';

const iconsMap: Record<TherapyAreaName, JSX.Element> = {
  Aesthetics: <TherapyAreaAestheticsSVG />,
  'Anti-infectives': <TherapyAreaAntiInfectivesSVG />,
  Cardiovascular: <TherapyAreaCardiovascularSVG />,
  CNS: <TherapyAreaCnsSVG />,
  Dermatology: <TherapyAreaDermatologySVG />,
  Immunology: <TherapyAreaImmunologySVG />,
  'Infectious diseases': <TherapyAreaInfectiousDiseaseSVG />,
  'Medical devices': <TherapyAreaMedicalDevicesSVG />,
  Metabolics: <TherapyAreaMetabolicSVG />,
  Oncology: <TherapyAreaOncologySVG />,
  Ophthalmology: <TherapyAreaOpthalmologySVG />,
  'Rare diseases': <TherapyAreaRareDiseaseSVG />,
  Respiratory: <TherapyAreaRespiratorySVG />,
  Vaccines: <TherapyAreaVaccinesSVG />,
  'Women’s health': <TherapyAreaWomensHealthSVG />,
};

export interface TherapyAreaIconProps {
  name: string;
}

export const TherapyAreaIcon: Component<TherapyAreaIconProps> = ({ name }) => (
  <Tooltip title={name}>{iconsMap[name as TherapyAreaName]}</Tooltip>
);
