import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponseData } from 'error-handling';

import { ErrorMessage, Maybe } from 'types-common';

import { Project } from '../types';

export interface ProjectsState {
  projects: Project[];
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: ProjectsState = {
  projects: [],
  loading: true,
  error: null,
};

const projectsReducer = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    fetchProjects(state) {
      state.loading = true;
      state.error = null;
    },
    fetchProjectsSuccess(state, action: PayloadAction<Project[]>) {
      state.loading = false;
      state.projects = action.payload;
    },
    fetchProjectsFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchProjects, fetchProjectsSuccess, fetchProjectsFailure } = projectsReducer.actions;
export default projectsReducer.reducer;
