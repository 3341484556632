import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FailureResponseData } from 'error-handling';

import { ErrorMessage, Maybe } from 'types-common';

import { BusinessUnit } from '../types';

export interface BusinessUnitsState {
  businessUnits: BusinessUnit[];
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: BusinessUnitsState = {
  businessUnits: [],
  loading: true,
  error: null,
};

const businessUnitsReducer = createSlice({
  name: 'businessUnits',
  initialState,
  reducers: {
    fetchBusinessUnits(state) {
      state.loading = true;
      state.error = null;
    },
    fetchBusinessUnitsSuccess(state, action: PayloadAction<BusinessUnit[]>) {
      state.loading = false;
      state.businessUnits = action.payload;
    },
    fetchBusinessUnitsFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchBusinessUnits, fetchBusinessUnitsSuccess, fetchBusinessUnitsFailure } =
  businessUnitsReducer.actions;
export default businessUnitsReducer.reducer;
