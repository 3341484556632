import { Table } from '../../../components/Table';
import { TableHeadRowAlignTop } from '../../../components/TableHeadRow';
import { TableLoading } from '../../../components/TableLoading/TableLoading';
import { Component } from '../../../types-common';
import { EmptyProjectsList } from './EmptyProjectsList';
import ProjectGeneralInfoListItem from './ProjectGeneralInfoListItem';
import { ProjectManagementListProps } from './ProjectManagement';
import {
  ColumnActions,
  ColumnBusinessUnit,
  ColumnClientName,
  ColumnClientsInvited,
  ColumnCount,
  ColumnCreated,
  ColumnDataEntry,
  ColumnDisease,
  ColumnProjectDuration,
  ColumnProjectName,
  ColumnProjectСode,
  ColumnTherapyArea,
  ColumnUpdated,
} from './styled/Columns.styled';

type ProjectGeneralInfoListProps = Omit<
  ProjectManagementListProps,
  'projectServices' | 'projectToEdit' | 'onEditClose'
>;
export const ProjectGeneralInfoList: Component<ProjectGeneralInfoListProps> = ({ projects, loading, onEdit }) => {
  return (
    <Table>
      <TableHeadRowAlignTop>
        <ColumnCount> </ColumnCount>
        <ColumnClientName>Client Name</ColumnClientName>
        <ColumnProjectСode>Project Сode</ColumnProjectСode>
        <ColumnProjectName>Project Name</ColumnProjectName>
        <ColumnTherapyArea>Therapy Area</ColumnTherapyArea>
        <ColumnDisease>Disease</ColumnDisease>
        <ColumnBusinessUnit>Business Unit</ColumnBusinessUnit>
        <ColumnDataEntry>Data Entry</ColumnDataEntry>
        <ColumnClientsInvited>Clients Invited</ColumnClientsInvited>
        <ColumnProjectDuration>Project Duration</ColumnProjectDuration>
        <ColumnCreated>Created</ColumnCreated>
        <ColumnUpdated>Updated</ColumnUpdated>
        <ColumnActions> </ColumnActions>
      </TableHeadRowAlignTop>
      {loading ? (
        <TableLoading />
      ) : projects.length ? (
        <>
          {projects.map((project, index) => (
            <ProjectGeneralInfoListItem
              key={`project-genera-info-list-item-${index}`}
              rowIndex={index}
              project={project}
              onEdit={onEdit}
            />
          ))}
        </>
      ) : (
        <EmptyProjectsList />
      )}
    </Table>
  );
};
