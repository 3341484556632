import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from 'error-handling';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';

import api from 'config/api';
import apiUrl from 'constants/network';

import { InviteStatus } from '../types';
import { inviteVerifyFailure, inviteVerifySuccess } from './reducer';

export interface PostInviteVerifyPayload {
  code: string;
}

export interface PostInviteVerifyResponse {
  inviteId: string;
  status: InviteStatus;
}

export function* inviteVerifySaga(
  action: PayloadAction<PostInviteVerifyPayload>,
): Generator<CallEffect | PutEffect<AnyAction>, void, AxiosResponse<PostInviteVerifyResponse>> {
  try {
    const { status, data }: AxiosResponse<PostInviteVerifyResponse> = yield call(
      api.patch,
      apiUrl.patchInviteVerify(action.payload.code),
    );
    switch (status) {
      case 200:
        if (data.status === InviteStatus.Expired) {
          yield put(inviteVerifyFailure(`Invite is expired`));
        } else {
          yield put(inviteVerifySuccess(data));
        }
        break;
      default:
        yield put(inviteVerifyFailure(`Oops, something went wrong (unknown status code ${status})`));
    }
  } catch (error) {
    yield put(inviteVerifyFailure(getErrorMessage(error)));
  }
}
