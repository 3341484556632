import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from 'modules/identity-management/types';
import { ErrorMessage, Maybe } from 'types-common';

import { FailureResponseData } from '../../../error-handling';

export interface UsersState {
  users: User[];
  loading: boolean;
  error: Maybe<FailureResponseData | ErrorMessage>;
}

const initialState: UsersState = {
  users: [],
  loading: true,
  error: null,
};

const usersReducer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsers(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUsersSuccess(state, action: PayloadAction<User[]>) {
      state.loading = false;
      state.users = action.payload;
    },
    fetchUsersFailure(state, action: PayloadAction<FailureResponseData | ErrorMessage>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchUsers, fetchUsersSuccess, fetchUsersFailure } = usersReducer.actions;
export default usersReducer.reducer;
