import MuiTextField from '@mui/material/TextField/TextField';
import styled from 'styled-components';

export const TextFieldRoot = styled.div`
  margin-bottom: 18px;
`;

export const TextFieldStyled = styled(MuiTextField)`
  && {
    width: 100%;

    &.valid .MuiInputBase-root {
      border-color: ${({ theme }) => theme.palette.greenMedium};
    }
    &.invalid .MuiInputBase-root {
      border-color: ${({ theme }) => theme.palette.redMedium};
    }
    &.invalid input::placeholder {
      color: ${({ theme }) => theme.palette.redMedium};
    }

    .MuiInputBase-root {
      border-radius: 6px;
      border: ${({ theme }) => `1.5px solid ${theme.palette.purplePale}`};

      &:before,
      &:after {
        display: none;
      }
    }

    input {
      font-size: 14px;
      height: 17px;
      line-height: 17px;
      padding: 8px 12px;
      border-radius: 6px;
      color: ${({ theme }) => theme.palette.text};

      &::placeholder {
        color: ${({ theme }) => theme.palette.subtext};
        opacity: 1;
      }
    }

    .MuiInputBase-multiline {
      font-size: 12px;
    }
    .MuiFormLabel-root {
      font-size: 12px;

      &.Mui-focused {
        color: ${({ theme }) => theme.palette.textLight};
      }
    }
  }
`;

export const TextFieldLabel = styled.label`
  display: flex;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.subtext};
  margin-bottom: 8px;
`;

export const TextFieldError = styled.div`
  padding-top: 8px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.redMedium};
`;
