import { Dialog } from '../../../components/Dialog';
import { LinearProgress } from '../../../components/LinearProgress/LinearProgress';
import { FORM_DIALOG_ASK_BEFORE_CLOSE } from '../../../constants/form';
import { Component, Maybe } from '../../../types-common';
import { OnCloseDialogHandler, Project } from '../types';
import { useProjectEditWizard } from './hooks/useProjectEditWizard';
import { ProjectGeneralInfoForm } from './ProjectGeneralInfoForm';
import { ProjectServicesForm } from './ProjectServicesForm';
import { ProjectTherapyAreaForm } from './ProjectTherapyAreaForm';

export interface ProjectEditWizardUIProps {
  stepIndex: number;
  onClose: (_: any, reason: string) => void;
  navigateNext: (stepsCount: number) => () => void;
  navigateBack: () => void;
}
export const ProjectEditWizardUI: Component<ProjectEditWizardUIProps> = ({
  stepIndex,
  onClose,
  navigateNext,
  navigateBack,
}) => {
  const STEPS_COUNT = 3;
  const handleClose = (reason?: string): void => {
    onClose({}, reason || FORM_DIALOG_ASK_BEFORE_CLOSE);
  };
  const steps: JSX.Element[] = [
    <div key="project-edit-step-1">
      <ProjectGeneralInfoForm key="ProjectGeneralInfoForm" onNext={navigateNext(STEPS_COUNT)} onClose={handleClose} />
    </div>,
    <div key="project-edit-step-2">
      <ProjectTherapyAreaForm
        key="ProjectTherapyAreaForm"
        onBack={navigateBack}
        onNext={navigateNext(STEPS_COUNT)}
        onClose={handleClose}
      />
    </div>,
    <div key="project-edit-step-3">
      <ProjectServicesForm key="ProjectServicesForm" onBack={navigateBack} onClose={handleClose} />
    </div>,
  ];
  const progress = (100 / steps.length) * (stepIndex + 1);

  return (
    <Dialog open onClose={onClose}>
      <LinearProgress variant="determinate" value={progress} />
      {steps[stepIndex]}
    </Dialog>
  );
};

type ProjectEditWizardProps = OnCloseDialogHandler & {
  project?: Maybe<Project>;
};
export const ProjectEditWizard: Component<ProjectEditWizardProps> = ({ project, onClose }) => {
  return <ProjectEditWizardUI {...useProjectEditWizard(project, onClose)} />;
};
